import {
  VergunningApi, IGetVergunningAdresOnVergunningStartParameters,
  ProductSelector, GetVergunningAdresOnVergunningStartParameters,
  GetVergunningAdresOnVergunningStartResult, CreateVergunningParameters,
  UserSelector, ICreateVergunningParameters, IUserSelector,
  ValidateProfielOnVergunningStartResult, IValidateProfielOnVergunningStartParameters,
  ValidateProfielOnVergunningStartParameters, VergunningInfo, GetVergunningenCurrentUserResult,
  DoAantallenControleOnStartResult, DoAantallenControleOnZoneResult, DoAantallenControleOnIndienenResult,
  IAddRechtVervangwagenParameters, GetMinMaxStartDayForVervangwagenResult, GetRechtResult, RechtInfo,
  AddRechtVervangwagenParameters, AddRechtVervangwagenResult, StopzettenVervangwagenParameters,
  IStopzettenVervangwagenParameters, StopzettenVervangwagenResult, IAanmakenBezoekerRechtParameters,
  AanmakenBezoekerRechtResult, AanmakenBezoekerRechtParameters, GetBezoekerRechtenResult,
  RechtSelector, VergunningSelector, UpdateBezoekerRechtResult, UpdateBezoekerRechtParameters,
  IUpdateBezoekerRechtParameters, DeleteBezoekerRechtParameters, IDeleteBezoekerRechtParameters,
  GetMinMaxStartDayForProductResult, GetMinMaxEndDayForVervangwagenResult, GetMinMaxStartDayForBezoekerRechtResult,
  GetMinMaxEndDayForBezoekerRechtResult, AddVerlengVergunningAanvraagZonderBewijslastInWinkelMandjeParameters,
  IAddVerlengVergunningAanvraagZonderBewijslastInWinkelMandjeParameters, AddVerlengVergunningAanvraagZonderBewijslastInWinkelMandjeResult,
  ProductApi, ProductInfo, VergunningZoneMethodDtoEnum, ThemaDtoEnum, ICalculateVergunningZoneInfoParameters,
  CalculateVergunningZoneInfoParameters, ISetVergunningAdresParameters, AdresInfo, SetVergunningAdresParameters,
  ISetVergunningZoneParameters, ZoneSelector, SetVergunningZoneParameters, GetVergunningLoopTijdResult,
  CalculateVergunningEndDayParameters, ICalculateVergunningEndDayParameters, CalculateVergunningEndDayResult,
  ISetVergunningStartDayParameters, SetVergunningStartDayParameters, ISetVergunningEndDayParameters,
  SetVergunningEndDayParameters, IAddPermanentRechtParameters, AddPermanentRechtParameters,
  IDeleteRechtParameters, DeleteRechtParameters, IDeleteVergunningBewijsstukParameters, DeleteVergunningBewijsstukParameters,
  BewijsstukSelector, IDeleteBewijsstukRechtParameters, DeleteBewijsstukRechtParameters, IControleerDivParameters,
  ControleerDivParameters, GetVergunningAantalRechtenResult, GetVergunningBijkomendeInfoResult, ISaveVergunningBijkomendeInfoParameters,
  VergunningBijkomendeInfoDto, SaveVergunningBijkomendeInfoParameters, IAnnuleerVergunningParameters,
  AnnuleerVergunningParameters, FileResponse, GenerateVergunningBewijslastParameters,
  IGenerateVergunningBewijslastParameters, GetVergunningBewijslastResult, PermanentRechtToAddAttributes,
  GetMinMaxStartDayForWijzigenPermanentRechtResult, IndienenPermanentRechtResult, IndienenPermanentRechtParameters,
  ICreatePermanentRechtParameters, PermanentRechtToCreate, CreatePermanentRechtParameters,
  IStopzettenPermanentRechtParameters, StopzettenPermanentRechtResult, StopzettenPermanentRechtParameters,
  GetMinMaxStartDayForToevoegenPermanentRechtResult,
  BewijsstukInfo,
  GetRechtenOverviewResult,
  ControleerDivResult,
  ICopyVergunningAsyncParameters,
  CopyVergunningAsyncParameters,
  CopyVergunningAsyncResult,
  GetCopyVergunningJobResult,
  StopzettenVergunningPublicParameters,
  StopzettenVergunningPublicResult
} from './../shared/publicapi/d09.avgpv.public.client';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {
  IdReference, Adres, Bewijsstuk,
  BijkomendeInfo
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class VergunningService {
  public product: ProductInfo;
  public vergunningSelector: IdReference;
  public vergunning: VergunningInfo;
  public bijkomendeInfoResult: GetVergunningBijkomendeInfoResult;
  public routeToBijkomendeInfo: boolean;

  public bewijslastResult: GetVergunningBewijslastResult;
  public routeToBewijslast: boolean;

  public routeToVoertuigen: boolean;

  public isVraagOpnieuwAan: boolean;
  public vraagOpnieuwAanVergunningOrigin: VergunningSelector;

  public steps = [
    { path: 'start', name: $localize`Start`, active: false, enabled: false, completed: false },
    { path: 'zone', name: $localize`Zone`, active: false, enabled: false, completed: false },
    { path: 'periode', name: $localize`Periode`, active: false, enabled: false, completed: false },
    { path: 'extrainfo', name: $localize`Extra info`, active: false, enabled: false, completed: false },
    { path: 'bewijslasten', name: $localize`Documenten`, active: false, enabled: false, completed: false },
    { path: 'voertuigen', name: $localize`Voertuig(en)`, active: false, enabled: false, completed: false },
    { path: 'indienen', name: $localize`Controleer`, active: false, enabled: false, completed: false },
  ];

  constructor(
    private productApi: ProductApi,
    private vergunningApi: VergunningApi,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  public reset(isVraagOpnieuwAanFlow: boolean): void {
    // reset the following static properties when it is not a vraag opnieuw aan flow
    if (!isVraagOpnieuwAanFlow) {
      this.isVraagOpnieuwAan = null;
      this.vraagOpnieuwAanVergunningOrigin = null;
    }

    this.vergunningSelector = null;
    this.bewijslastResult = null;
    this.bijkomendeInfoResult = null;

    // As a default we skip the pages, only for the lucky few we are gonna route to specific pages
    this.routeToBewijslast = false;
    this.routeToBijkomendeInfo = false;
  }

  public cleanUpVraagOpnieuwAanFlow() {
    this.isVraagOpnieuwAan = null;
    this.vraagOpnieuwAanVergunningOrigin = null;
  }

  public getProduct(reference: string): Observable<ProductInfo> {
    return this.productApi.getProduct(null, reference).pipe(
      // Save the last fetched product for later,
      // so we don't need to keep passing it around.
      tap(p => this.product = p)
    );
  }

  public getProducten(thema: ThemaDtoEnum): Observable<ProductInfo[]> {
    return this.productApi.getProducts(thema, this.locale);
  }

  public refreshVergunning(): Observable<VergunningInfo> {
    this.vergunning = null;
    return this.getVergunning({ includeValidationInfo: true, includeNummerPlaten: true, includeZoneBepalingInfo: true });
  }

  public getVergunning({ includeValidationInfo = false, includeZoneBepalingInfo = false, includeNummerPlaten = false, includeRechtenBewijsstukken = false }:
    { includeValidationInfo?: boolean; includeZoneBepalingInfo?: boolean;
      includeNummerPlaten?: boolean; includeRechtenBewijsstukken?: boolean; }): Observable<VergunningInfo> {

    this.checkVergunningSelectorIsSet();

    return this.getVergunningForDetail(this.vergunningSelector?.reference,
      {
        includeValidationInfo,
        includeZoneBepalingInfo,
        includeNummerPlaten,
        includeRechtenBewijsstukken
      })
      .pipe(
        // Save the last created vergunning for later,
        // so we don't need to keep passing it around.
        tap(v => this.vergunning = v),
        take(1),
      );
  }

  public getRechtenOverview(reference: string, kenteken: string): Observable<GetRechtenOverviewResult> {
    return this.vergunningApi.getVergunningPermanenteRechten(null, reference, kenteken);
  }

  public getVergunningForDetail(reference: string, {
    includeValidationInfo = false,
    includeZoneBepalingInfo = false,
    includeNummerPlaten = false,
    includePermanenteRechten = false,
    includeVervangwagenRechten = false,
    includeBezoekerRechten = false,
    includeDeelwagenRechten = false,
    includeKanVerlengenManueel = false,
    includeRechtenBewijsstukken = false
  }: {
    includeValidationInfo?: boolean;
    includeZoneBepalingInfo?: boolean;
    includeNummerPlaten?: boolean;
    includePermanenteRechten?: boolean;
    includeVervangwagenRechten?: boolean;
    includeBezoekerRechten?: boolean;
    includeDeelwagenRechten?: boolean;
    includeKanVerlengenManueel?: boolean;
    includeRechtenBewijsstukken?: boolean;
  }): Observable<VergunningInfo> {
    return this.vergunningApi.getVergunning(null, reference, includeValidationInfo, includeZoneBepalingInfo,
      includeNummerPlaten, includePermanenteRechten, includeVervangwagenRechten, includeBezoekerRechten, includeDeelwagenRechten,
      includeKanVerlengenManueel, includeRechtenBewijsstukken, false);
  }

  public calculateZoneInfo(): Observable<void> {
    const parameters: ICalculateVergunningZoneInfoParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.calculateZoneInfo(new CalculateVergunningZoneInfoParameters(parameters));
  }

  public setAdres(adres: Adres): Observable<void> {
    const parameters: ISetVergunningAdresParameters = {
      adres: new AdresInfo({ ...adres, id: null, crabCodeStraat: null }),
      selector: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.setAdres(new SetVergunningAdresParameters(parameters));
  }

  public setZone(zoneReference: string): Observable<void> {
    const parameters: ISetVergunningZoneParameters = {
      zoneSelector: new ZoneSelector({ id: null, reference: zoneReference }),
      selector: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.setZone(new SetVergunningZoneParameters(parameters));
  }

  public getLooptijd(): Observable<GetVergunningLoopTijdResult> {
    return this.vergunningApi.getLooptijd(this.vergunningSelector?.id, this.vergunningSelector?.reference);
  }

  public calculateEndDay(startDay: Date): Observable<CalculateVergunningEndDayResult> {
    const parameters: ICalculateVergunningEndDayParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      startDay: new Date(startDay)
    };

    return this.vergunningApi.calculateEndDay(new CalculateVergunningEndDayParameters(parameters));
  }

  public setPeriode(startDay: Date, endDayInclusive: Date): Observable<void> {
    const parametersStartDay: ISetVergunningStartDayParameters = {
      startDay: new Date(startDay),
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    const parametersEndDay: ISetVergunningEndDayParameters = {
      endDayInclusive: new Date(endDayInclusive),
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.setStartDay(new SetVergunningStartDayParameters(parametersStartDay))
      .pipe(
        switchMap(() => endDayInclusive
          ? this.vergunningApi.setEndDay(new SetVergunningEndDayParameters(parametersEndDay))
          : of(null)
        )
      );
  }

  public getRecht(recht: RechtSelector, vergunning: VergunningSelector): Observable<RechtInfo> {
    return this.vergunningApi.getRecht(vergunning.id, vergunning.reference, null, recht.id, recht.reference)
      .pipe(map((rechtResult: GetRechtResult) => rechtResult.recht));
  }

  public addRecht(recht: PermanentRechtToAddAttributes): Observable<RechtSelector> {
    const parameters: IAddPermanentRechtParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      recht: new PermanentRechtToAddAttributes({
        reference: null,
        kenteken: recht.kenteken,
        alias: recht.alias,
        landIso2Code: recht.landIso2Code,
        previousVersion: recht.previousVersion,
        startDay: recht.startDay,
        endDayIncl: recht.endDayIncl,
        isCopied: null
      })
    };

    return this.vergunningApi.addPermanentRecht(new AddPermanentRechtParameters(parameters));
  }

  public createRecht(recht: PermanentRechtToCreate): Observable<RechtSelector> {
    const parameters: ICreatePermanentRechtParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      recht: new PermanentRechtToCreate({
        reference: null,
        kenteken: recht.kenteken,
        alias: recht.alias,
        landIso2Code: recht.landIso2Code,
        previousVersion: recht.previousVersion,
        startDag: recht.startDag,
        endDayIncl: recht.endDayIncl
      })
    };

    return this.vergunningApi.createPermanentRecht(new CreatePermanentRechtParameters(parameters));
  };

  public deleteRecht(recht: IdReference): Observable<void> {
    const parameters: IDeleteRechtParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      recht: new RechtSelector({ id: recht.id, reference: recht.reference, __dummy: null }),
      isCancel: false
    };

    return this.vergunningApi.deleteRecht(new DeleteRechtParameters(parameters));
  }

  public deleteBewijsstukRecht(recht: RechtInfo, bewijsstuk: BewijsstukInfo): Observable<void> {
    const parameters: IDeleteBewijsstukRechtParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      bewijsstuk: new BewijsstukSelector({ id: bewijsstuk.id, reference: bewijsstuk.reference }),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      recht: new RechtSelector({ id: recht.id, reference: recht.reference, __dummy: null })
    };

    return this.vergunningApi.deleteBewijsstukRecht(new DeleteBewijsstukRechtParameters(parameters));
  }

  public controleerDiv(recht: IdReference): Observable<ControleerDivResult> {
    const parameters: IControleerDivParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      recht: new RechtSelector({ id: recht.id, reference: recht.reference, __dummy: null })
    };

    return this.vergunningApi.controleerDiv(new ControleerDivParameters(parameters));
  }

  public getAantalRechten(): Observable<GetVergunningAantalRechtenResult> {
    return this.vergunningApi.getAantalRechten(this.vergunningSelector?.id, this.vergunningSelector?.reference);
  }

  public createVergunning(ownerUserSelector: IUserSelector): Observable<IdReference> {

    if (this.vergunningSelector) {
      return of(this.vergunningSelector);
    }

    const parameters: ICreateVergunningParameters = {
      reference: null,
      ownerUserSelector: new UserSelector(ownerUserSelector),
      productSelector: new ProductSelector({ id: this.product?.id, reference: this.product?.reference })
    };

    return this.vergunningApi.createVergunning(new CreateVergunningParameters(parameters))
      .pipe(
        // Save the last created vergunning for later,
        // so we don't need to keep passing it around.
        tap(v => this.vergunningSelector = v)
      );
  }

  public copyVergunningAsync(): Observable<CopyVergunningAsyncResult> {
    const parameters: ICopyVergunningAsyncParameters = {
      vergunning: this.vraagOpnieuwAanVergunningOrigin
    };

    return this.vergunningApi.runCopyVergunningJob(new CopyVergunningAsyncParameters(parameters));
  }

  public getCopyVergunningJob(copyVergunningJobId: string): Observable<GetCopyVergunningJobResult> {
    return this.vergunningApi.getCopyVergunningJobStatus(copyVergunningJobId);
  }

  public getZoneMethod(): Observable<VergunningZoneMethodDtoEnum> {
    return this.vergunningApi.getZoneMethod(this.vergunningSelector?.id, this.vergunningSelector?.reference)
      .pipe(map(z => z.zoneMethod));
  }

  public getBijkomendeInfo(): Observable<GetVergunningBijkomendeInfoResult> {
    return this.vergunningApi.getBijkomendeInfo(this.vergunningSelector?.id, this.vergunningSelector?.reference).pipe(map(bi => bi));
  }

  public setBijkomendeInfo(bijkomendeInfo: BijkomendeInfo): Observable<void> {
    const parameters: ISaveVergunningBijkomendeInfoParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference }),
      bijkomendeInfoList: bijkomendeInfo.bijkomendeInfoList
        .map(bil => new VergunningBijkomendeInfoDto({ ...bil, zichtbaarVoorKanalen: null }))
    };

    return this.vergunningApi.saveBijkomendeInfo(new SaveVergunningBijkomendeInfoParameters(parameters));
  }

  public annuleer(): Observable<FileResponse> {
    const parameters: IAnnuleerVergunningParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.annuleerVergunning(new AnnuleerVergunningParameters(parameters));
  }

  public initializeVereisteBewijslast(): Observable<void> {
    const parameters: IGenerateVergunningBewijslastParameters = {
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.generateBewijslast(new GenerateVergunningBewijslastParameters(parameters));
  }

  public getVereisteBewijslasten(): Observable<GetVergunningBewijslastResult> {
    return this.vergunningApi.getBewijslast(this.vergunningSelector?.id, this.vergunningSelector?.reference)
      .pipe(map(vbw => vbw));
  }

  public deleteBewijsstukVergunning(bewijsstuk: Bewijsstuk): Observable<any> {
    const parameters: IDeleteVergunningBewijsstukParameters = {
      bewijsstuk: new BewijsstukSelector({ id: bewijsstuk.id, reference: bewijsstuk.reference }),
      vergunning: new VergunningSelector({ id: this.vergunningSelector?.id, reference: this.vergunningSelector?.reference })
    };

    return this.vergunningApi.deleteBewijsstuk(new DeleteVergunningBewijsstukParameters(parameters));
  }

  public getVergunningAdres(): Observable<GetVergunningAdresOnVergunningStartResult> {
    const parameters: IGetVergunningAdresOnVergunningStartParameters = {
      product: new ProductSelector({ id: this.product?.id, reference: this.product?.reference })
    };

    return this.vergunningApi.getVergunningAdresOnVergunningStart(new GetVergunningAdresOnVergunningStartParameters(parameters));
  }

  public validateProfielOnVergunningStart(): Observable<ValidateProfielOnVergunningStartResult> {
    const parameters: IValidateProfielOnVergunningStartParameters = {
      product: new ProductSelector({ id: this.product?.id, reference: this.product?.reference })
    };

    return this.vergunningApi.validateProfielOnVergunningStart(new ValidateProfielOnVergunningStartParameters(parameters));
  }

  public aantallenControleOnVergunningStart(product: ProductInfo): Observable<DoAantallenControleOnStartResult> {
    return this.vergunningApi.doAantallenControleOnStart(product.id, product.reference);
  }

  public aantallenControleOnVergunningZone(): Observable<DoAantallenControleOnZoneResult> {
    return this.vergunningApi.doAantallenControleOnZone(this.vergunningSelector?.id, this.vergunningSelector?.reference);
  }

  public aantallenControleOnVergunningIndienen(id: string, reference: string): Observable<DoAantallenControleOnIndienenResult> {
    return this.vergunningApi.doAantallenControleOnIndienen(id, reference);
  }

  public getVergunningenForCurrentUser(includeActieveNummerplaten?: boolean, kenteken?: string): Observable<GetVergunningenCurrentUserResult> {
    return this.vergunningApi.getVergunningenCurrentUser(includeActieveNummerplaten, kenteken);
  }

  public indienenPermanentRecht(parameters: IndienenPermanentRechtParameters): Observable<IndienenPermanentRechtResult> {
    return this.vergunningApi.indienenPermanentRecht(parameters);
  }

  public addRechtVervangwagen(parameters: IAddRechtVervangwagenParameters): Observable<AddRechtVervangwagenResult> {
    return this.vergunningApi.addRechtVervangwagen(new AddRechtVervangwagenParameters(parameters));
  }

  public stopzettenRechtVervangwagen(parameters: IStopzettenVervangwagenParameters): Observable<StopzettenVervangwagenResult> {
    return this.vergunningApi.stopzettenVervangwagen(new StopzettenVervangwagenParameters(parameters));
  }

  public stopzettenRechtPermanent(parameters: IStopzettenPermanentRechtParameters): Observable<StopzettenPermanentRechtResult> {
    return this.vergunningApi.stopzettenPermanentRecht(new StopzettenPermanentRechtParameters(parameters));
  }

  public addBezoekerRecht(parameters: IAanmakenBezoekerRechtParameters): Observable<AanmakenBezoekerRechtResult> {
    return this.vergunningApi.aanmakenBezoekerRecht(new AanmakenBezoekerRechtParameters(parameters));
  }

  public updateBezoekerRecht(parameters: IUpdateBezoekerRechtParameters): Observable<UpdateBezoekerRechtResult> {
    return this.vergunningApi.updateBezoekerRecht(new UpdateBezoekerRechtParameters(parameters));
  }

  public deleteBezoekerRecht(parameters: IDeleteBezoekerRechtParameters): Observable<void> {
    return this.vergunningApi.deleteBezoekerRecht(new DeleteBezoekerRechtParameters(parameters));
  }

  public getBezoekerRechten(
    vergunning: IdReference,
    kenteken: string = '',
    alias: string = '',
    datumFrom: Date = null,
    datumTo: Date = null,
    sort: string = '',
    sortDirection: string = 'asc',
    limit: number = 100,
    offset: number = 0): Observable<GetBezoekerRechtenResult> {
    return this.vergunningApi.getBezoekerRechten(vergunning?.id, vergunning?.reference, kenteken, alias,
      datumFrom, datumTo, sort, sortDirection, limit, offset);
  }

  public getMinMaxStartDayForVervangwagen(vergunning: VergunningInfo, recht: RechtInfo): Observable<GetMinMaxStartDayForVervangwagenResult> {
    return this.vergunningApi.getMinMaxStartDayForVervangwagen(vergunning.id, vergunning.reference, null, recht.id, recht.reference);
  }

  public getMinMaxStartDayForWijzigenPermanentRecht(vergunning: VergunningInfo, recht: RechtInfo):
    Observable<GetMinMaxStartDayForWijzigenPermanentRechtResult> {
    return this.vergunningApi.getMinMaxStartDayForWijzigenPermanentRecht(vergunning.id, vergunning.reference, null, recht.id, recht.reference);
  }

  public getMinMaxStartDayForToevoegenPermanentRecht(vergunning: VergunningInfo):
    Observable<GetMinMaxStartDayForToevoegenPermanentRechtResult> {
    return this.vergunningApi.getMinMaxStartDayForToevoegenPermanentRecht(vergunning.id, vergunning.reference);
  }

  public getMinMaxStartDayForProduct(): Observable<GetMinMaxStartDayForProductResult> {
    return this.vergunningApi.getMinMaxStartDayForProduct(this.product.id, this.product.reference);
  }

  public getMinMaxEndDayForVervangwagen(vergunning: VergunningInfo, recht: RechtSelector): Observable<GetMinMaxEndDayForVervangwagenResult> {
    return this.vergunningApi.getMinMaxEndDayForVervangwagen(vergunning.id, vergunning.reference, null, recht.id, recht.reference);
  }

  public getMinMaxStartDayForBezoekerRecht(vergunning: VergunningSelector): Observable<GetMinMaxStartDayForBezoekerRechtResult> {
    return this.vergunningApi.getMinMaxStartDayForBezoekerRecht(vergunning.id, vergunning.reference);
  }

  public getMinMaxEndDayForBezoekerRechtCreate(vergunning: VergunningSelector, startdatum: Date): Observable<GetMinMaxEndDayForBezoekerRechtResult> {
    return this.vergunningApi.getMinMaxEndDayForBezoekerRecht(vergunning.id, vergunning.reference, null, null, null, startdatum);
  }

  public getMinMaxEndDayForBezoekerRechtUpdate(vergunning: VergunningSelector, bezoekerRecht: RechtSelector, startdatum: Date):
    Observable<GetMinMaxEndDayForBezoekerRechtResult> {
    return this.vergunningApi.getMinMaxEndDayForBezoekerRecht(vergunning.id, vergunning.reference,
      null, bezoekerRecht.id, bezoekerRecht.reference, startdatum);
  }

  public addVerlengVergunningAanvraagZonderBewijslastInWinkelMandje(
    parameters: IAddVerlengVergunningAanvraagZonderBewijslastInWinkelMandjeParameters):
    Observable<AddVerlengVergunningAanvraagZonderBewijslastInWinkelMandjeResult> {
    return this.vergunningApi.addVerlengVergunningAanvraagZonderBewijslastInWinkelMandje(
      new AddVerlengVergunningAanvraagZonderBewijslastInWinkelMandjeParameters(parameters));
  }

  public async checkIfNeededBijkomendeInfo(): Promise<boolean> {
    const info: GetVergunningBijkomendeInfoResult = await this.getBijkomendeInfo().toPromise();

    if (info.bijkomendeInfoList.length > 0) {
      this.routeToBijkomendeInfo = true;
      this.bijkomendeInfoResult = info;
      return true;
    }

    return false;
  }

  public async checkIfNeededBewijslast(): Promise<boolean> {
    await this.initializeVereisteBewijslast().toPromise();
    const bewijslastResult: GetVergunningBewijslastResult = await this.getVereisteBewijslasten().toPromise();

    if (bewijslastResult.vereisteBewijslasten.length > 0) {
      this.routeToBewijslast = true;
      this.bewijslastResult = bewijslastResult;
      return true;
    }
    return false;
  }

  public async checkIfNeededVoertuigen(): Promise<boolean> {
    const voertuigResult: GetVergunningAantalRechtenResult = await this.getAantalRechten().toPromise();

    const allowedToAddRechten = voertuigResult.maximumAantal > 0
      || voertuigResult.minimumAantal > 0
      || voertuigResult.maximumAantal == null;

    if (allowedToAddRechten) {
      this.routeToVoertuigen = true;
      return true;
    }

    return false;
  }

  public stopVergunning(parameters: StopzettenVergunningPublicParameters): Observable<StopzettenVergunningPublicResult> {
    return this.vergunningApi.stopzettenVergunning(parameters);
  }

  private checkVergunningSelectorIsSet(): void {
    if (!this.vergunningSelector) {
      throw new Error('Onmogelijk om vergunning op te halen. Nog geen vergunning identificatie geinitialiseerd');
    }
  }
}
