import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ProfielPaginaComponent } from './profiel-pagina.component';
import { OndernemingFormComponent } from './forms/onderneming-form/onderneming-form.component';
import { PersoonFormComponent } from './forms/persoon-form/persoon-form.component';

@NgModule({
    declarations: [
        PersoonFormComponent,
        OndernemingFormComponent,
        ProfielPaginaComponent
    ],
    imports: [
        SharedModule
    ]
})
export class ProfielPaginaModule { }
