<div *ngIf="statusMessage?.hasMessage()" class="messages" [ngClass]="{'messages--error': statusMessage?.isError(), 'messages--warning': statusMessage?.isWarning(),'messages--info': statusMessage?.isInfo() }" role="alert" aria-atomic="true">
  <i *ngIf="statusMessage?.isError()"   class="icon-cross"       aria-hidden="true"></i>
  <i *ngIf="statusMessage?.isWarning()" class="icon-exclamation" aria-hidden="true"></i>
  <i *ngIf="statusMessage?.isInfo()"    class="icon-info"        aria-hidden="true"></i>
  <div class="message-body">
    <p style="min-width: 0" *ngIf="statusMessage?.header"><b>{{statusMessage?.header}}</b></p>
    <p style="min-width: 0"aria-hidden="true">&nbsp;</p>
    <pre style="white-space: pre-wrap;word-wrap: break-word;overflow-wrap: break-word;flex-shrink: 1;min-width: 0;" [innerHtml]="statusMessage?.message"></pre>
    <p style="min-width: 0;" aria-hidden="true">&nbsp;</p>
    <pre style="white-space: pre-wrap;word-wrap: break-word;overflow-wrap: break-word;flex-shrink: 1;min-width: 0;font-size: 0.6rem;display:block;" *ngIf="statusMessage?.messageCode" aria-hidden="true">{{statusMessage?.messageCode}}</pre>
  </div>

</div>
