/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { EnvironmentService } from './app/environment.service';

const injector =  Injector.create({providers: [{provide: EnvironmentService, deps: []}]});
const environment = injector.get(EnvironmentService);

declare var window: any;

if (window.__env.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
