/* eslint-disable @typescript-eslint/naming-convention */
import { AuthService } from '../auth/auth.service';
import { avgpvParseError, errorIsAuthSvcRelated } from '../shared/errorhandling/errorhandler';
import { getLocalStorageAsStr } from '../shared/storage';

export enum ClientLogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6
}

// rename LogEntry to ClientLogEntry to avoid collision with NSwag generated class (are compatible however)
export class ClientLogEntry {
  public static Create;

  public application: string;
  public level: number;
  public message: string;
  public messageArgs: any[] = [];
  public timeStampUtc: string;
  public properties: any = {};

  public static CreateFromError(error: Error | any, authSvc?: AuthService): ClientLogEntry
  {
    if (error?.rejection) {
      error = error.rejection;
    }

    const newEntry = new ClientLogEntry();
    const parsed = avgpvParseError(error);
    newEntry.message = parsed.detail;
    newEntry.properties = parsed.properties;
    newEntry.properties.stack = parsed.stacktraces;
    newEntry.properties.title = parsed.title;
    newEntry.properties.instance = parsed.instance;
    newEntry.properties.status = parsed.status;
    newEntry.timeStampUtc = new Date().toISOString();
    newEntry.level = this.parseLevel(parsed.status);

    const isErrorRelatedToAuthSvc = errorIsAuthSvcRelated(error);

    // if error is AuthSvc related then we add the current build up authsvc log
    // + we add the localstorage to the properties
    if (isErrorRelatedToAuthSvc && authSvc)
    {
      const authSvcLog = authSvc.getLogAsStr();
      newEntry.properties.authsvclog = authSvcLog;

      const localStorageStr = getLocalStorageAsStr();
      newEntry.properties.localstorage = localStorageStr;
    }

    return newEntry;
  }

  private static parseLevel(status: number): number {
    if (status && status >= 400 && status < 500) {
      return 3;  // warning
    }
    return 4; // error
  }

  public mergeProperties(properties: {[key: string]: object}): void
  {
    this.properties = {...this.properties, ...properties};
  }
}
