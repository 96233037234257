import { NavigationService } from './../../../core/services/navigation-service';
import { BreadcrumbConstants } from 'src/app/core/models/breadcrumb-constants';
import { Breadcrumb } from 'src/app/core/models/breadcrumb-model';
import { ThemaEnum } from 'src/app/models/thema.enum';
import { ActivatedRoute, Params } from '@angular/router';
import { VergunningService } from 'src/app/vergunning/vergunning.service';
import { AuthService } from './../../../auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/models/user';
import { take } from 'rxjs/operators';

@Component({
    selector: 'avgpv-pub-foute-product-hoedanigheid',
    templateUrl: './foute-product-hoedanigheid.component.html'
})
export class FouteProductHoedanigheidComponent implements OnInit, OnDestroy {
    public user: User;
    public themaRouteKey: string;
    public params: Params;

    private breadcrumbs: Breadcrumb[] = [];

    constructor(
        private navigationService: NavigationService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private vergunningService: VergunningService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe(params => this.params = params);
    }

    public ngOnInit(): void {
        this.authService.loggedInUser$.subscribe(user => this.user = user);

        this.vergunningService.getProduct(this.params.product).subscribe(product => {
            if (ThemaEnum.AVG.toLowerCase() === product.thema.toLowerCase()) {
                this.themaRouteKey = 'avg';
            } else if (ThemaEnum.PV.toLowerCase() === product.thema.toLowerCase()) {
                this.themaRouteKey = 'pv';
            }

            this.setupBreadcrumbs();
        });
    }

    public ngOnDestroy(): void {
        this.navigationService.clearBreadcrumbs(this.breadcrumbs);
    }

    private setupBreadcrumbs(): void {
        this.breadcrumbs = [{
            key: BreadcrumbConstants.Portaal,
            naam: BreadcrumbConstants.PortaalLabel,
            path: BreadcrumbConstants.PortaalRoute
        },
        {
            key: BreadcrumbConstants.ThemaKeuze,
            naam: BreadcrumbConstants.ThemaKeuzeLabel,
            path: BreadcrumbConstants.ThemaKeuzeRoute
        },
        {
            key: BreadcrumbConstants.ProductKeuze,
            naam: BreadcrumbConstants.ProductKeuzeLabel,
            path: `vergunning/${this.themaRouteKey}`
        },
        {
            key: BreadcrumbConstants.FoutieveHoedanigheid,
            naam: $localize`Foutieve hoedanigheid`,
            path: `foute-product-hoedanigheid?product=${this.params.product}&guard=${this.params.guard}`
        }];

        this.navigationService.setBreadcrumbs(this.breadcrumbs);
    }
}
