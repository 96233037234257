/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ApiException } from './../publicapi/d09.avgpv.public.client';
import { StatusMessageModel } from '../errorhandling';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { safeJSONStringify } from '../formatting';

export interface AvgpvHandleErrorOptions {
  statusMessageModel: StatusMessageModel;
  errorHandler?: (error: any, options: AvgpvHandleErrorOptions) => boolean;
  context?: string; // free form info (preferrably short) describing the functionaly that is executing
}

export type ProblemClass = 'status400' | 'status500';

export class ProblemDetails {
  public title: string;
  public detail: string;
  public status: number;
  public problemClass: ProblemClass;
  public instance: string;
  public stacktraces: string;
  public properties: any;
}

export function avgpvHandleError<T>(options: AvgpvHandleErrorOptions): (input: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => new Observable(subscriber => {

    source.subscribe(
      {
        next(value: T): void {

          subscriber.next(value);

        },

        error(error: any): void {

          const handler = options.errorHandler || avgpvDefaultErrorHandler;
          const isErrorHandled = handler(error, options);
          if (isErrorHandled) {
            subscriber.complete();
          }
          else {
            subscriber.error(error);
          }

        },

        complete(): void {

          subscriber.complete();

        }
      }
    );

  });
}

// responsibilities of error handler
// set the statusMessageModel
// return true or false to indicate if error has been handled
// In case of false, the error will be propagated further in the pipeline
export function avgpvDefaultErrorHandler(error: any, options: AvgpvHandleErrorOptions): boolean {
  // can be made much more complex, but at least it is at one central location

  const statusMessageModel = options.statusMessageModel;
  if (statusMessageModel) {
    const problemDetails = avgpvParseError(error);

    let detail = problemDetails?.detail;
    const code = problemDetails?.instance;
    const context = options?.context;

    if (!detail) {
      detail = $localize`:Errorhandler default message:Technische fout, probeer later opnieuw.`;
    }

    // currently no special behavior based on the error class
    // but for possible future usage, already some templated code

    if (problemDetails.problemClass === 'status400') {
      // behavior based on client errors (DomainExceptions)
    }

    if (problemDetails.problemClass === 'status500') {
      // behavior based on server errors (Exceptions)
    }

    statusMessageModel.setMessage(detail);
    statusMessageModel.setMessageCode(code);
    statusMessageModel.setMessageType('error');
    statusMessageModel.setHeader(context);
  }
  return true; // true : exception is handled
}

export function avgpvParseError(error: any): ProblemDetails {
  // can be made much more complicated: e.g. take into account ApiError
  const rv = new ProblemDetails();
  rv.properties = {};
  let problemDetailsObj: any;

  // if unhandled promise exception
  if (error?.rejection) {
    error = error.rejection;
  }

  if (error.isApiException) {
    const response = (error as ApiException)?.response;
    if (response || response === '') {
      try {
        // suggestion: possible exception could be avoided by first checking
        // error.headers.content-type === 'application/problem+json'
        problemDetailsObj = JSON.parse(response);
        rv.properties.traceid = error?.headers?.traceid;
        rv.properties.spanid = error?.headers?.spanid;
        rv.properties.traceparent = error?.headers?.traceparent;
      }
      catch
      {
        const status = error?.status;
        let detail = '';
        if (status === 504) {
          detail = 'Gateway Time-out(504)';
        }
        else if (status === 503) {
          detail = 'Service Unavailable(503)';
        }
        else if (status === 502) {
          detail = 'Bad Gateway(502)';
        }
        else {
          detail = `Http status code: ${status}`;
        }

        if (detail) {
          detail = detail + '\r\n';
        }

        detail = detail + response;

        problemDetailsObj = { detail };
      }
    }
  }
  else if (error instanceof HttpErrorResponse) {
    problemDetailsObj = error?.error;
    rv.properties.traceid = error.headers.get('traceid');
    rv.properties.spanid = error.headers.get('spanid');
    rv.properties.traceparent = error.headers.get('traceparent');
  }
  else {
    problemDetailsObj = error?.error;
  }

  if (!problemDetailsObj) {
    problemDetailsObj = {
      detail: error?.message,
      stacktraces: error?.stack
    };
  }

  if (problemDetailsObj) {
    rv.title = problemDetailsObj.title;
    rv.detail = problemDetailsObj.detail?.trim();
    rv.status = problemDetailsObj.status;
    rv.stacktraces = problemDetailsObj.stacktraces;
    rv.instance = problemDetailsObj.instance;
    rv.problemClass = decodeStatusIntoClass(rv.status);
  }

  rv.properties.error = safeJSONStringify(error);
  rv.properties.userAgent = getUserAgent();

  // copy all avgpvProperties to the properties object
  if (error?.avgpvProperties) {
    rv.properties = { ...rv.properties, ...error.avgpvProperties };
  }

  return rv;

}

function getUserAgent(): string {
  if (!window || !window.navigator) {
    return 'no navigator';
  }
  return window.navigator.userAgent;
}

export function decodeStatusIntoClass(status: number): ProblemClass {
  if (status >= 400 && status < 500) {
    return 'status400';
  }
  else if (status >= 500 && status < 600) {
    return 'status500';
  }
}

export function errorSetAvgpvProperty(error: any, propertyName: string, propertyValue: any): void {
  if (error && typeof error === 'object') {
    // aanmaken van avgpvProperties dictionary if necessary
    error.avgpvProperties = error.avgpvProperties ?? {};
    error.avgpvProperties[propertyName] = propertyValue;
  }

}

export function errorGetAvgpvProperty(error: any, propertyName: string): any {
  if (error && typeof error === 'object') {
    const properties = error?.avgpvProperties;
    if (properties) {
      const value = properties[propertyName];
      return value;
    }
    else { return undefined; }
  }
  else{
    return undefined;
  }
}

export function errorTagAuthenticationSvc(error): void {
  errorSetAvgpvProperty(error, 'isAvgpvAuthSvc', true);
}

export function errorIsTaggedAuthenticationSvc(error): boolean {
  const isAvgpvAuthSvc = errorGetAvgpvProperty(error, 'isAvgpvAuthSvc');
  return !!isAvgpvAuthSvc;
}

export function errorAddAvgpvScope(error: any, scope: string): void {

  if (scope) {
    const errorScopeArray = errorGetAvgpvProperty(error, 'scope') ?? [];

    errorScopeArray.push(scope);
    errorSetAvgpvProperty(error, 'scope', errorScopeArray);
  }

}

// by returning true,
// the clientlogentry will be treated specially
// by adding the authsvc log and the localstorage to the entry sent to the backend
// Normally this is detected by the tag on the error
// but some exceptions do not pass via our try catch blocks
// and are detected by the form of the exception
export function errorIsAuthSvcRelated(error: any): boolean {
  try {
    if (errorIsTaggedAuthenticationSvc(error)) {
      return true;
    }

    if (!error) {
      return false;
    }

    // we try to catch following form
    // {
    //     "type": "invalid_nonce_in_state",
    //     "reason": null,
    //     "params": null
    // }
    // If it has a type that contains the word nonce
    if (typeof error.type === 'string' && error.type.indexOf('nonce') >= 0) {
      return true;
    }
    //   {
    //     "headers": {
    //         "headers": {}
    //     },
    //     "status": 400,
    //     "statusText": "BAD REQUEST",
    //     "url": "https://authenticatie.vlaanderen.be/op/v1/token",
    //     "ok": false,
    //     "name": "HttpErrorResponse",
    //     "message": "Http failure response for https://authenticatie.vlaanderen.be/op/v1/token: 400 BAD REQUEST",
    //     "error": {
    //         "error": "invalid_grant"
    //     }
    // }
    if (typeof error.url === 'string' && error.url.indexOf('/op/v1/token') >= 0) {
      return true;
    }
  }
  catch (e) {
    // just failsafe
  }

  return false;
}
