<article class="overview-layout">
  <h1 #profielTitle i18n tabindex="-1">Mijn profiel</h1>
  <div>
    <h2 i18n>Profielgegevens</h2>

    <div *ngIf="params.guard" class="messages messages--warning" role="alert" aria-atomic="true">
      <i class="icon-exclamation" aria-hidden="true"></i>
      <div>
        <p i18n>We merken dat we nog niet over je volledige profielgegevens beschikken.</p>
        <p i18n>Je hebt deze nodig om een vergunning aan te vragen</p>
      </div>
    </div>

    <div *ngIf="showSuccessMessage" class="messages messages--status" role="alert" aria-atomic="true">
      <i class="icon-checkmark" aria-hidden="true"></i>
      <p i18n>Profielgegevens zijn bijgewerkt.</p>
    </div>

    <avgpv-pub-persoon-form *ngIf="user?.persoon as persoon" [persoon]="persoon" [strongAuth]="user.strong"
      [disabledFields]="disabledFields" (submitForm)="submitPersoon($event)" (scrollToHeader)="scrollToHeader()">
    </avgpv-pub-persoon-form>

    <avgpv-pub-onderneming-form *ngIf="user?.onderneming as onderneming" [strongAuth]="user.strong"
      [onderneming]="onderneming" [disabledFields]="disabledFields" (submitForm)="submitOnderneming($event)"
      (scrollToHeader)="scrollToHeader()">
    </avgpv-pub-onderneming-form>

    <div *ngIf="loading$ | async" class="spinner">
      <div i18n>&hellip;loading</div>
    </div>
  </div>
</article>