import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientLogEntry } from '../../models/log-entry';

/**
 * This is a separate service because the ErrorHandler class does not act as a singleton.
 */
@Injectable({
  providedIn: 'root'
})
export class LogEntryService {
  public logEntry$: BehaviorSubject<ClientLogEntry> = new BehaviorSubject(null);

  constructor() { }
}
