/* eslint-disable prefer-arrow/prefer-arrow-functions */

function generateUUIDUsingMathRandom(): string {
  /* eslint-disable no-bitwise */
  let d = new Date().getTime(); // Timestamp
  let d2 = (performance && performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {// Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {// Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    /* eslint-enable no-bitwise */
  });
}

function getOrCreateLocalStorageApplicationId(): string
{
  const storage = window.localStorage;

  let applicationId = storage.getItem('applicationId');
  if (applicationId == null)
  {
    applicationId = generateUUIDUsingMathRandom();
    storage.setItem('applicationId', applicationId);
  }
  return applicationId;
}

function getOrCreateSessionStorageApplicationId(): string
{
  const storage = window.sessionStorage;

  let applicationId = storage.getItem('applicationId');
  if (applicationId == null)
  {
    applicationId = generateUUIDUsingMathRandom();
    storage.setItem('applicationId', applicationId);
  }
  return applicationId;
}

const applicationInstanceId = generateUUIDUsingMathRandom();
const started = performance.now();
const startedDateTime = new Date();
const startedDateTimeUnixEpochUTC = startedDateTime.getTime();
const localStorageApplicationId = getOrCreateLocalStorageApplicationId();
const sessionStorageApplicationId = getOrCreateSessionStorageApplicationId();

export function getApplicationInstanceId(): string {
  return applicationInstanceId;
}

export function  getLocalStorageApplicationInstanceId(): string {
  return localStorageApplicationId;
}

export function  getSessionStorageApplicationInstanceId(): string {
  return sessionStorageApplicationId;
}

export function getApplicationElapsedSinceStartedMilliseconds(): number {
  return Math.trunc(performance.now() - started);
}

export function getApplicationStartedUnixEpochUTCMilliseconds(): number {
  return startedDateTimeUnixEpochUTC;
}
