import { BreadcrumbConstants } from '../core/models/breadcrumb-constants';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Onderneming } from 'src/app/models/onderneming';
import { Persoon } from 'src/app/models/persoon';
import { User } from 'src/app/models/user';
import { NavigationService } from '../core/services/navigation-service';
import { Breadcrumb } from '../core/models/breadcrumb-model';

@Component({
    selector: 'avgpv-pub-profiel-pagina',
    templateUrl: './profiel-pagina.component.html'
})
export class ProfielPaginaComponent implements OnInit, OnDestroy {
    @ViewChild('profielTitle', { static: true }) profielTitle: ElementRef;

    public user: User;
    public loading$ = new BehaviorSubject<boolean>(true);
    public disabledFields: string[] = [];
    public productUserTypeNotAllowed = false;
    public params: Params;
    public showSuccessMessage = false;

    private destroyed = new Subject<void>();

    private breadcrumbs: Breadcrumb[] = [];

    constructor(
        private navigationService: NavigationService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
    ) {
        this.route.queryParams.pipe(take(1)).subscribe(params => this.params = params);

        this.authService.loggedInUser$
            .pipe(takeUntil(this.destroyed), tap(u => this.user = u))
            .subscribe(u => {
                if (u) // u can be null => was issue when e.g. token was expired
                {
                    if (u.strong) {
                        this.disabledFields.push('naam');

                        if (u.persoon) {
                            this.disabledFields.push('voorNaam');
                            this.disabledFields.push('rijksRegisterNummer');
                        }

                        if (u.onderneming) {
                            this.disabledFields.push('ondernemingsNummer');
                        }
                    } else {
                        if (u.email) {
                            this.disabledFields.push('email');
                        }
                    }

                    this.loading$.next(false);
                }
            });
    }

    public ngOnInit(): void {
        this.setupBreadcrumbs();
    }

    public ngOnDestroy(): void {
        this.navigationService.clearBreadcrumbs(this.breadcrumbs);

        this.destroyed.next();
        this.destroyed.unsubscribe();
    }

    public async submitPersoon(persoon: Persoon): Promise<void> {
        this.showSuccessMessage = false;
        this.loading$.next(true);

        persoon.id = this.user.persoon.id;
        persoon.reference = this.user.persoon.reference;

        await this.authService.saveLoggedInPersoon(persoon);

        this.loading$.next(false);

        this.handleSuccess();
    }

    public async submitOnderneming(onderneming: Onderneming): Promise<void> {
        this.showSuccessMessage = false;
        this.loading$.next(true);

        onderneming.id = this.user.onderneming.id;
        onderneming.reference = this.user.onderneming.reference;

        await this.authService.saveLoggedInOnderneming(onderneming);

        this.loading$.next(false);

        this.handleSuccess();
    }

    public scrollToHeader(): void {
        setTimeout(() => {
            this.profielTitle.nativeElement.focus();
            this.profielTitle.nativeElement.scrollIntoView();
        });
    }

    private setupBreadcrumbs(): void {
        this.breadcrumbs = [{
            key: BreadcrumbConstants.Portaal,
            naam: BreadcrumbConstants.PortaalLabel,
            path: BreadcrumbConstants.PortaalRoute
        },
        {
            key: BreadcrumbConstants.ProfielPagina,
            naam: $localize`Mijn profiel`,
            path: 'profiel'
        }];

        this.navigationService.setBreadcrumbs(this.breadcrumbs);
    }

    private handleSuccess(): void {
        if (this.params.return) {
            this.redirectToUrl(this.params.return);
        } else {
            this.showSuccessMessage = true;
            this.scrollToHeader();
        }
    }

    private redirectToUrl(returnUrl: string): void {
        this.router.navigateByUrl(returnUrl);
    }
}
