import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Breadcrumb } from '../../models/breadcrumb-model';
import { NavigationService } from '../../services/navigation-service';

@Component({
    selector: 'avgpv-pub-breadcrumbs',
    templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit, OnChanges {

    @Input() public childIsTabletOrLarger: boolean;

    public expanded: boolean;
    public breadcrumbs$: Observable<Breadcrumb[]>;

    constructor(private navigationService: NavigationService) {
    }

    public ngOnInit(): void {
        this.getBreadcrumbs();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.childIsTabletOrLarger.currentValue !== changes.childIsTabletOrLarger.previousValue) {
            this.getBreadcrumbs();
        }
    }

    public getBreadcrumbs(): void {
        this.breadcrumbs$ = this.navigationService.breadcrumbs$.pipe(
            tap((x: Array<Breadcrumb>) => this.expanded = this.getExpanded(x))
        );
    }

    public getExpanded(crumbs: Breadcrumb[]): boolean {
        return (this.childIsTabletOrLarger && crumbs?.length > 5) || (!this.childIsTabletOrLarger && crumbs?.length > 2);
    }
}

