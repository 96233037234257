import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'Truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, start: number, end?: number): string {
        if (value.length > end) {
            return value.substring(start, end) + '...';
        }

        return value;
    }
}
