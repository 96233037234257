/* eslint-disable @typescript-eslint/naming-convention */
export class BreadcrumbConstants {
    public static readonly BezoekerRegistreren = 'bezoeker_registreren';
    public static readonly DetailVergunning = 'detail_vergunning';
    public static readonly FavorietenBeheer = 'favorieten_beheer';
    public static readonly FoutieveHoedanigheid = 'foutieve_hoedanigheid';

    public static readonly IbannummerWijzigen = 'iban_nummer_wijzigen';

    public static readonly BezoekersbeheerAutovrijGebied = 'bezoekersbeheer_autovrij_gebied';
    public static readonly BezoekersbeheerAutovrijgGebiedLabel = $localize`Bezoekersbeheer autovrij gebied`;
    public static readonly BezoekersbeheerAutovrijGebiedRoute = 'bezoeker/beheer';

    public static readonly MijnMobiliteitsvergunningen = 'mijn_mobiliteitsvergunningen';
    public static readonly MijnMobiliteitsvergunningenLabel = $localize`Mijn mobiliteitsvergunningen`;
    public static readonly MijnMobiliteitsvergunningenRoute = 'vergunning/lijst';

    public static readonly Portaal = 'portaal';
    public static readonly PortaalLabel = $localize`Portaal`;
    public static readonly PortaalRoute = 'portaal';

    public static readonly ProductKeuze = 'product_keuze';
    public static readonly ProductKeuzeLabel = $localize`Product keuze`;

    public static readonly ProfielPagina = 'profielpagina';

    public static readonly ThemaKeuze = 'thema_keuze';
    public static readonly ThemaKeuzeLabel = $localize`Thema keuze`;
    public static readonly ThemaKeuzeRoute = 'vergunning';

    public static readonly VergunningAanvraag = 'vergunning_aanvraag';
    public static readonly Winkelmandje = 'winkelmandje';

    public static readonly PermanentRechtWijzigen = 'permanentrechtwijzigen';
    public static readonly PermanentRechtToevoegen = 'permanentrechttoevoegen';

    public static readonly StopZetten = 'stopzetten';
    public static readonly StopZettenLabel = $localize`Vergunning stopzetten`;
}
