import { ValidateProfielGuard } from './shared/guards/validate-profiel.guard';
import { AlgemeneVoorwaardenComponent } from './shared/components/algemene-voorwaarden/algemene-voorwaarden.component';
import { LoginAcmIdmComponent } from './auth/components/loginacmidm/login-acm-idm/login-acm-idm.component';
import { CookiebeleidComponent } from './shared/components/cookiebeleid/cookiebeleid.component';
import { UitzonderingsprocedureComponent } from './auth/components/uitzonderingsprocedure/uitzonderingsprocedure.component';
import { HoedanigheidComponent } from './shared/components/hoedanigheid/hoedanigheid.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/components/auth/auth.component';
import { CallbackComponent } from './auth/components/callback/callback.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PageInternalServerErrorComponent } from './core/page-internal-server-error/page-internal-server-error.component';
import { PageServiceUnavailableComponent } from './core/page-service-unavailable/page-service-unavailable.component';
import { LogoutComponent } from './auth/components/logout/logout.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { LoginGuard } from './auth/guards/login.guard';
import { FouteProductHoedanigheidComponent } from './shared/components/foute-product-hoedanigheid/foute-product-hoedanigheid.component';
import { ProfielPaginaComponent } from './profiel-pagina/profiel-pagina.component';
import { PortaalComponent } from './portaal/portaal.component';
import {
    VervangvoertuigWizardComponent
} from "./vergunning/containers/vervangvoertuig-wizard/vervangvoertuig-wizard.component";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'portaal',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: AuthComponent,
        data: { title: $localize`:pagina titel:Login` }
    },
    {
        path: 'uitzonderingsprocedure',
        component: UitzonderingsprocedureComponent,
        data: { title: $localize`: pagina titel: Uitzonderingsprocedure` }
    },
    {
        path: 'callback/:issuer',
        component: CallbackComponent,
        data: { title: $localize`:pagina titel:Login` }
    },
    {
      path: 'loginacmidm',
      component: LoginAcmIdmComponent,
      data: { title: $localize`:pagina titel:Login Vlaanderen` }
    },
    {
        path: 'logout/:issuer',
        component: LogoutComponent,
        data: { title: $localize`:pagina titel:Logout` }
    },
    {
        path: 'hoedanigheid',
        component: HoedanigheidComponent,
        canActivate: [AuthGuard, ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Hoedanigheid` }
    },
    {
        path: 'profiel',
        component: ProfielPaginaComponent,
        canActivate: [LoginGuard],
        data: { title: $localize`:pagina titel:Profielpagina` }
    },
    {
        path: 'foute-product-hoedanigheid',
        component: FouteProductHoedanigheidComponent,
        canActivate: [ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Foute product hoedanigheid` }
    },
    {
        path: '500',
        component: PageInternalServerErrorComponent,
        data: { title: $localize`:pagina titel:Error` }
    },
    {
        path: '503',
        component: PageServiceUnavailableComponent,
        data: { title: $localize`:pagina titel:Error` }
    },
    {
        path: 'vergunning',
        loadChildren: () => import('./vergunning/vergunning.module').then(m => m.VergunningModule),
        canActivate: [ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Vergunning` }
    },
    {
        path: 'portaal',
        component: PortaalComponent,
        canActivate: [ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Portaal` },
    },
    {
        path: 'order',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
        canActivate: [LoginGuard, ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Winkelmandje` }
    },
    {
        path: 'bezoeker',
        loadChildren: () => import('./bezoeker/bezoeker.module').then(m => m.BezoekerModule),
        canActivate: [LoginGuard, ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Bezoeker rechten` }
    },
    {
        path: 'algemene-voorwaarden',
        component: AlgemeneVoorwaardenComponent,
        canActivate: [ValidateProfielGuard],
        data: { title: $localize`:pagina titel: Algemene voorwaarden`}
    },
    {
        path: 'cookie-declaration',
        redirectTo: 'cookiebeleid'
    },
    {
        path: 'cookiebeleid',
        component: CookiebeleidComponent,
        canActivate: [ValidateProfielGuard],
        data: { title: $localize`:pagina titel:Cookiebeleid` }
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: $localize`:pagina titel:Pagina niet gevonden` }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
