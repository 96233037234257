import {
  AantallenControleValidationMessageComponent, AddressComponent, AlgemeneVoorwaardenComponent, AlgemeneVoorwaardenModalComponent,
  ConfirmComponent, CookiebeleidComponent, FouteProductHoedanigheidComponent, HoedanigheidComponent,
  InputDateComponent, InputFileComponent, StatusMessageComponent
} from './components';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreakEmailPipe } from './pipes/break-email.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DateValueAccessorDirective } from './directives/date-value-accessor.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { ArraySortPipe } from './pipes/array-sort.pipe';

const angularModules = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  FileUploadModule
];

const sharedComponents = [
  AlgemeneVoorwaardenComponent,
  AlgemeneVoorwaardenModalComponent,
  TruncatePipe,
  BreakEmailPipe,
  ArraySortPipe,
  AddressComponent,
  CookiebeleidComponent,
  ConfirmComponent,
  InputDateComponent,
  DateValueAccessorDirective,
  InputFileComponent,
  HoedanigheidComponent,
  FouteProductHoedanigheidComponent,
  StatusMessageComponent,
  AantallenControleValidationMessageComponent
];

@NgModule({
  declarations: sharedComponents,
  exports: [
    angularModules,
    sharedComponents
  ],
  imports: [
    angularModules
  ]
})
export class SharedModule { }
