import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageInternalServerErrorComponent } from './page-internal-server-error/page-internal-server-error.component';
import { PageServiceUnavailableComponent } from './page-service-unavailable/page-service-unavailable.component';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { PageHomeComponent } from './page-home/page-home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatureModule } from '../feature/feature.module';
import { NavigationService } from './services/navigation-service';

@NgModule({
    declarations: [
        BreadcrumbsComponent,
        FooterComponent,
        HeaderComponent,
        PageNotFoundComponent,
        PageInternalServerErrorComponent,
        PageServiceUnavailableComponent,
        PageHomeComponent
    ],
    exports: [
        FooterComponent,
        HeaderComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule,
        FeatureModule
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import Core module in the AppModule only.');
        }
    }

    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                NavigationService,
                { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
            ]
        };
    }
}
