import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Breadcrumb } from '../models/breadcrumb-model';

@Injectable()
export class NavigationService {
    public breadcrumbs$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject(null);

    private breadcrumbs: Breadcrumb[] = [];

    public setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        // make sure we start with an empty array
        this.breadcrumbs = [];

        breadcrumbs.forEach((breadcrumb: Breadcrumb) => {
            this.addBreadcrumb(breadcrumb);
        });
    }

    public addBreadcrumb(breadcrumb: Breadcrumb): void {
        const breadcrumbFound = this.breadcrumbs.find(x => x.key === breadcrumb.key);
        if (!breadcrumbFound) {
            this.breadcrumbs.push(breadcrumb);
            this.breadcrumbs$.next(this.breadcrumbs);
        }
    }

    public clearBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
        breadcrumbs.forEach((breadcrumb: Breadcrumb) => {
            this.removeBreadcrumb(breadcrumb.key);
        });
    }

    public removeBreadcrumb(key: string): void {
        const breadcrumbFound = this.breadcrumbs.find(x => x.key === key);
        const index = this.breadcrumbs.indexOf(breadcrumbFound);
        this.breadcrumbs.splice(index, 1);
        this.breadcrumbs$.next(this.breadcrumbs);
    }
}
