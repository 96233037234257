<ng-template #formFields [formGroup]="formGroup">
  <div class="form-item" *ngIf="hiddenFields.indexOf('landIso2Code') === -1">
    <label [for]="uniqueIdPRefix + 'land'" i18n>Land</label>
    <div class="form-columns">
      <div class="form-item-column">
        <select formControlName="landIso2Code" [id]="uniqueIdPRefix + 'land'" autocomplete="country"
          (change)="onUserChangedLand($event.target.value)">
          <option [ngValue]="land.code" *ngFor="let land of landen$ | async" [innerHTML]="land.naam">
          </option>
        </select>
      </div>
      <div class="form-item-column">
      </div>
    </div>
  </div>

  <div class='form-row' *ngIf="isVisible('gemeente') &&  isVisible('postCode')">
    <div class="form-item  stacked" *ngIf="isVisible('postCode')">
      <label [for]="uniqueIdPRefix + 'postcode'" i18n>Postcode</label>
      <div class="form-columns">
        <div class="form-item-column">
          <input type="text" [id]="uniqueIdPRefix + 'postcode'" (change)="onUserChangedPostcode($event)"
            autocomplete="postal-code" formControlName="postCode" required [attr.aria-invalid]="isInvalid('postCode')"
            [ngClass]="{'error': isInvalid('postCode')}"
            [attr.aria-describedby]="isInvalid('postCode') ? uniqueIdPRefix + 'postCode--error' : null" />
        </div>
        <div *ngIf="isInvalid('postCode')" class="form-item-column">
          <div *ngIf="formGroup.get('postCode').hasError('required')" class="field-message error" role="alert"
            [id]="uniqueIdPRefix + 'postCode--error'" i18n>
            Postcode is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-item  stacked" *ngIf="isVisible('gemeente')">
      <label [for]="uniqueIdPRefix + 'gemeente'" i18n>Gemeente</label>
      <div class="form-columns">
        <div class="form-item-column">
          <input type="text" [id]="uniqueIdPRefix + 'gemeente'" autocomplete="address-level2" formControlName="gemeente"
            (change)="onUserChangedGemeente($event)" required [attr.aria-invalid]="isInvalid('gemeente')"
            [ngClass]="{'error': isInvalid('gemeente')}"
            [attr.aria-describedby]="isInvalid('gemeente') ? uniqueIdPRefix + 'gemeente--error' : null" />
        </div>
        <div *ngIf="isInvalid('gemeente')" class="form-item-column">
          <div *ngIf="formGroup.get('gemeente').hasError('required')" class="field-message error" role="alert"
            [id]="uniqueIdPRefix + 'gemeente--error'" i18n>
            Gemeente is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-item">
    <label [for]="isGentsePostCode ? uniqueIdPRefix + 'straat' : uniqueIdPRefix + 'straat--classic'" i18n>
      Straat
    </label>
    <div class="form-columns">
      <div [style.display]="isGentsePostCode ? 'block': 'none'" class="form-item-column"
        [class.autocomplete--error]="straatError$ | async" #straatAutocomplete>
      </div>
      <div [style.display]="!isGentsePostCode ? 'block': 'none'" class="form-item-column">
        <input type="text" [id]="uniqueIdPRefix + 'straat--classic'" formControlName="straat" required
          [attr.aria-invalid]="isInvalid('straat')" [ngClass]="{'error': isInvalid('straat')}"
          [attr.aria-describedby]="isInvalid('straat') ? uniqueIdPRefix + 'straat--error' : null">
      </div>
      <div class="form-item-column">
        <div *ngIf="!(straatError$ | async) && isInvalid('straat') && formGroup.get('straat').hasError('required')"
          class="field-message error" role="alert" [id]="uniqueIdPRefix + 'straat--error'" i18n>
          Straat is een verplicht veld en werd niet ingevuld.
          <div class="accolade"></div>
        </div>
        <div *ngIf="isGentsePostCode && straatError$ | async as error" class="field-message error" role="alert"
          [id]="uniqueIdPRefix + 'straat--error'">
          {{ error }}
          <div class="accolade"></div>
        </div>
      </div>
    </div>
  </div>

  <div class='form-row' *ngIf="isGentsePostCode">
    <div class="form-item stacked">
      <label [for]="uniqueIdPRefix + 'huisnummer'" i18n>Huisnummer</label>
      <div class="form-columns">
        <div class="form-item-column">
          <select formControlName="huisNummer" id="huisNummer" (change)="onUserChangedHuisnummer($event.target.value)">
            <option [ngValue]="nummer" *ngFor="let nummer of huisNummers">
              {{nummer}}
            </option>
          </select>
        </div>
        <div class="form-item-column">
        </div>
        <div *ngIf="isInvalid('huisNummer')" class="form-item-column">
          <div *ngIf="formGroup.get('huisNummer').hasError('required')" class="field-message error" role="alert"
            [id]="uniqueIdPRefix + 'huisNummer--error'" i18n>
            Huisnummer is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-item  stacked">
      <label [for]="uniqueIdPRefix + 'busnummer'">
        <ng-container i18n>Busnummer</ng-container>&nbsp;
        <span *ngIf="!(busNummers.length > 0)" class="label-optional" i18n>Optioneel</span>
      </label>
      <div class="form-columns">
        <div class="form-item-column">
          <select formControlName="busNummer" id="busNummer">
            <option [ngValue]="nummer" *ngFor="let nummer of busNummers">
              {{nummer}}
            </option>
          </select>
        </div>
        <div *ngIf="isInvalid('busNummer')" class="form-item-column">
          <div *ngIf="formGroup.get('busNummer').hasError('required')" class="field-message error" role="alert"
            [id]="uniqueIdPRefix + 'huisNummer--error'" i18n>
            Busnummer is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class='form-row' *ngIf="!isGentsePostCode">
    <div class="form-item stacked">
      <label [for]="uniqueIdPRefix + 'huisnummer'" i18n>Huisnummer</label>
      <div class="form-columns">
        <div class="form-item-column">
          <input type="text" [id]="uniqueIdPRefix + 'huisnummer'" autocomplete="on" formControlName="huisNummer"
            required [attr.aria-invalid]="isInvalid('huisNummer')" [ngClass]="{'error': isInvalid('huisNummer')}"
            [attr.aria-describedby]="isInvalid('huisNummer') ? uniqueIdPRefix + 'huisNummer--error' : null">
        </div>
        <div *ngIf="isInvalid('huisNummer')" class="form-item-column">
          <div *ngIf="formGroup.get('huisNummer').hasError('required')" class="field-message error" role="alert"
            [id]="uniqueIdPRefix + 'huisNummer--error'" i18n>
            Huisnummer is een verplicht veld en werd niet ingevuld.
            <div class="accolade"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-item  stacked">
      <label [for]="uniqueIdPRefix + 'busnummer'">
        <ng-container i18n>Busnummer</ng-container>&nbsp;
        <span class="label-optional" i18n>Optioneel</span>
      </label>
      <div class="form-columns">
        <div class="form-item-column">
          <input type="text" [id]="uniqueIdPRefix + 'busnummer'" autocomplete="on" formControlName="busNummer" />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<fieldset *ngIf="!!legend">
  <legend [innerText]="legend"></legend>
  <ng-container *ngTemplateOutlet="formFields"></ng-container>
</fieldset>

<div *ngIf="!legend">
  <ng-container *ngTemplateOutlet="formFields"></ng-container>
</div>
<div *ngIf="(isLoading$ | async)" class="spinner">
  <div i18n>&hellip;loading</div>
</div>