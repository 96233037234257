<article class="detail-layout">
  <ng-container *ngIf="params.guard">
    <h1 i18n>Meld je aan om verder te gaan</h1>
  </ng-container>

  <h1 i18n *ngIf="!params.guard">Aanmelden</h1>

  <div *ngIf="isPartiallyAuthenticated$ | async; else login">

    <div *ngIf="!(isAuthenticated$ | async)" class="messages messages--warning">
      <i class="icon-exclamation" aria-hidden="true"></i>
      <p i18n>Authenticatie is niet volledig gelukt</p>
    </div>

    <div *ngIf="!(isLoggedin$ | async)" class="messages messages--warning">
      <i class="icon-exclamation" aria-hidden="true"></i>
      <p i18n>Profiel is niet geladen.<br>
        Probeer de web pagina te herladen of probeer eerst eens af te melden en opnieuw in te loggen</p>
    </div>

    <div *ngIf="isLoggedin$ | async" class="messages messages--warning">
      <i class="icon-exclamation" aria-hidden="true"></i>
      <p i18n>Je bent aangemeld.<br>
        Wil je aanmelden onder een andere hoedanigheid? Meld je eerst af.</p>
    </div>

  </div>

  <ng-template #login>
    <div>
      <p i18n>Heb je een Belgische e-ID of ben je een Belgische onderneming?<br>
        Dan kan je eenvoudig aanmelden met itsme&#169; of met je kaartlezer.</p>
    </div>
    <div>
      <a [routerLink]="[]" (click)="loginACM()" class="button button-primary" i18n>Aanmelden</a>
    </div>
    <div>
      <p i18n>Ben je een buitenlandse onderneming of heb je geen Belgische e-ID?
        Neem contact op met het Mobiliteitsbedrijf.
      </p>
      <a href="https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent" class="standalone-link" i18n>Ga
        naar onze contactgegevens</a>
    </div>
  </ng-template>
</article>