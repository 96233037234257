import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../../auth.service';

@Component({
    templateUrl: './uitzonderingsprocedure.component.html'
})
export class UitzonderingsprocedureComponent {
    public isAuthenticated$;
    public isLoggedin$;
    public params: Params;

    constructor(private authService: AuthService, private route: ActivatedRoute) {
        this.isAuthenticated$ = authService.isAuthenticated$;
        this.isLoggedin$ = authService.isLoggedin$;
        this.route.queryParams.pipe(take(1)).subscribe(params => this.params = params);
    }

    public loginSimple(): void {
        this.authService.loginItb(this.params.return);
    }
}
