/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { ClientLoggingService } from './../core/services/client-logging.service';
import {
  UsersApi, ISynchronizeMagdaPersoonParameters,
  UserSelector, SynchronizeMagdaPersoonParameters,
  ISynchronizeMagdaOndernemingParameters, SynchronizeMagdaOndernemingParameters,
  FileResponse
} from './../shared/publicapi/d09.avgpv.public.client';
import { LoggedOnUser } from './../models/logged-on-user';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthConfig, OAuthErrorEvent, OAuthService, OAuthStorage, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ACMConfig, itbConfig } from './auth.config';
import { Router } from '@angular/router';
import { EnvironmentService } from '../environment.service';
import { User } from '../models/user';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Onderneming } from '../models/onderneming';
import { Persoon } from '../models/persoon';
import { UserTypeEnum } from '../models/userType.enum';
import { IdReference } from '../models/id-reference';
import { devfeatures } from '../utils/devfeatures';
import {
  getApplicationInstanceId, getApplicationElapsedSinceStartedMilliseconds,
  getApplicationStartedUnixEpochUTCMilliseconds,
  getLocalStorageApplicationInstanceId,
  getSessionStorageApplicationInstanceId
} from '../shared/application-instance';
import { ClientLogLevel } from '../models/log-entry';
import { errorAddAvgpvScope, errorTagAuthenticationSvc } from '../shared/errorhandling';
import { formatError, formatObject } from '../shared/formatting';
import { getLocalStorageAsStr } from '../shared/storage';

interface TokenExchangeResponse {
  access_token: string;
  expires_in: number;
  issued_token_type: string;
  scope: string;
  token_type: string;
}

/**
 * The interceptor will inject header Authorization into request only for
 * backend api calls.
 * If loggedon with acmidm then the identity token instead of the access token will be sent
 */
@Injectable()
export class IDTokenInterceptor implements HttpInterceptor {

  constructor(private environment: EnvironmentService, private authStorage: OAuthStorage,
              private authSvc: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // func contains the calling of the next handler, which returns an observable
    // and also does some 'recording' of some requests
    const doInterceptAndCallNextFunc = () => {

      const apiBaseUri = this.environment.api.baseUri;

      if (req.url.startsWith(apiBaseUri)) {
        const oauthIssuer = this.authStorage.getItem('oauth_issuer');
        let authorizationHeaderValue: string;

        if (oauthIssuer === this.environment.acmIdm.issuer) {
          if (this.environment.acmIdm.enableIdTokenAsAccessToken) {
            authorizationHeaderValue = this.authStorage.getItem('id_token');
          }
          else if (this.environment.acmIdm.enableTokenExchange) {
            // validity check very important to avoid sending invalid tokens to backend,
            // this will trigger backend request to introspection endpoint
            // with invalid token, and this will trigger rate_limit issues
            if (this.hasValidAccessTokenForTokenExchange()) {
              const avgpvAccessToken = this.authStorage.getItem('avgpv_access_token');
              authorizationHeaderValue = avgpvAccessToken;
            }
          }
        }
        else {
          authorizationHeaderValue = this.authStorage.getItem('access_token');
        }

        let headers: HttpHeaders = req.headers;

        try {
          const applicationInstanceId = getApplicationInstanceId();
          const localStorageApplicationInstanceId = getLocalStorageApplicationInstanceId();
          const sessionStorageApplicationInstanceId = getSessionStorageApplicationInstanceId();
          const applicationElapsedMillisecondsStr = getApplicationElapsedSinceStartedMilliseconds()?.toString();
          const applicationStartedUnixEpochUTCMillisecondsStr = getApplicationStartedUnixEpochUTCMilliseconds()?.toString();
          headers = headers.set('Application-Instance-Id', applicationInstanceId);
          headers = headers.set('Application-Instance-Id-Localstorage', localStorageApplicationInstanceId);
          headers = headers.set('Application-Instance-Id-Sessionstorage', sessionStorageApplicationInstanceId);
          headers = headers.set('Application-Elapsed-Milliseconds', applicationElapsedMillisecondsStr);
          headers = headers.set('Application-Started-Epoch-UTC', applicationStartedUnixEpochUTCMillisecondsStr);
        }
        catch (e) {
          // intentionally do nothing
        }

        if (authorizationHeaderValue) {
          headers = headers.set('Authorization', 'Bearer ' + authorizationHeaderValue);
        }

        if (headers) {
          req = req.clone({ headers });
        }
      }

      let recordRequest = false;

      // enable request recording for requests acmidm vlaanderen
      if (req.url.indexOf('vlaanderen.be/op/v1') >= 0) {
        recordRequest = true;
        const fmt = formatObject(req);

        this.authSvc.logInternal('AuthService', `HttpInterceptor - request -\r\n${fmt}`);
      }

      const rv = next.handle(req).pipe(tap(
        e => {
          if (recordRequest) {

            if (e instanceof HttpResponse) {
              const fmt = formatObject(e);
              this.authSvc.logInternal('AuthService', `HttpInterceptor - response -\r\n${fmt}`);
            }
          }

        },
        error => {
          const fmt = formatObject(error);
          this.authSvc.logInternal('AuthService', `HttpInterceptor - response ERROR -\r\n${fmt}`);
          this.authSvc.logInternal(`AuthService - HttpInterceptor - response ERROR - localstorage:\r\n${getLocalStorageAsStr()}`);
        }
      ));

      return rv;
    };

    // sometimes add some wait, in the hope that a valid avgpv access token will be available
    // when tokenexchange is enabled (for acmidm) and we have the first token (a valid one) but the second token is not valid
    // then we assume that in this process or in another browser tab a refresh is in progress, so by waiting a bit
    // the chance exists that will have a valid access token
    // INFO: this is still not bullet proof, especially with a lot of parallel open browser tabs, then chances increases that
    // another tab page is starting another refresh.
    // So we could consider: to keep waiting until second access token is valid (currently not implemented)

    const introduceDelayIfRequiredFunc = () => {
      const needsDelay = this.authSvc.isTokenExchangeEnabled && this.authSvc.hasValidAccessToken && !this.authSvc.hasValidAvgpvAccessToken;

      if (needsDelay) {
        return of([1]).pipe(
          delay(1000),
          mergeMap(() => {
            this.authSvc.logInternal('AuthService', `HttpInterceptor - delay has been added because of not valid second access token - url:${req?.url}`);
            return introduceDelayIfRequiredFunc();
          }));
      }
      else {
        return doInterceptAndCallNextFunc();
      }
    };

    const rv2 = introduceDelayIfRequiredFunc();
    return rv2;

  }

  private hasValidAccessTokenForTokenExchange(): boolean {
    const accessToken = this.authStorage.getItem('avgpv_access_token');
    const expiresEpoch = parseInt(this.authStorage.getItem('avgpv_expires_in_epoch'), 10);
    const nowEpoch = Math.floor(new Date().valueOf() / 1000);
    const linkedClientAccessToken = this.authStorage.getItem('avgpv_access_token_client');
    const currentClientAccessToken = this.authStorage.getItem('access_token');

    const validAccessToken = (accessToken && expiresEpoch > nowEpoch && linkedClientAccessToken === currentClientAccessToken);
    return validAccessToken;
  }
}

declare let window: any;

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private oauthService: OAuthService,
    private httpClient: HttpClient,
    private authStorage: OAuthStorage,
    private router: Router,
    private usersApi: UsersApi,
    private environment: EnvironmentService,
    private clientLoggingService: ClientLoggingService,
    @Inject(LOCALE_ID) locale: string) {

    window.authsvc = this;

    this.logInternal('AuthService - Service Created', true);

    this.logInternal('AuthService', 'ApplicationInstanceId', getApplicationInstanceId(),
      'ApplicationElapsedSinceStartedMilliseconds', getApplicationElapsedSinceStartedMilliseconds()
      , 'ApplicationStartedUnixEpochUTCMilliseconds', getApplicationStartedUnixEpochUTCMilliseconds());

    this.logInternal(`AuthService - Localstorage: ${getLocalStorageAsStr()}`);

    this.baseHref = locale.substr(0, 2);

    this.isDoneLoading$.subscribe(p => {
      this.logInternal(`AuthService - isDoneLoading$ - isDoneLoading:`, p);
    });

    this.isPartiallyAuthenticatedDebounce$.subscribe(p => {
      this.authenticatedEventCount = this.authenticatedEventCount + 1;

      this.logInternal('AuthService - isPartiallyAuthenticatedDebounce$ - isPartiallyAuthenticated:', p,
        'count:', this.authenticatedEventCount);

      if (this.authenticatedEventCount > 1 && !p) {
        // navigate to portal page
        if (this.debugLoggingEnabled) {
          console.log(`not authenticated anymore - redirecting to portal page`);
        }
        this.clientLoggingService.logMessage(ClientLogLevel.Information, `not authenticated anymore - calling logOut and redirecting to portal page`);
        this.logInternal('AuthService', `not authenticated anymore - calling oauthService.logOut(true) and redirecting to portal page`);

        // this.oauthService.stopAutomaticRefresh();
        this.oauthService.logOut(true); // clean up tokens (no redirect) // experiment
        this.user = null;
        this.publishUser();

        this.router.navigateByUrl('/');
      }

    });

    this.isAuthenticatedNoDebounce$.subscribe(p => {
      this.logInternal('AuthService - isAuthenticatedNoDebounce$ - isAuthenticated:', p, 'count:', this.authenticatedEventCount);
    });

    this.isPartiallyAuthenticatedDebounce$.subscribe(p => {
      this.logInternal('AuthService - isPartiallyAuthenticatedDebounce$ - isPartiallyAuthenticated:', p);
    });

    this.isPartiallyAuthenticatedNoDebounce$.subscribe(p => {
      this.logInternal('AuthService - isPartiallyAuthenticatedNoDebounce$ - isPartiallyAuthenticated:', p);
    });

    this.user$.subscribe(p => {
      this.logInternal(`AuthService - user$ - user:`, p);
    });

    this.isAuthenticated$.subscribe(p => {
      this.logInternal(`AuthService - isAuthenticated$ - isAuthenticated:`, p);
    });

    this.isPartiallyAuthenticated$.subscribe(p => {
      this.logInternal(`AuthService - isPartiallyAuthenticated$ - isPartiallyAuthenticated:`, p);
    });

    this.isLoggedin$.subscribe(p => {
      this.logInternal(`AuthService - isLoggedin$ - isLoggedin:`, p);
    });

    this.loggedInUser$.subscribe(p => {
      this.logInternal(`AuthService - userLoggedIn$ - userLoggedIn:`, p);

      if (p) {
        if (p.persoon) {
          const persoon = p.persoon;
          this.clientLoggingService.logMessage(
            ClientLogLevel.Information,
            `Loggedin - persoon - ${persoon.naam} - ${persoon.email} - ${persoon.reference} - ${persoon.id}`);
        }
        else if (p.onderneming) {
          const onderneming = p.onderneming;
          this.clientLoggingService.logMessage(
            ClientLogLevel.Information,
            `Loggedin - onderneming - ${onderneming.naam} - ${onderneming.email} - ${onderneming.reference} - ${onderneming.id}`);
        }
      }
    });

    this.oauthService.events.subscribe(event => {

      this.logInternal(`AuthService - oauthService on event - ${formatObject(event)}`);

      this.logInternal(`AuthService - oauthService on event - Localstorage: ${getLocalStorageAsStr()}`);

      if (event instanceof OAuthSuccessEvent) {
        const successEvent = event as OAuthSuccessEvent;
        if (successEvent.type === 'token_received') {
          if (this.isTokenExchangeEnabled) {
            this.doTokenExchange();
          }
        }
      }

      if (event instanceof OAuthErrorEvent) {
        console.error('AuthService - oauthService event - OAuthErrorEvent', event);

        // on auth error event we do a client logging with our authsvc logbuffer
        this.logClientWarningError(`AuthService - oauthService event - OAuthErrorEvent`, event);
      }

      this.pollValidToken();
    });

    this.pollValidToken();
    // this.isAuthenticatedSubject$.next(this.hasValidAvgpvAccessToken());

    this.pollingIntervalValidTokenRegistration = setInterval(() => this.pollValidToken(), this.pollingIntervalValidTokenMs);
  }

  public get isTokenExchangeEnabled(): boolean {
    const issuer = this.authStorage.getItem('oauth_issuer');
    const rv = issuer === this.environment.acmIdm.issuer && this.environment.acmIdm.enableTokenExchange;
    return rv;
  }

  public get token(): string {
    const oauthIssuer = this.authStorage.getItem('oauth_issuer');
    let authorizationHeaderValue: string;

    if (oauthIssuer === this.environment.acmIdm.issuer) {
      if (this.environment.acmIdm.enableIdTokenAsAccessToken) {
        authorizationHeaderValue = this.authStorage.getItem('id_token');
      }
      else if (this.environment.acmIdm.enableTokenExchange) {
        authorizationHeaderValue = this.authStorage.getItem('avgpv_access_token');
      }
    }
    else {
      authorizationHeaderValue = this.authStorage.getItem('access_token');
    }
    return authorizationHeaderValue;
  }

  private logBuffer = [];

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private isAuthenticatedDebounce$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged(), debounceTime(750));
  private isAuthenticatedNoDebounce$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());

  // partially authenticated => when first access token is valid (not necessarily in case of token exchange that token exchange succeeded)
  // partially authenticated is true when this.oauthService.hasValidAccessToken();
  // when partially authenticated true, also means it is possible you could do a logout
  private isPartiallyAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private isPartiallyAuthenticatedDebounce$ = this.isPartiallyAuthenticatedSubject$.asObservable()
    .pipe(distinctUntilChanged(), debounceTime(750));
  private isPartiallyAuthenticatedNoDebounce$ = this.isPartiallyAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(undefined);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable().pipe(distinctUntilChanged(), debounceTime(250));

  private user: User = null;
  // returns user on each change. When receiving a user object, it does not mean the user is logged in
  // user$ should NOT be used by normal components, they should prefer loggedInUser$
  private userSubject$ = new BehaviorSubject<User>(null);
  private user$ = this.userSubject$.asObservable();

  private baseHref: string;

  private tokenExchangeIsRunningTask: Promise<void> = Promise.resolve();

  public returnRoute: string;

  private debugLoggingEnabled = devfeatures.enableAuthLogging;
  private pollingIntervalValidTokenMs = 10000;
  private pollingIntervalValidTokenRegistration: any;
  private authenticatedEventCount = 0;

  public isAuthenticated$: Observable<boolean> = combineLatest([
    this.isAuthenticatedDebounce$,
    this.isDoneLoading$
  ]).pipe(
    filter(([_, isDoneLoading]) => isDoneLoading),
    map(([isAuthenticated, _]) => isAuthenticated),
    distinctUntilChanged()
  );

  public isPartiallyAuthenticated$: Observable<boolean> = combineLatest([
    this.isPartiallyAuthenticatedDebounce$,
    this.isDoneLoading$
  ]).pipe(
    filter(([_, isDoneLoading]) => isDoneLoading),
    map(([isAuthenticated, _]) => isAuthenticated),
    distinctUntilChanged()
  );

  public isLoggedin$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
    this.user$
  ]).pipe(
    filter(([_, isDoneLoading, user]) => isDoneLoading),
    map(([isAuthenticated, _, user]) => isAuthenticated && user && user.exists === true),
    distinctUntilChanged()
  );

  // observable returns the user object if logged in , otherwise a null is returned
  public loggedInUser$: Observable<User> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
    this.user$
  ]).pipe(
    filter(([_, isDoneLoading, user]) => isDoneLoading),
    map(([isAuthenticated, _, user]) => {
      if (isAuthenticated && user && user.exists === true) {
        return user;
      }
      else {
        return null;
      }
    })
  );

  private publishUser(): void {
    this.userSubject$.next(this.user);
  }

  private pollValidToken(): void {
    const hasValidFinalToken = !!this.hasValidAvgpvAccessToken();
    const hasValidFirstToken = !!this.hasValidAccessToken();

    if (this.debugLoggingEnabled) {
      console.log('AuthService - pollValidToken() - hasValidFirstToken:', hasValidFirstToken, 'hasValidFinalToken:', hasValidFinalToken);
    }

    this.isAuthenticatedSubject$.next(hasValidFinalToken);
    this.isPartiallyAuthenticatedSubject$.next(hasValidFirstToken);

  }

  private pollValidTokenCheckingAgain(): void {
    const hasValidFinalToken = !!this.hasValidAvgpvAccessToken();
    const hasValidFirstToken = !!this.hasValidAccessToken();

    if (this.debugLoggingEnabled) {
      console.log('AuthService - pollValidTokenCheckingAgain() - hasValidFirstToken:', hasValidFirstToken,
        'hasValidFinalToken:', hasValidFinalToken);
    }

    this.isAuthenticatedSubject$.next(hasValidFinalToken);
    this.isPartiallyAuthenticatedSubject$.next(hasValidFirstToken);
  }

  // function only checks if the oauthservice has a valid access token
  // in the case of the acmidm token exchange, a valid access token
  // just means that the first round resulted in a valid access token
  // This does not mean that the token exchange resulted in a valid access token
  // Function hasValidAvgpvAccessToken() also takes the tokenexchange if relevant into account
  public hasValidAccessToken(): boolean {
    const result = this.oauthService.hasValidAccessToken();
    return result;
  }

  public hasValidAvgpvAccessToken(): boolean {
    if (this.oauthService.hasValidAccessToken()) {
      const issuer = this.authStorage.getItem('oauth_issuer');

      // if acmidm && tokenexchange is enabled
      if (issuer === this.environment.acmIdm.issuer && this.environment.acmIdm.enableTokenExchange) {
        const accessToken = this.authStorage.getItem('avgpv_access_token');
        const expiresEpoch = parseInt(this.authStorage.getItem('avgpv_expires_in_epoch'), 10);
        const nowEpoch = Math.floor(new Date().valueOf() / 1000);
        const linkedClientAccessToken = this.authStorage.getItem('avgpv_access_token_client');
        const currentClientAccessToken = this.oauthService.getAccessToken();

        const validAccessToken = !!(accessToken && expiresEpoch > nowEpoch && linkedClientAccessToken === currentClientAccessToken);
        return validAccessToken;
      }
      // if acmidm & identitytokenasaccesstoken is enabled
      else if (issuer === this.environment.acmIdm.issuer &&
        this.environment.acmIdm.enableIdTokenAsAccessToken &&
        this.oauthService.hasValidIdToken()) {
        const validToken = !!this.oauthService.getIdToken();
        return validToken;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }

  // access userinfo endpoint + getLoggedOnUser (avgpv)
  private async tryLoadUserProfile(): Promise<boolean> {
    try {
      try {
        this.logInternal('AuthService - tryLoadUserProfile()');

        if (this.hasValidAvgpvAccessToken()) {

          this.logInternal('AuthService - tryLoadUserProfile: hasValidAvgpvAccessToken():true');

          let oauthUserInfo = JSON.parse(this.authStorage.getItem('id_token_claims_obj'));

          this.logInternal('AuthService - oauthService.loadUserProfile()', oauthUserInfo);

          const apiUserInfo = await this.getLoggedOnUser();

          this.logInternal('AuthService - getLoggedOnUser()', apiUserInfo);

          this.user = new User(oauthUserInfo, oauthUserInfo.iss === this.environment.acmIdm.issuer);
          this.user.exists = apiUserInfo.exists;
          this.user.onderneming = apiUserInfo.user?.onderneming;
          this.user.persoon = apiUserInfo.user?.persoon;
          this.user.requireUserTypeForCreation = apiUserInfo.requireUserTypeForCreation;

          if (!this.user.exists && !this.user.requireUserTypeForCreation) {

            this.logInternal('AuthService - createLoggedOnUser(null)');
            // since requireUserTypeForCreation is false we do not need to pass the usertype, so passing null
            await this.createLoggedOnUser(null);

            // if (user.vo_doelgroepcode === DoelgroepCodeEnum.BUR) {
            //   await this.createLoggedOnUser(UserTypeEnum.PERSOON);
            // }
            // else if (user.vo_doelgroepcode === DoelgroepCodeEnum.EA
            //   || user.vo_doelgroepcode === DoelgroepCodeEnum.LB
            //   || user.vo_doelgroepcode === DoelgroepCodeEnum.OV) {
            //   await this.createLoggedOnUser(UserTypeEnum.ONDERNEMING);
            // }
          }
          else if (!this.user.exists && this.user.requireUserTypeForCreation) {
            this.logInternal('AuthService - router.navigateByUrl(\'/hoedanigheid\')');
            this.router.navigateByUrl('/hoedanigheid');
            return false;
          }
          return true;
        }
        else {
          this.logInternal('AuthService - tryLoadUserProfile: hasValidAvgpvAccessToken():false');
        }
        return false;
      }
      finally {
        this.publishUser();
        this.logInternal('AuthService - tryLoadUserProfile() end');
      }
    }
    catch (error) {
      error = this.ErrorAddAuthSvcTagAndScope(error, 'tryLoadUserProfile()');
      throw error;
    }

  }

  private ErrorAddAuthSvcTagAndScope(error: any, scope: string): any {
    if (typeof error === 'string')
    {
      // if the error is a string we transform it to an object with property message that will contain the message
      error = { message: error};
    }

    errorTagAuthenticationSvc(error);
    errorAddAvgpvScope(error, scope);
    return error;
  }

  public async getLoggedOnUser(): Promise<LoggedOnUser> {
    try {
      const user = await this.httpClient.get<LoggedOnUser>
        (`${this.environment.api.baseUri}/users/getloggedonuser`).toPromise();

      return user;
    }
    catch (error) {
      error = this.ErrorAddAuthSvcTagAndScope(error, 'getLoggedOnUser()');
      throw error;
    }

  }

  public async triggerTokenExchangeIfAcmIdmAndEnabledAndNoValidAvgpvAccessToken(): Promise<void> {

    this.logInternal('AuthService - triggerTokenExchangeIfAcmIdmAndEnabledAndNoValidAvgpvAccessToken()');

    const issuer = this.authStorage.getItem('oauth_issuer');
    if (issuer === this.environment.acmIdm.issuer
      && this.hasValidAccessToken()  // check required, because if false then no use of starting token exchange
      && this.environment.acmIdm.enableTokenExchange
      && !this.hasValidAvgpvAccessToken()) {

      this.logInternal('AuthService - triggerTokenExchangeIfAcmIdmAndEnabledAndNoValidAvgpvAccessToken - triggering doTokenExchange()');
      await this.doTokenExchange();

    }

    this.logInternal('AuthService - triggerTokenExchangeIfAcmIdmAndEnabledAndNoValidAvgpvAccessToken() end');
  }

  // internally call the doTokenExchangeCore and also some extra application sideeffects
  // creates a task (promise) and triggers some notification
  // on end of tokenexchange
  public doTokenExchange(): Promise<void> {
    this.tokenExchangeIsRunningTask = new Promise((accept, reject) => {
      this.logInternal('AuthService - doTokenExchange()');
      this.doTokenExchangeCore().finally(() => {
        this.logInternal('AuthService - doTokenExchange() end');
        accept();
        this.pollValidToken();
        // this.isAuthenticatedSubject$.next(this.hasValidAvgpvAccessToken());
      });
    });
    return this.tokenExchangeIsRunningTask;
  }

  // call to token endpoint and set some values in AuthStorage
  // this is the core functionality of token exchange
  private async doTokenExchangeCore(): Promise<TokenExchangeResponse> {
    this.logInternal('AuthService - doTokenExchangeCore()');
    try {
      if (this.oauthService.hasValidAccessToken()) {
        const accessToken = this.oauthService.getAccessToken();
        const clientid = this.environment.acmIdm.clientId;
        const clientsecret = this.environment.acmIdm.clientSecret;
        const avgpvClientId = this.environment.acmIdm.avgpvClientId;
        const issuer = this.environment.acmIdm.issuer;
        const tokenExchangeEndpoint = `${issuer}/v1/token`;

        if (!avgpvClientId) {
          throw new Error('avgpvClientId is niet geconfigureerd');
        }

        const body = new HttpParams()
          .set('client_id', clientid)
          .set('client_secret', clientsecret)
          .set('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange')
          .set('subject_token_type', 'urn:ietf:params:oauth:token-type:access_token')
          .set('audience', avgpvClientId)
          .set('subject_token', accessToken);

        const bodyString = body.toString();

        let tokenResponse = null;
        try {
          this.logInternal('calling token endpoint', tokenExchangeEndpoint, 'body', bodyString);

          tokenResponse = await this.httpClient.post<TokenExchangeResponse>(
            tokenExchangeEndpoint,
            bodyString,
            {
              headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            }).toPromise();

          this.logInternal('calling token endpoint finished', 'tokenResponse', tokenResponse);

        }
        catch (error) {
          error = this.ErrorAddAuthSvcTagAndScope(error, 'doTokenExchangeCore: error occurred when calling the token exchange endpoint');
          throw error;
        }

        this.authStorage.setItem('avgpv_access_token', tokenResponse.access_token);
        this.authStorage.setItem('avgpv_expires_in', tokenResponse.expires_in.toString());
        this.authStorage.setItem('avgpv_access_token_response', formatObject(tokenResponse));

        // expires in epoch marge van 0.75 naar 0.95 veranderd
        // reden: om meer marge te geven aan het auto refresh van token
        // nu wordt avgpv_expires_in gebruikt om te checken of ons token geldig is. Door een marge te nemen zal de app sneller
        // vinden dat het token niet geldig meer is (terwijl in realiteit er nog die marge is).
        // Door de marge te verkleinen zal de applicatie langer het token als geldig beschouwen en heeft de autorefresh iets meer marge
        // want in documentatie wordt ook 0.75 vermeld als trigger wanneer auto refresh van token gebeurt.
        this.authStorage.setItem('avgpv_expires_in_epoch',
          Math.floor(((new Date().valueOf() / 1000) + tokenResponse.expires_in)).toString());
        this.authStorage.setItem('avgpv_access_token_client', accessToken);
        this.setInStorage('avgpv_tokenexchange_info', `${new Date().toISOString()} - appid - ${getApplicationInstanceId()}`);

        return tokenResponse;
      }
      else {
        this.logInternal('doTokenExchangeCore() called but this.oauthService.hasValidAccessToken() returned false');
      }
    }
    finally {
      this.logInternal('AuthService - doTokenExchangeCore() end');
      this.logInternal(`AuthService - doTokenExchangeCore() end - Localstorage: ${getLocalStorageAsStr()}`);
    }

  }

  private setInStorage(key: string, value: any): void {
    this.authStorage.setItem(key, value);
  }

  // Function reworked (see below)
  // Keep comment for possible rework if not stable
  // public runInitialLoginSequence(issuer: string, returnUrl?: string): void {
  //     let config: AuthConfig;
  //     if (issuer === 'itb') {
  //         config = itbConfig(this.baseHref, this.environment);
  //     } else if (issuer === 'acmidm') {
  //         config = ACMConfig(this.baseHref, this.environment);
  //     } else {
  //         return;
  //     }

  //     this.authStorage.setItem('oauth_issuer', config.issuer);
  //     this.oauthService.configure(config);
  //     this.oauthService.loadDiscoveryDocumentAndLogin({ state: returnUrl})
  //       .then( () => this.tokenExchangeIsRunningTask)
  //       .then( () => {
  //           // keep comment: easy to enable for debugging
  //           // console.log('loadDiscoveryDocumentAndLogin finished', 'hasValidAccessToken', this.oauthService.hasValidAccessToken());
  //         })
  //       .then(() => this.tryLoadUserProfile())
  //       .then(() => this.oauthService.setupAutomaticSilentRefresh())
  //       .finally(() => {
  //           this.isAuthenticatedSubject$.next(this.hasValidAccessToken());

  //           //keep comment
  //           //this.isDoneLoadingSubject$.next(true);
  //           if (this.oauthService.state || returnUrl) {
  //               this.returnRoute = decodeURIComponent(this.oauthService.state || returnUrl || '');
  //               let route = this.returnRoute;

  //               if (issuer === 'itb') {
  //                   route = 'hoedanigheid';
  //               }

  //               this.router.navigate([route]);
  //           }
  //         })
  //       .catch(error => { throw error; });
  // }

  public async waitUntilTokenExchangeIsNotRunning(): Promise<void> {
    this.logInternal('AuthService - waitUntilTokenExchangeIsNotRunning()');
    if (this.tokenExchangeIsRunningTask) {
      this.logInternal('AuthService - waitUntilTokenExchangeIsNotRunning() - waiting...');
      await this.tokenExchangeIsRunningTask;
      this.logInternal('AuthService - waitUntilTokenExchangeIsNotRunning() - end waiting');
    }
    else {
      // return Promise.resolve();
    }
    this.logInternal('AuthService - waitUntilTokenExchangeIsNotRunning() - end');
  }

  public async acmIdmGerichteLogin(loginHint: any, returnUrl?: string): Promise<void> {

    this.logInternal('AuthService - acmIdmGerichteLogin()', 'loginHint', loginHint, 'returnUrl', returnUrl);

    const state = returnUrl ? returnUrl : '/';

    const config = ACMConfig(this.baseHref, this.environment);

    this.oauthService.configure(config);

    this.logInternal('AuthService - oauthService.logOut(true)');

    this.oauthService.logOut(true); // clean up tokens (no redirect)

    await this.oauthService.loadDiscoveryDocument();

    this.oauthService.initLoginFlow(state, { login_hint: loginHint });
  }

  public async runLoginSequence(issuerCode: string, returnUrl?: string, loginHint: string = null): Promise<void> {
    try {
      try {
        this.logInternal('AuthService - runLoginSequence()', issuerCode, returnUrl, loginHint);

        this.isDoneLoadingSubject$.next(false);

        const isGerichtAanmelden = loginHint !== null;

        let config: AuthConfig;
        if (issuerCode === 'itb') {
          config = itbConfig(this.baseHref, this.environment);
        } else if (issuerCode === 'acmidm') {
          config = ACMConfig(this.baseHref, this.environment);
        } else {
          return;
        }

        this.authStorage.setItem('oauth_issuer', config.issuer);

        this.oauthService.configure(config);

        this.logInternal('AuthService - oauthService.loadDiscoveryDocumentAndLogin()', 'state', returnUrl);

        const loadDiscoveryDocAndLoginResult =
          await this.oauthService.loadDiscoveryDocumentAndLogin(
            { state: returnUrl });

        this.logInternal('AuthService - oauthService.loadDiscoveryDocumentAndLogin() end',
          'loadDiscoveryDocAndLoginResult', loadDiscoveryDocAndLoginResult);

        if (loadDiscoveryDocAndLoginResult) {
          await this.waitUntilTokenExchangeIsNotRunning();

          // it is theoretically possible that loadDiscoveryDocAndLoginResult is true (valid first access token)
          // but the avgpv access token is not valid. So we do here a check if need to do token exchange
          await this.triggerTokenExchangeIfAcmIdmAndEnabledAndNoValidAvgpvAccessToken();

          this.logInternal(
            'AuthService - after doTokenExchangeIfAcmIdmAndEnabledAndNoValidAvgpvAccessToken() - oauthService.hasValidAccessToken',
            this.oauthService.hasValidAccessToken(), 'hasValidAvgpvAccessToken()', this.hasValidAvgpvAccessToken());

          if (!this.hasValidAvgpvAccessToken) {
            throw new Error('Geen geldig accesstoken. Login gebruiker gestopt');
          }

          const loadProfileSuccess = await this.tryLoadUserProfile();

          this.oauthService.setupAutomaticSilentRefresh();

          // keep for easy debugging simulation of error
          // throw new Error('Debug error for testing, needs to be removed')

          // only if loadProfile succeeded will we return to returnUrl
          if (loadProfileSuccess) {
            if (this.oauthService.state || returnUrl) {

              this.returnRoute = decodeURIComponent(this.oauthService.state || returnUrl || '');

              const route = this.returnRoute;

              // if (issuerCode === 'itb') {
              //   route = 'hoedanigheid';
              // }

              this.router.navigate([route]);
            }
          }
        }
      }
      catch (error) {
        const argumentsFormattedStr = formatObject({ issuerCode, returnUrl, loginHint });
        error = this.ErrorAddAuthSvcTagAndScope(error, `runLoginSequence().\r\nArguments: ${argumentsFormattedStr}`);
        this.logInternal('AuthService', 'runLoginSequence() error', formatError(error));
        this.logInternal(`AuthService - runLoginSequence() error - Localstorage: ${getLocalStorageAsStr()}`);
        throw error;
      }
    }
    finally {
      this.logInternal('AuthService - runLoginSequence() setting isDoneLoadingSubject to true', issuerCode, returnUrl, loginHint);
      this.isDoneLoadingSubject$.next(true);
      this.pollValidToken();
      // this.isAuthenticatedSubject$.next(this.hasValidAvgpvAccessToken());
      this.logInternal('AuthService - runLoginSequence() end', issuerCode, returnUrl, loginHint);
    }
  }

  public loadDiscoveryAndLogin(returnUrl): Promise<boolean> {
    return this.oauthService.loadDiscoveryDocumentAndLogin({ state: returnUrl });
  }

  public logout(): void {
    this.logInternal('AuthService - logout() - calling oauthService.logOut(true)');
    this.clientLoggingService.logMessage(ClientLogLevel.Information, 'Logout'); // async call !!!
    this.oauthService.logOut();
  }

  // called from AppComponent
  public tryAutoLogin(): void {
    this.logInternal('AuthService - tryAutoLogin()');

    const url = window.location.href;
    const isCallbackUrl = (url?.toLowerCase().indexOf('/callback/') >= 0);
    const isLoginAcmIdm = (url?.toLowerCase().indexOf('/loginacmidm/') >= 0);

    if (this.hasValidAvgpvAccessToken()) {
      // check it is not a callback call

      if (isCallbackUrl || isLoginAcmIdm) {
        // lets skip here to avoid having two runLoginSequence() functions being started at the same time (async)
        // there are two startpoints for calling runLoginSequence():
        // 1) tryAutoLogin() (called from app.component)
        // 2) callback.component

        // TODO: log the fact that we are skipping tryAutoLogin();
        this.logInternal('AuthService - tryAutoLogin() - tryAutoLogin() skipped. Valid access token and isCallbackUrl');
        this.clientLoggingService.logMessage(ClientLogLevel.Warning,
          'tryAutoLogin() skipped. Valid access token and isCallbackUrl'); // async call !!!
        return;
      }

      try {
        // we store the issuer, so no need to decode token
        // but keep the commented code, can be usefull later
        // const issuer = jwt_decode(this.oauthService.getIdToken()).iss;
        const issuer = this.authStorage.getItem('oauth_issuer');
        if (issuer) {

          if (issuer === this.environment.acmIdm.issuer) {
            this.runLoginSequence('acmidm');
            return;
          }
          if (issuer === this.environment.itb.issuer) {
            this.runLoginSequence('itb');
            return;
          }
        }
      }
      catch (e) {
        console.error('Automatisch aanloggen is gefaald', e);
      }
    }
    else {
      this.logInternal('AuthService - Geen ValidAvgpvAccessToken token, stoppen van autologin');
      this.isDoneLoadingSubject$.next(true);
    }
  }

  public loginItb(returnUrl = '/'): void {
    this.logInternal('AuthService - loginItb()', 'returnUrl', returnUrl);
    this.clientLoggingService.logMessage(ClientLogLevel.Information, 'Login magiclink');
    this.runLoginSequence('itb', returnUrl);
  }

  public loginACM(returnUrl = '/'): void {
    this.logInternal('AuthService - loginACM()', 'returnUrl', returnUrl);
    this.clientLoggingService.logMessage(ClientLogLevel.Information, 'Login acmidm');
    this.runLoginSequence('acmidm', returnUrl);
  }

  // SUGGESTION: the following functions should be put in a seperate service
  // this should have been put in a user service
  // but now the functions are linked to the logged in user !!!!

  // INFO: function called from hoedanigheid component
  public async createLoggedOnUser(userType: UserTypeEnum):
    Promise<void> {

    this.logInternal('AuthService - createLoggedOnUser()', 'userType', userType);

    if (devfeatures.triggerErrorOnCreateLoggedOnUser) {
      throw new Error('CreateLoggedOnUser() returned an error (for debug purposes)');
    }

    const { userInfo } = await this.httpClient.post
      <{ userInfo: { onderneming?: Onderneming; persoon?: Persoon; id: string; reference: string } }>
      (`${this.environment.api.baseUri}/users/createloggedonuser`, { userType }).toPromise();

    this.user.exists = true;

    this.user.persoon = userInfo.persoon;
    this.user.onderneming = userInfo.onderneming;

    this.publishUser();
  }

  // update properties of the logged in profile
  public async saveLoggedInPersoon(persoon: Persoon): Promise<void> {

    // check we update correct persoon
    if (persoon.id && this.user.persoon.id && persoon.id !== this.user.persoon.id) {
      throw new Error('Niet toegelaten om een ander persoon profiel te saven');
    }

    // save Persoon
    const { id, reference } = await this.httpClient.post<IdReference>
      (`${this.environment.api.baseUri}/users/personen`, persoon).toPromise();

    // reload persoon
    await this.reloadLoggedInPersoon();
  }

  public async saveLoggedInOnderneming(onderneming: Onderneming): Promise<void> {

    // check we update correct onderneming
    if (onderneming.id && this.user.onderneming.id && onderneming.id !== this.user.onderneming.id) {
      throw new Error('Niet toegelaten om een ander ondernming profiel te saven');
    }

    // save Persoon
    const { id, reference } = await this.httpClient.post<IdReference>
      (`${this.environment.api.baseUri}/users/ondernemingen`, onderneming).toPromise();

    await this.reloadLoggedInOnderneming();
  }

  // Reload the userinfo from backend for the loggedin user
  // changed public async getPersoon(persoon: Persoon)
  // into public async reloadPersoon()
  // omdat dit toeliet om een andere persoon als logged in te maken via de parameters
  // ook rename voor meer duidelijkheid
  public async reloadLoggedInPersoon(): Promise<User> {

    if (this.user) {
      this.user.persoon = await this.usersApi.getPersoon(this.user.persoon.id, this.user.persoon.reference).toPromise();
      this.publishUser();
    }

    return this.user;
  }

  public async reloadLoggedInOnderneming(): Promise<User> {

    if (this.user) {
      this.user.onderneming = await this.usersApi.getOnderneming(this.user.onderneming.id, this.user.onderneming.reference).toPromise();
      this.publishUser();
    }
    return this.user;
  }

  public syncMagdaLoggedInPersoon(): Observable<FileResponse> {
    const parameters: ISynchronizeMagdaPersoonParameters = {
      persoon: new UserSelector({ id: this.user.persoon.id, reference: this.user.persoon.reference })
    };

    return this.usersApi.synchronizeMagdaPersoon(new SynchronizeMagdaPersoonParameters(parameters));
  }

  public syncMagdaLoggedInOnderneming(): Observable<FileResponse> {
    const parameters: ISynchronizeMagdaOndernemingParameters = {
      onderneming: new UserSelector({ id: this.user.onderneming.id, reference: this.user.onderneming.reference })
    };

    return this.usersApi.synchronizeMagdaOnderneming(new SynchronizeMagdaOndernemingParameters(parameters));
  }

  private async logClientCore(logLevel: ClientLogLevel, message: string, properties?: { [key: string]: any }): Promise<void> {
    await this.clientLoggingService.logMessage(
      logLevel,
      message,
      properties
    );
  }

  public getLogAsStr(): string {
    return this.logBuffer.join('\r\n');
  }

  private async logClientIncludeContext(logLevel: ClientLogLevel, message: string, properties?: { [key: string]: any }): Promise<void> {
    const propertiesMerged = {
      authsvclog: this.logBuffer.join('\r\n'),
      localstorage: getLocalStorageAsStr(),
      ...properties
    };

    await this.logClientCore(logLevel, message, propertiesMerged);
  }

  private async logClientError(message: string, error: any): Promise<void> {

    await this.logClientIncludeContext(ClientLogLevel.Error, message, { error: formatError(error) });

  }

  private async logClientWarningError(message: string, error: any): Promise<void> {

    await this.logClientIncludeContext(ClientLogLevel.Warning, message, { error: formatError(error) });

  }

  public logInternal(message: string, ...args): void {
    const nowStr = new Date().toISOString();
    let fullmessage = `${nowStr} - ${message}`;
    if (args) {
      for (let i = 0, len = args.length; i < len; ++i) {
        const argsitem = args[i];
        if (typeof argsitem === 'string') {
          fullmessage += ' - ' + argsitem;
        }
        else {
          fullmessage += ' - ' + formatObject(argsitem);
        }
      }
    }
    this.logBuffer.push(fullmessage);

    if (this.debugLoggingEnabled) {
      console.log(message, ...args, '- ' + nowStr);
    }
  }

}
