<article class="detail-layout">
    <ng-container *ngIf="params.guard">
        <h1 i18n>Meld je aan om verder te gaan</h1>
    </ng-container>

    <h1 i18n *ngIf="!params.guard">Aanmelden via de uitzonderingsprocedure</h1>

    <div *ngIf="isAuthenticated$ | async; else login" class="messages messages--warning">

      <div *ngIf="isLoggedin$ | async">
        <i class="icon-exclamation" aria-hidden="true"></i>
        <p i18n>Je bent aangemeld.<br>
        Wil je aanmelden onder een andere hoedanigheid? Meld je eerst af.</p>
      </div>

      <div *ngIf="!(isLoggedin$ | async)">
        <i class="icon-exclamation" aria-hidden="true"></i>
        <p i18n>Authenticatie is gelukt maar je profiel is niet geladen.<br>
        Probeer de web pagina te herladen of probeer eerst eens af te melden en opnieuw in te loggen</p>
      </div>

    </div>

    <ng-template #login>
        <div>
            <p i18n>Ben je een buitenlandse onderneming of heb je geen Belgische e-ID?
                Dan kan je via deze weg aanmelden op het e-loket voor alle mobiiteitsvergunningen.
            </p>
            <p>
                <a [routerLink]="[]" (click)="loginSimple()"
                    class="standalone-link" i18n>Uitzonderingsprocedure</a>
            </p>
            <p i18n>
                Stad Gent hecht veel belang aan een goede dienstverlening. Heb je bijkomende vragen, staat GentInfo voor
                je klaar.
            </p>
        </div>
    </ng-template>
</article>
