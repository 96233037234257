import { ClientLoggingService } from './../../../core/services/client-logging.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ClientLogLevel } from 'src/app/models/log-entry';

@Component({
    selector: 'avgpv-pub-callback',
    templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {

    constructor(private authService: AuthService, private route: ActivatedRoute, private clientLoggingService: ClientLoggingService) {

    }

    ngOnInit(): void {
        this.route.paramMap
            .pipe(map(params => params.get('issuer')))
            .subscribe(issuer => {
              this.clientLoggingService.logMessage(ClientLogLevel.Information, 'Callback received');
              this.authService.logInternal('AuthService', 'Callback received');
              if (this.authService.hasValidAccessToken())
              {
                this.authService.logInternal('AuthService', 'Already valid access token. On callback, we do not expect this');
                this.clientLoggingService.logMessage(ClientLogLevel.Warning, 'Already valid access token. On callback, we do not expect this');
              }
              this.authService.logInternal('AuthService', 'calling runLoginSequence', 'issuer', issuer);
              this.authService.runLoginSequence(issuer);
            })
            .unsubscribe();
    }

    public logout(): void {
        this.authService.logout();
    }

}
