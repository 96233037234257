/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { formatError, formatObject } from './formatting';

export function getLocalStorageAsStr(): string {

  try {
    const localStorageObj = {};

    for (let i = 0, len = localStorage.length; i < len; ++i) {
      const key = localStorage.key(i);
      localStorageObj[key] = localStorage.getItem(key);

    }

    // const state = {
    //   avgpv_access_token: this.authStorage.getItem('avgpv_access_token'),
    //   avgpv_expires_in_epoch: this.authStorage.getItem('avgpv_expires_in_epoch'),
    //   avgpv_access_token_client: this.authStorage.getItem('avgpv_access_token_client'),
    //   currentClientAccessToke: this.oauthService.getAccessToken()
    // };

    const localStorageObjStr = formatObject(localStorageObj);
    return localStorageObjStr;
  }
  catch (e) {
    return `error getting local storage as string. Error: ${formatError(e)}`;
  }

}
