import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user';

@Component({
    selector: 'avgpv-pub-portaal',
    templateUrl: './portaal.component.html'
})
export class PortaalComponent implements OnInit {
    public user: User;

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    public ngOnInit(): void {
        this.authService.loggedInUser$.subscribe(user => {
            this.user = user;
        });
    }

    public routeProfile(): void {
        this.router.navigate(['profiel'], { queryParams: { return: this.router.url } });
    }
}
