import { ClientLogLevel } from './../../models/log-entry';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';
import { ClientLogEntry } from 'src/app/models/log-entry';

@Injectable({
  providedIn: 'root'
})
export class ClientLoggingService {

  constructor(private httpClient: HttpClient, private environment: EnvironmentService) {
  }

  public async logEntry(entry: ClientLogEntry): Promise<void> {
    await this.httpClient.post(`${this.environment.api.baseUri}/clientlogging`, entry)
      .pipe(catchError(() => of())).toPromise();
  }

  public async logMessage(logLevel: ClientLogLevel, message: string, properties?: {[key: string]: any}): Promise<void>
  {
    const entry = this.createClientLogEntry();
    entry.message = message;
    entry.level = logLevel;

    if (properties)
    {
      entry.mergeProperties(properties);
    }

    await this.logEntry(entry);
  }

  public createClientLogEntry(): ClientLogEntry {
    const newEntry = new ClientLogEntry();
    newEntry.application = this.environment.itb.clientId;
    newEntry.properties.url = window.location.href;
    newEntry.timeStampUtc = new Date().toISOString();
    newEntry.level = ClientLogLevel.Information;
    return newEntry;
  }
}
