import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    templateUrl: './cookiebeleid.component.html'
})
export class CookiebeleidComponent implements OnInit {
    @ViewChild('cookieLijst', { static: true }) cookieLijst: ElementRef;

    constructor(
        private renderer2: Renderer2
    ) { }

    public ngOnInit(): void {
        const script = this.renderer2.createElement('script');
        script.type = 'text/javascript';
        script.text = ``;
        script.src = `https://consent.cookiebot.com/2704adde-8fb9-41f8-a6ac-acfcca9045d9/cd.js`;

        this.renderer2.appendChild(this.cookieLijst.nativeElement, script);
    }
}
