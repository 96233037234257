<section class="footer">
    <h2 class="visually-hidden">Footer</h2>
    <div class="footer-top">
        <div class="content-container">
            <div class="footer--column">
                <div class="gentinfo-block">
                    <div class="gentinfo-block-content">
                        <h2 i18n>Contacteer het Mobiliteitsbedrijf</h2>
                        <p i18n="GentInfo">Bereikbaar van maandag tot en met vrijdag van 9u tot 12u30.</p>
                        <ul class="icon-list ">
                            <li>
                                <i class="icon-phone" aria-hidden="true"></i>
                                <a href="tel:+3292662800">09 266 28 00</a>
                            </li>
                            <li>
                                <i class="icon-envelope" aria-hidden="true"></i>
                                <a href="mailto:mobiliteit@stad.gent">mobiliteit&#64;stad.gent</a>
                            </li>
                            <li>
                                <i class="icon-document" aria-hidden="true"></i>
                                <a href="https://stad.gent/contact" i18n>Contactformulier</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer--column">
                <h3 i18n>Nuttige links</h3>
                <ul>
                    <li>
                        <a href="https://jobs.gent.be/" i18n>Vacatures</a>
                    </li>
                    <li>
                        <a href="https://stad.gent/over-gent-en-het-stadsbestuur/over-gent/kaarten-cijfers-en-data/gent-kaart/stadsplan"
                           i18n>Stadsplan</a>
                    </li>
                    <li>
                        <a href="https://persruimte.stad.gent/nl" i18n>Persruimte Stad Gent</a>
                    </li>
                </ul>
            </div>
            <div class="footer--column">
                <h3 i18n>Volg de Stad Gent op sociale media</h3>
                <div class="social-list">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/stadsbestuurgent">
                                <i aria-hidden="true" class="icon-facebook"></i>
                                <span class="visually-hidden">Facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/stadgent">
                                <i aria-hidden="true" class="icon-twitter"></i>
                                <span class="visually-hidden">Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/stad-gent">
                                <i aria-hidden="true" class="icon-linkedin"></i>
                                <span class="visually-hidden">LinkedIn</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCmJLf8SFG0D0GTd6LSSTQLw">
                                <i aria-hidden="true" class="icon-youtube"></i>
                                <span class="visually-hidden">Youtube</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/stadgent/">
                                <i aria-hidden="true" class="icon-instagram"></i>
                                <span class="visually-hidden">Instagram</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom content-container">
        <div class="footer--column">
            <ul>
                <li>
                    <a href="https://stad.gent/nl/over-gent-en-het-stadsbestuur/juridische-info/gebruiksvoorwaarden-website-stad-gent" i18n>Gebruiksvoorwaarden</a>
                </li>
                <li>
                    <a href="https://stad.gent/nl/over-gent-en-het-stadsbestuur/juridische-info/met-respect-voor-uw-privacy" i18n>Privacy</a>
                </li>
                <li>
                    <a href="https://stad.gent/nl/responsible-disclosure" i18n>Responsible disclosure</a>
                </li>
                <li>
                    <!-- todo: VPEA-106 toegankelijkheidsverklaring -->
                    <a href="https://stad.gent/nl/toegankelijkheidsverklaring-stad-gent" i18n>Toegankelijkheidsverklaring</a>
                </li>
                <li>
                    <a href="javascript:Cookiebot.renew();" i18n>Cookie instellingen</a>
                </li>
            </ul>
        </div>
        <div class="footer--column partnership">
            <span i18n>In samenwerking met District09</span><i aria-hidden="true" class="icon-district09"></i>
        </div>
    </div>
</section>
