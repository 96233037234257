import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'BreakEmail'
})
export class BreakEmailPipe implements PipeTransform {

  // eslint-disable-next-line unused-imports/no-unused-vars
  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return;
    }
    return value.replace('@', '\u200B@');
  }

}
