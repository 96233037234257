<div class="overview-layout">
    <div class="mt-20 mb-20">
        <h1 i18n tabindex="-1">Onder welke hoedanigheid wil je verder gaan?</h1>
        <p i18n>Je moet kiezen in welke hoedanigheid je wil aanmelden: als persoon of als onderneming. Het is in naam
            van deze hoedanigheid dat je verder je vergunning zal aanvragen.
        </p>
        <p i18n>Opgelet: je keuze kan nadien niet meer gewijzigd worden.</p>

        <div class="toggle-button-wrapper">
            <button id="persoon" class="button button-toggle button-xlarge" (click)="togglePersoon()" i18n>
                Persoon
            </button>
            <button id="onderneming" class="button button-toggle button-xlarge" (click)="toggleOnderneming()" i18n>
                Onderneming
            </button>
        </div>

        <div *ngIf="loading$ | async" class="spinner">
            <div i18n>&hellip;loading</div>
        </div>
    </div>
</div>
