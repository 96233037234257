
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function debounce(func: any, wait: number, immediate: boolean): any {
  let timeout;
  return function(): void {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) { func.apply(context, args); }
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) { func.apply(context, args); }
  };
}
