<ng-container *ngIf="aantallenControleResultCodes.length > 0">
    <div id="aantallenControleMessages" class="messages messages--error" role="alert" aria-atomic="true">
        <i class="icon-cross" aria-hidden="true"></i>
        <div>
            <ng-container *ngFor="let resultCode of aantallenControleResultCodes">
                <div *ngIf="isBestemmingsAdresError(resultCode)">
                    <p i18n>
                        Voor dit adres is het maximum aantal vergunningen van dit type bereikt. Je kan geen bijkomende vergunning meer aanvragen op dit adres voor dit type van vergunning. Verwijder deze vergunning uit het winkelmandje.
                    </p>
                    <p i18n>Wat kan je doen?</p>
                    <ul>
                        <li i18n>Controleer het opgegeven adres.</li>
                        <li i18n>Neem contact op met het <a
                                href="https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent">Mobiliteitsbedrijf</a>.
                        </li>
                    </ul>
                </div>
                <div *ngIf="isDomicilieAdresError(resultCode)">
                    <p i18n>
                        Op dit adres zijn al twee bewonersvergunningen actief. Je kan geen bijkomende
                        bewonersvergunning
                        aanvragen.
                    </p>
                    <p i18n>Wat kan je doen?</p>
                    <ul>
                        <li i18n>Verhuis je naar het opgegeven adres? Vraag dan een <a class="pointer"
                                [routerLink]="routerLinkBewonerTijdelijk">bewonersvergunning bij adreswijziging </a> aan.
                        </li>
                        <li i18n>Neem contact op met het <a
                                href="https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent">Mobiliteitsbedrijf</a>.
                        </li>
                    </ul>
                </div>
                <div *ngIf="isVergunningHouderError(resultCode)">
                    <p i18n>
                        Je hebt voor deze vergunning het maximum aantal bereikt. Je kan geen bijkomende vergunning meer aanvragen voor dit type van vergunning. Verwijder deze vergunning uit het winkelmandje.
                    </p>
                    <p i18n>
                        Je vindt een overzicht van al je vergunningen op je <a [routerLink]="['/']">portaalpagina</a>.
                    </p>
                </div>
                <div *ngIf="isBewoner1Error(resultCode)">
                    <p i18n>
                        Op dit adres is al een eerste bewonersvergunning actief. Je kan voor dit adres enkel nog een
                        <a class="pointer" [routerLink]="routerLinkTweedeBewoner">tweede
                            bewonersvergunning</a> aanvragen.
                    </p>
                    <p i18n>Wat kan je doen?</p>
                    <ul>
                        <li i18n>Vraag een <a class="pointer" [routerLink]="routerLinkTweedeBewoner">tweede
                                bewonersvergunning</a> aan.</li>
                        <li i18n>Verhuis je naar het opgegeven adres? Vraag dan een <a class="pointer"
                                [routerLink]="routerLinkBewonerTijdelijk">bewonersvergunning bij adreswijziging </a>
                            aan.
                        </li>
                        <li i18n>Neem contact op met het <a
                                href="https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent">Mobiliteitsbedrijf</a>.
                        </li>
                    </ul>
                </div>
                <div *ngIf="isBewoner2Error(resultCode)">
                    <p i18n>
                        Je kan voor dit adres een gratis <a class="pointer"
                            [routerLink]="routerLinkEersteBewoner">eerste bewonersvergunning</a> aanvragen.
                    </p>
                    <p i18n>Wat kan je doen?</p>
                    <ul>
                        <li i18n>Vraag een <a class="pointer" [routerLink]="routerLinkEersteBewoner">eerste
                                bewonersvergunning</a> aan.</li>
                        <li i18n>Verhuis je naar het opgegeven adres? Vraag dan een <a class="pointer"
                                [routerLink]="routerLinkBewonerTijdelijk">bewonersvergunning bij adreswijziging </a>
                            aan.
                        </li>
                        <li i18n>Neem contact op met het <a
                                href="https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent">Mobiliteitsbedrijf</a>.
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>