<div class="form-item stacked">
  <label [attr.for]="id" [innerText]="label"></label>
  <div *ngIf="message" class="field-message" [id]="id + '-message'">
      <div [innerHTML]="message"></div>
      <div class="accolade "></div>
  </div>
  <div class="form-columns">
      <div class="form-item-column">
          <div class="file">
              <input type="file" ng2FileSelect [uploader]="uploader"
                     [accept]="accept"
                     [attr.aria-invalid]="error$ | async"
                     [id]="id"
                     [ngClass]="{'error': error$ | async}"
                     [attr.aria-describedby]="describedBy$ | async"
                     #fileUpload />
              <span class="file__button" [id]="id + '-file'" i18n>
                <ng-container *ngIf="hasFilesUploaded">Kies nog een bestand</ng-container>
                <ng-container *ngIf="!hasFilesUploaded">Kies een bestand</ng-container>
              </span>
          </div>
          <span class="help-text" *ngIf="limitations" [innerHTML]="limitations"></span>
      </div>
      <div class="form-item-column">
          <div *ngIf="error$ | async as error" class="field-message error" role="alert" [id]="id + '-error'">
              <div [innerHTML]="error"></div>
              <div class="accolade"></div>
          </div>
      </div>
  </div>
  <div *ngIf="uploader.isUploading" class="spinner" role="alert">
      <div i18n>bezig met opladen</div>
  </div>
</div>
