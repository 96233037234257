export type StatusMessageType = 'error' | 'warning' | 'info' ;

export class StatusMessageModel
{
  public message: string;
  public messageType: StatusMessageType = 'error';
  public messageCode: string; // some string for easy identification in the logging system
  public header: string; // can be used to store e.g. the functionality that was executing, the context

  constructor()
  {
  }

  public setMessage(message: string): void
  {
    this.message = message;
  }

  public setMessageType(type: StatusMessageType): void
  {
    this.messageType = type;
  }

  public setMessageCode(code: string): void
  {
    this.messageCode = code;
  }

  public setHeader(header: string): void
  {
    this.header = header;
  }

  public clear(): void
  {
    this.message = null;
    this.messageType = 'error'; // reset to default value
  }

  public isError(): boolean
  {
    return this.messageType === 'error';
  }

  public isWarning(): boolean
  {
    return this.messageType === 'warning';
  }

  public isInfo(): boolean
  {
    return this.messageType === 'info';
  }

  public hasMessage(): boolean
  {
    const rv =  this.message && this.message !== '';
    return rv;
  }
}
