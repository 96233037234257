import { UserInfo } from 'angular-oauth2-oidc';
import { Onderneming } from './onderneming';
import { Persoon } from './persoon';

export class User implements UserInfo {
    [key: string]: any;

    public voornaam: string;
    public naam: string;
    public email: string;
    public organisatie: string;
    public sub: string;
    public strong: boolean;
    public persoon?: Persoon;
    public onderneming?: Onderneming;
    public exists: boolean;
    public requireUserTypeForCreation: boolean;

    constructor(userInfo: UserInfo, strong: boolean) {
        Object.entries(userInfo).forEach(([key, value]) => this[key] = value);

        this.strong = strong;
    }
}
