import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientLogEntry } from '../../models/log-entry';
import { LogEntryService } from '../services/log-entry.service';

@Component({
    selector: 'avgpv-pub-page-internal-server-error',
    templateUrl: './page-internal-server-error.component.html',
    styleUrls: ['./page-internal-server-error.component.scss']
})
export class PageInternalServerErrorComponent{
    public logEntry$: BehaviorSubject<ClientLogEntry>;

    constructor(private logEntryService: LogEntryService) {
        this.logEntry$ = this.logEntryService.logEntry$;
    }

}
