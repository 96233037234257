import {
  OrderApi, GetOrCreateOrderForCurrentUserParameters, OrderSelector, OrderOverzichtInfo,
  IndienenOrderParameters, FileResponse, DeleteVergunningParameters, VergunningSelector,
  IBetaalOrderPublicParameters, BetaalOrderPublicParameters, LinkVergunningToOrderParameters,
  ILinkVergunningToOrderParameters,
  DeleteOrderItemVergunningVerlengingResult,
  DeleteOrderItemVergunningVerlengingParameters,
  OrderItemVerlengingSelector,
  IValidateOrderForIndienenParameters,
  ValidateOrderForIndienenParameters,
  ValidateOrderForIndienenResult
} from './../shared/publicapi/d09.avgpv.public.client';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public order: OrderOverzichtInfo;
  public order$: BehaviorSubject<OrderOverzichtInfo> = new BehaviorSubject(null);
  public orderCount$: Observable<string | number> = this.order$.pipe(map(o => o?.vergunningen?.length + o?.verlengingen?.length || '0'));
  public betalingFailedBericht$: BehaviorSubject<string> = new BehaviorSubject(null);

  private orderSelector: OrderSelector;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private orderApi: OrderApi
  ) { }

  public add(vergunning): Observable<any> {
    if (!this.order) {
      return this.getOrder().pipe(switchMap(() => this.addToOrder(vergunning)));
    }
    return this.addToOrder(vergunning);
  }

  public validateOrderForIndienen(): Observable<ValidateOrderForIndienenResult> {
    const parameters: IValidateOrderForIndienenParameters = {
      order: this.orderSelector
    };

    return this.orderApi.validateOrderForIndienen(new ValidateOrderForIndienenParameters(parameters));
  }

  public getOrder(): Observable<any> {
    return this.orderApi.getOrCreateOrderForCurrentUser(new GetOrCreateOrderForCurrentUserParameters())
      .pipe(
        tap(({ order }) => this.orderSelector = order),
        switchMap(({ order }) => this.getOverzicht(order)),
        catchError(() => of(null)),
        tap(o => {
          this.order = o;
          this.order$.next(o);
        })
      );
  }

  public initPayment(betalingOkUri: string, betalingMisluktUri: string): Observable<any> {
    const parameters: IBetaalOrderPublicParameters = {
      order: this.orderSelector,
      betalingOkUri,
      betalingMisluktUri,
      language: this.locale.substring(0, 2)
    };

    return this.orderApi.betaalOrder(new BetaalOrderPublicParameters(parameters))
      .pipe(tap(this.sendWorldlineResponse));
  }

  public indienenOrder(): Observable<FileResponse> {
    return this.orderApi.indienenOrder(new IndienenOrderParameters({ order: this.orderSelector }));
  }

  public deleteVergunning(vergunningSelector: VergunningSelector): Observable<void> {
    return this.orderApi.deleteVergunning(new DeleteVergunningParameters(
      { order: this.orderSelector, vergunning: vergunningSelector }));
  }

  public deleteVergunningVerlenging(orderItemVerlengingSelector: OrderItemVerlengingSelector):
    Observable<DeleteOrderItemVergunningVerlengingResult> {

    return this.orderApi.deleteOrderItemVergunningVerlenging(new DeleteOrderItemVergunningVerlengingParameters(
      { order: this.orderSelector, orderItemVerlenging: orderItemVerlengingSelector }));
  }

  private addToOrder(vergunning: VergunningSelector): Observable<any> {
    const parameters: ILinkVergunningToOrderParameters = {
      order: this.orderSelector,
      vergunning
    };

    return this.orderApi.linkVergunning(new LinkVergunningToOrderParameters(parameters))
      .pipe(switchMap(() => this.getOrder()));
  }

  private getOverzicht(orderSelector: OrderSelector): Observable<OrderOverzichtInfo> {
    return this.orderApi.getOrderOverzicht(orderSelector.id, orderSelector.reference);
  }

  // Worldline requires a form POST because Edge has a URL length limitation.
  // Using formdata returns status 302.
  private sendWorldlineResponse = (result) => {
    const { redirectionVersion, redirectionData, redirectionUrl } = result;
    const form = document.createElement('form');
    const versionInput = document.createElement('input');
    const versionData = document.createElement('input');

    versionInput.type = 'hidden';
    versionInput.name = 'redirectionVersion';
    versionInput.value = redirectionVersion;
    versionData.type = 'hidden';
    versionData.name = 'redirectionData';
    versionData.value = redirectionData;

    form.action = redirectionUrl;
    form.method = 'post';
    form.appendChild(versionInput);
    form.appendChild(versionData);

    document.body.appendChild(form);
    form.submit();
  };
}
