import { UserTypeEnum } from 'src/app/models/userType.enum';
import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'avgpv-pub-hoedanigheid',
  templateUrl: './hoedanigheid.component.html'
})
export class HoedanigheidComponent implements OnDestroy {
  public loading$ = new BehaviorSubject<boolean>(false);
  public error$ = new BehaviorSubject<string>(null);

  private destroyed = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  public togglePersoon(): void {
    this.submit(UserTypeEnum.PERSOON);
  }

  public toggleOnderneming(): void {
    this.submit(UserTypeEnum.ONDERNEMING);
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private async submit(userType: UserTypeEnum): Promise<void> {
    this.loading$.next(true);
    try {

      await this.authService.createLoggedOnUser(userType);
    }
    finally {
      this.loading$.next(false);
    }

    this.router.navigate([this.authService.returnRoute || '']);
  }
}
