import { ValidateProfielOnVergunningStartResult, ValidateProfielOnVergunningStartResultDtoEnum } from '../publicapi/d09.avgpv.public.client';
import { } from '../publicapi/d09.avgpv.public.client';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import {  map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { VergunningService } from '../../vergunning/vergunning.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ValidateProfielGuard  {

    constructor(
        private vergunningService: VergunningService,
        private authService: AuthService,
        private router: Router
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.isAllowed(next, state);
    }

    private isAllowed(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isLoggedin$
            .pipe(
                switchMap(isLoggedIn => {
                    if (isLoggedIn) {
                        return this.vergunningService.validateProfielOnVergunningStart();
                    } else {
                        return of(null);
                    }
                }),
                map((result: ValidateProfielOnVergunningStartResult) => {
                    if (result != null && result.validatieResult.length > 0) {
                        const redirectRequired = result.validatieResult.filter(x => x.toLowerCase()
                            !== ValidateProfielOnVergunningStartResultDtoEnum[ValidateProfielOnVergunningStartResultDtoEnum.MagicLink]
                                .toLowerCase()).length > 0;

                        const url = `${state.url}?redirectSource=profiel`;

                        if (redirectRequired) {
                            this.router.navigate(['profiel'], { queryParams: { return: url, guard: true } });
                            return false;
                        }
                        return true;
                    }
                    return true;
                }));
    }
}
