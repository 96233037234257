<div [id]="modalName" class="modal" aria-modal="true" aria-labelledby="modal-title" tabindex="-1">
    <div class="modal-inner">
        <div class="modal-header">
            <button type="button" class="button close icon-cross modal-close" [attr.data-target]="modalName">
                <span i18n>Sluiten</span>
            </button>
        </div>
        <div class="modal-content">
            <h3 id="modal-title" i18n>Ben je zeker?</h3>
            <form>
                <div class="modal-actions">
                    <button type="button" class="button button-primary modal-close" [attr.data-target]="modalName" i18n
                        (click)="confirm()">Ja</button>
                    <button type="button" class="button close button-secondary modal-close"
                        [attr.data-target]="modalName" i18n>Oei,
                        nee!</button>
                </div>
            </form>
        </div>
    </div>

    <div class="modal-overlay modal-close" [attr.data-target]="modalName" tabindex="-1"></div>
</div>