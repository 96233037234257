import { AantallenControleResultDtoEnum } from '../../publicapi/d09.avgpv.public.client';
import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'avgpv-pub-aantallen-controle-validation-messages',
    templateUrl: './aantallen-controle-validation-messages.component.html'
})
export class AantallenControleValidationMessageComponent {
    @Input() aantallenControleResultCodes: AantallenControleResultDtoEnum[];
    @Output() triggerRefresh: Subject<void> = new Subject<void>();

    public routerLinkBewonerTijdelijk = '/vergunning/nieuw/bewonertijdelijk/start';
    public routerLinkEersteBewoner = '/vergunning/nieuw/bewoner1/start';
    public routerLinkTweedeBewoner = '/vergunning/nieuw/bewoner2/start';

    public isBestemmingsAdresError(resultCode: AantallenControleResultDtoEnum): boolean {
        return resultCode.toLowerCase()
            === AantallenControleResultDtoEnum[AantallenControleResultDtoEnum.AantalNokBestemmingsAdres].toLowerCase();
    }

    public isDomicilieAdresError(resultCode: AantallenControleResultDtoEnum): boolean {
        return resultCode.toLowerCase()
            === AantallenControleResultDtoEnum[AantallenControleResultDtoEnum.AantalNokDomicilieAdres].toLowerCase();
    }

    public isVergunningHouderError(resultCode: AantallenControleResultDtoEnum): boolean {
        return resultCode.toLowerCase()
            === AantallenControleResultDtoEnum[AantallenControleResultDtoEnum.AantalNokVergunninghouder].toLowerCase();
    }

    public isBewoner1Error(resultCode: AantallenControleResultDtoEnum): boolean {
        return resultCode.toLowerCase()
            === AantallenControleResultDtoEnum[AantallenControleResultDtoEnum.AantalBewoner1NokBewoner2].toLowerCase();
    }

    public isBewoner2Error(resultCode: AantallenControleResultDtoEnum): boolean {
        return resultCode.toLowerCase()
            === AantallenControleResultDtoEnum[AantallenControleResultDtoEnum.AantalBewoner2NokBewoner1].toLowerCase();
    }
}
