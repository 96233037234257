import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { BannerApi, FeaturesApi } from '../shared/publicapi/d09.avgpv.public.client';

@Injectable({
    providedIn: 'root'
})
export class BannerAndFeatureService {
    constructor(private featureApi: FeaturesApi, private bannerApi: BannerApi, @Inject(LOCALE_ID) private locale: string) {
    }

    public isFeatureActive(featureName: string): Observable<boolean> {
        return this.featureApi.isFeatureActive(featureName);
    }

    public getBannerMessage(): Observable<string> {
        return this.bannerApi.getBannerText(this.locale.split('-')[0]);
    }
}
