import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { EnvironmentService } from '../../environment.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { ClientLogEntry } from '../../models/log-entry';
import { LogEntryService } from './log-entry.service';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  public logEntry$: BehaviorSubject<ClientLogEntry> = new BehaviorSubject(null);

  constructor(
    private environment: EnvironmentService,
    private logEntryService: LogEntryService,
    private router: Router,
    private ngZone: NgZone,
    private httpClient: HttpClient,
    private authSvc: AuthService) {
  }

  /**
   * Handles unhandled exceptions.
   * Don't use it as a catch all,
   * this is the train with destination: error page.
   */
  async handleError(error: any): Promise<void> {

    if (error?.rejection) {
      error = error.rejection;
    }

    // if chunk load error then we reload the page
    // we return from the function, because we do not want to log the error
    if (this.isChunkLoadError(error)) {
      window.location?.reload();
      return;
    }

    const logEntry = ClientLogEntry.CreateFromError(error, this.authSvc);
    logEntry.application = this.environment.itb.clientId;
    logEntry.properties.url = this.router.url;

    /**
     * Save a local copy in the service.
     */
    this.logEntryService.logEntry$.next(logEntry);

    if (!this.environment.production) {
      console.error(error, logEntry);
    }

    /**
     * Send the logEntry to the API.
     */
    this.httpClient.post(`${this.environment.api.baseUri}/clientlogging`, logEntry)
      .pipe(
        /**
         * Avoid error loop.
         */
        catchError(() => of()))
      .subscribe();

    /**
     * Show the user some cuberdons for their effort.
     */
    this.ngZone.run(() => setTimeout(() => this.router.navigate(['500']), 500));

  }

  private isChunkLoadError(error: any): boolean {
    const isChunkLoadErrorRv = error && typeof error === 'object' && error.name === 'ChunkLoadError';
    return isChunkLoadErrorRv;

  }
}
