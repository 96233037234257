import { AbstractControl, ValidationErrors } from '@angular/forms';

export class RijksregisternummerValidator {
    public static isValidFormat(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return;
        }

        const regexp = new RegExp('^([0-9]{11})$');
        let isValid = regexp.test(control.value);

        if (isValid) {
            const n = +control.value.substring(0, 9);
            const checksum = +control.value.substring(9);

            // If a person is born in or after the year 2000. The remainder needs to be calculated on a different number.
            // https://nl.wikipedia.org/wiki/Rijksregisternummer
            isValid = RijksregisternummerValidator.checkRemainder(n, checksum) ||
                RijksregisternummerValidator.checkRemainder(n + 2000000000, checksum);
        }

        return isValid ? null : { format: true };
    }

    private static checkRemainder(n: number, checksum: number): boolean {
        const calculatedChecksum = 97 - (n % 97);
        return calculatedChecksum === checksum;
    }
}
