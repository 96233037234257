import { ClientLoggingService } from './core/services/client-logging.service';
import { A11yService } from './core/services/a11y.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Component, OnInit } from '@angular/core';
import {
    filter,
    map,
    switchMap
} from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { Observable } from 'rxjs';
import { OrderService } from './order/order.service';
import { Title } from '@angular/platform-browser';
import { ClientLogLevel } from './models/log-entry';
@Component({
    selector: 'avgpv-pub-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public isDoneLoading$;
    public user$;
    public a11yMessage: Observable<string>;

    constructor(
        private authService: AuthService,
        private orderService: OrderService,
        private environment: EnvironmentService,
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private a11yService: A11yService,
        private clientLoggingService: ClientLoggingService) {

        this.clientLoggingService.logMessage(ClientLogLevel.Information, 'Starting Application'); // async call !!!
        this.isDoneLoading$ = authService.isDoneLoading$;
        this.user$ = authService.loggedInUser$;
        this.a11yMessage = a11yService.message$;

        this.authService.tryAutoLogin();

        // API might throw a "Could not find User in db for claims" error.
        // that's because the user is anly created during the first step of his first product request.
        // We don't care, no order = empty order, it's catched in the service.
        authService.isLoggedin$
            .pipe(filter(v => !!v), switchMap(() => orderService.getOrder()))
            .subscribe();

        this.router.events.pipe(
            filter((e) => e instanceof RoutesRecognized),
            map(({ state }: RoutesRecognized) =>
                // there is a child router-outlet in this application
                state.root.firstChild.firstChild?.data?.title || state.root.firstChild.data?.title)
        ).subscribe(title => {
            this.setPageTitle(title);
            this.handleA11yRouting(title);
        });
    }

    public get isProduction(): boolean {
        return this.environment.production || this.env === 'pr';
    }

    public get isStaging(): boolean {
        return this.env === 'st';
    }

    public get env(): string {
        return this.environment.env;
    }

    public get location(): string {
        return window.location.href.split('#')[0];
    }

    public logout(): void {
        this.authService.logout();
    }

    public navigateToFragment(fragment): void {
        const target = document.getElementById(fragment);
        if (!target) {
            return;
        }
        target.tabIndex = -1;
        target.style.outline = 'none';
        setTimeout(() => target.focus(), 200);
    }

    ngOnInit(): void {
        if (this.environment.googleTagManager.enabled) {
            this.addGTM(this.environment.googleTagManager.id);
        }
    }

    /**
     * Set the page title after each router event.
     */
    private setPageTitle(title: string): void {
        this.titleService.setTitle(`${title} | ${$localize`:@@site.naam:Mobiliteitsvergunningen`}`);
    }

    /**
     * Make angular routing accessible.
     * Handle focus management and
     * alert the user through the a11yService in combination with
     * an aria-live region.
     */
    private handleA11yRouting(title: string): void {
        this.a11yService.message$.next($localize`Pagina ${title} werd geladen`);
        document.body.focus();
    }

    /* eslint-disable prefer-arrow/prefer-arrow-functions */
    private addGTM(key: string): void {
    // add Google Tag Manager - source: https://developers.google.com/tag-platform/tag-manager/web#custom_web_installations
    (function(w, d, s, l, i): void {
        w[l] = w[l] || [];
        w[l].push({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        // @ts-ignore
        j.async = true;
        // @ts-ignore
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', key);
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${key}"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.insertBefore(noscript, document.body.childNodes[0]);
    }
    /* eslint-enable prefer-arrow/prefer-arrow-functions */
}
