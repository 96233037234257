import { Component, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import Modal from '@digipolis-gent/modal';

@Component({
  selector: 'avgpv-pub-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements AfterViewInit {
  @Input() modalName: string;
  @Output() confirmModal = new EventEmitter<void>();

  public ngAfterViewInit(): void {
    const modalElement = document.querySelector(`#${this.modalName}`);
    new Modal(modalElement, { changeHash: false });
  }

  public confirm(): void {
    this.confirmModal.next();
  }
}
