/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AuthConfig } from 'angular-oauth2-oidc';

export function itbConfig(baseHref, environment): AuthConfig {
    return {
        issuer: environment.itb.issuer,
        redirectUri: window.location.origin + '/' + baseHref + '/callback/itb',
        postLogoutRedirectUri: window.location.origin + '/' + baseHref + '/logout/itb',
        clientId: environment.itb.clientId,
        responseType: 'code',
        scope: 'openid profile email',
        showDebugInformation: !environment.production
    };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ACMConfig(baseHref, environment): AuthConfig {
    return {
        issuer: environment.acmIdm.issuer,
        showDebugInformation: !environment.production,
        clientId: environment.acmIdm.clientId,
        dummyClientSecret: environment.acmIdm.clientSecret,
        responseType: 'code',
        scope: 'openid rrn vo profile mobiliteitsvergunningen wettelijkvertegenwoordiger offline_access avgpv-backend-api ssm_mandaat',
        redirectUri: window.location.origin + '/' + baseHref + '/callback/acmidm',
        postLogoutRedirectUri: window.location.origin + '/' + baseHref + '/logout/acmidm',
        // timeoutFactor: 0.0018 // for refresh token, keep comment is handy for debugging to shorten the refresh period
    };
}
