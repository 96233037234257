<article class="overview-layout">

    <!-- VPEA-2169: Hide banner until futher notice -->
    <!-- <div class="region region-highlighted">
        <div class="block block-dg-crisis block-dg-crisis-communication">
            <div class="dg-crisis-block dg-crisis-block--type-front">
                <section class="crisis-communication__wrapper">
                    <div class="crisis-communication" role="alert">
                        <i aria-hidden="true" class="icon-exclamation"></i>
                        <div class="crisis-communication__content">
                            <h2 i18n>Opgelet</h2>
                            <div><p i18n>Wij verhuizen! <b>Vanaf 9 december</b> vind je ons in het <b>Stadskantoor Gent</b>, Woodrow Wilsonplein 1, 9000 Gent.</p></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div> -->

    <h1 i18n tabindex="-1">Portaal</h1>
    <div id="portaal-vergunningen">
        <h2 i18n>Mobiliteitsvergunningen</h2>
        <div class="grid-wrapper">
            <ul class="grid-2">
                <li>
                    <section class="highlight vergunning-link-block">
                        <div class="highlight__inner">
                            <h2 i18n>Mijn mobiliteitsvergunningen</h2>
                            <p i18n>Je aanvragen en vergunningen bekijken en wijzigen (vervangvoertuig registreren, nummerplaat wijzigen)</p>
                            <a id="mijnMobiliteitsvergunningenLink" [routerLink]="['/vergunning/lijst']" class="button button-primary" i18n>Mijn
                                mobiliteitsvergunningen</a>
                        </div>
                    </section>
                </li>
                <li>
                    <section class="highlight">
                        <div class="highlight__inner">
                            <h2 i18n>Vervangvoertuig aan- of afmelden</h2>
                            <p i18n>Registreer tijdelijk een vervangvoertuig voor je eigen voertuig.</p>
                            <a id="vervangwagenWizardLink" [routerLink]="['/vergunning/vervangwagen-registreren']" class="button button-primary" i18n>Vervangvoertuig aan- en afmelden</a>
                        </div>
                    </section>
                </li>
                <li>
                    <section class="highlight">
                        <div class="highlight__inner">
                            <h2 i18n>Vraag een vergunning aan</h2>
                            <p i18n>Je kan een parkeervergunning en/of een vergunning autovrij gebied aanvragen.</p>
                            <a id="aanvragenLink" [routerLink]="['/vergunning']" class="button button-primary" i18n>Vergunning
                                aanvragen</a>
                        </div>
                    </section>
                </li>
                <li>
                    <section class="highlight">
                        <div class="highlight__inner">
                            <h2 i18n>Bezoekersbeheer autovrij gebied</h2>
                            <p i18n>Registreer, wijzig en beheer je bezoekers in deze beheertool.</p>
                            <a id="bezoekersbeheerLink" [routerLink]="['/bezoeker/beheer']" class="button button-primary" i18n>Bezoekersbeheer autovrij gebied</a>
                        </div>
                    </section>
              </li>

            </ul>
        </div>
    </div>
    <h2 *ngIf="user" i18n>Klantenprofiel</h2>
    <div *ngIf="user" class="highlight">
        <div id="portaal-klantenprofiel" class="mb-20 highlight__inner">
            <div *ngIf="user.persoon as persoon">
                <dl class="inline">
                    <dt i18n>Volledige naam:</dt>
                    <dd>{{persoon.voorNaam}} {{persoon.naam}}</dd>
                    <dt i18n>Rijksregisternummer:</dt>
                    <dd>{{persoon.rijksRegisterNummer}}</dd>
                    <dt *ngIf="persoon.hoofdVerblijfplaats as adres" i18n>Adres:</dt>
                    <dd *ngIf="persoon.hoofdVerblijfplaats as adres"><ng-container *ngIf="adres.straat || adres.huisnummer || adres.postCode || adres.gemeente">{{adres.straat}} {{adres.huisNummer}}<span *ngIf="adres.busNummer"> /
                        {{adres.busNummer}}</span>, {{adres.postCode}} {{adres.gemeente}}, {{adres.landIso2Code}}</ng-container></dd>
                    <dt *ngIf="persoon.telefoon" i18n>Telefoon:</dt>
                    <dd *ngIf="persoon.telefoon"><a [href]="'tel:' + persoon.telefoon">{{persoon.telefoon}}</a></dd>
                    <dt *ngIf="persoon.email" i18n>Email:</dt>
                    <dd *ngIf="persoon.email"><a [href]="'mailto:' + persoon.email"> {{persoon.email}}</a></dd>
                </dl>
            </div>
            <div *ngIf="user.onderneming as onderneming">
                <dl class="inline">
                    <dt i18n>Ondernemingsnaam:</dt>
                    <dd>{{onderneming.naam}}</dd>
                    <dt i18n>Ondernemingsnummer:</dt>
                    <dd>{{onderneming.ondernemingsNummerOfOvoCode}}</dd>
                    <dt i18n>Volledige naam:</dt>
                    <dd>{{onderneming.voorNaam}} {{onderneming.naam}}</dd>
                    <dt *ngIf="onderneming.adresEntiteit as adres">Adres:</dt>
                    <dd *ngIf="onderneming.adresEntiteit as adres"><ng-container *ngIf="adres.straat || adres.huisnummer || adres.postCode || adres.gemeente">{{adres.straat}} {{adres.huisNummer}}<span *ngIf="adres.busNummer"> /
                        {{adres.busNummer}}</span>, {{adres.postCode}} {{adres.gemeente}}, {{adres.landIso2Code}}</ng-container></dd>
                    <dt *ngIf="onderneming.telefoon" i18n>Telefoon:</dt>
                    <dd *ngIf="onderneming.telefoon"><a [href]="'tel:' + onderneming.telefoon">{{onderneming.telefoon}}</a></dd>
                    <dt *ngIf="onderneming.email" i18n>Email:</dt>
                    <dd *ngIf="onderneming.email"><a [href]="'mailto:' + onderneming.email"> {{onderneming.email}}</a></dd>
                    <dt i18n>Naam contactpersoon:</dt>
                    <dd>{{onderneming.voorNaam}} {{onderneming.naam}}</dd>
                </dl>
            </div>
            <button id="profielButton" type="button" class="button button-primary button-block icon-arrow-right mb-10" i18n
                    (click)="routeProfile()">Pas je gegevens aan
            </button>
        </div>
    </div>
</article>
