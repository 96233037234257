import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ClientLoggingService } from 'src/app/core/services/client-logging.service';

@Component({
  selector: 'avgpv-pub-login-acm-idm',
  templateUrl: './login-acm-idm.component.html',
  styleUrls: ['./login-acm-idm.component.scss']
})
export class LoginAcmIdmComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private clientLoggingService: ClientLoggingService) {

    route.queryParams.pipe(take(1)).subscribe(params => {
           if (params.login_hint)
           {
             const loginHint = params.login_hint;
             const returnUrl = params.returnurl;
             this.authService.acmIdmGerichteLogin(loginHint, returnUrl).then(() => {

             });
           }
           else
           {
             this.router.navigateByUrl('/'); // navigate to homepage.
           }

    });
  }

  ngOnInit(): void {
  }

}
