import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import accessibleAutocomplete from 'accessible-autocomplete';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { CodeNaam } from 'src/app/models/code-naam';
import { LandenService } from 'src/app/services/landen.service';
import { debounce } from '../../../utils/debounce';

@Component({
  selector: 'avgpv-pub-address',
  templateUrl: './address.component.html'
})
export class AddressComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('straatAutocomplete', { read: ElementRef }) straatAutocomplete: ElementRef;

  @Input() public uniqueIdPRefix = '';
  @Input() public formGroup: UntypedFormGroup;
  @Input() public legend: string;
  @Input() public hiddenFields: string[] = [];
  @Input() public straatIsDisabled: boolean;
  @Input() public syncAddress: Observable<void>;
  @Input() private submitted: boolean;

  public isLoading$ = new BehaviorSubject<boolean>(false);
  public huisNummers: string[] = [];
  public busNummers: string[] = [];
  public landen$: Observable<CodeNaam[]>;
  public isGentsePostCode$: Observable<boolean>;
  public isGentsePostCode: boolean;
  public straatError$ = new BehaviorSubject<string>(null);

  private autcompleteField: HTMLInputElement;
  private destroyed = new Subject<void>();
  private gentsePostCodes = ['9000', '9030', '9031', '9032', '9040', '9041', '9042', '9050', '9051', '9052'];

  private straatIsDisabled$ = new BehaviorSubject<boolean>(true);

  constructor(private landenService: LandenService) {
    this.landen$ = landenService.getLanden();
    this.getStraatSuggestions = debounce(this.getStraatSuggestions, 750, false);
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.isLoading$.complete();
    this.straatError$.complete();
    this.straatIsDisabled$.complete();
  }

  public ngAfterViewInit(): void {
    this.createStreetAutocomplete();
    const element = document.getElementById(this.uniqueIdPRefix + 'straat');
    if (element) {
      element.setAttribute('autocomplete', 'shouldnotwork');
    }

    /**
     *  This disables/enables the street based on the input.
     *  Even though we listen on the straatIsDisabled in ngOnChanges,
     *  it wouldn't work without this.
     */
    this.setDisabledStraat(this.straatIsDisabled);

    /**
     * Check if the addres we know is a Ghent address
     */
    this.checkIfGhentsAdress();

    if (this.syncAddress) {
      const combined = combineLatest([this.syncAddress, this.straatIsDisabled$]);
      combined.pipe(
        takeUntil(this.destroyed),
        tap(() => {
          this.checkIfGhentsAdress();
        })
      ).subscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.straatIsDisabled) {
      // this will listen to the update of straatIsDisabled input
      this.setDisabledStraat(this.straatIsDisabled);
      this.straatIsDisabled$.next(this.straatIsDisabled);
    }
    if (changes.formGroup) {
      this.checkIfGhentsAdress();
    }
  }

  public isInvalid(control: string): boolean {
    const c = this.formGroup.get(control);

    if (!c) {
      return true;
    }
    return c.errors && (c.touched || this.submitted);
  }

  public onUserChangedLand(land: string): void {
    if (land) {
      // Reset fields
      this.resetPostcode();
      this.resetGemeente();
      this.resetStraat();
      this.resetHuisnummer();
      this.resetBusnummer();
    }
  }

  public onUserChangedPostcode(postcode: string): void {
    if (postcode) {
      // Reset fields
      this.resetGemeente();
      this.resetStraat();
      this.resetHuisnummer();
      this.resetBusnummer();

      // Check if new postcode is a postcode of Ghent.
      this.checkIfGhentsAdress();
    }
  }

  public onUserChangedGemeente(gemeente: string): void {
    if (gemeente) {
      this.resetStraat();

      // resets the autcomplete value
      if (this.autcompleteField) {
        this.autcompleteField.value = null;
      }

      this.resetHuisnummer();
      this.resetBusnummer();
    }
  }

  public onUserChangedHuisnummer(huisnummer: string): void {
    if (huisnummer) {
      this.getBusnummers();
    }
  }

  public isVisible(field: string): boolean {
    return this.hiddenFields.indexOf(field) === -1;
  }

  public checkIfGhentsAdress(): void {
    const postCode = this.formGroup.get('postCode').value;
    const landIso2Code = this.formGroup.get('landIso2Code').value;
    this.isGentsePostCode = landIso2Code === 'BE' && this.gentsePostCodes.indexOf(postCode) !== -1 && !this.straatIsDisabled;

    if (this.isGentsePostCode) {
      const value = this.formGroup.get('straat').value || null;
      const crabControl = this.formGroup.get('crabCodeStraat');

      // Reset autocomplete error.
      this.straatError$.next(null);

      // Reset crabCode
      if (crabControl) {
        crabControl.reset();
      }

      // Bind the autocomplete value to the formControl
      if (this.autcompleteField) {
        this.autcompleteField.value = value;
      }

      if (this.isGentsePostCode && value) {
        this.testStraat(value, false);
      }
    } else {
      // check if straat is disabled, meaning busnummer has to stay disabled
      if (!this.straatIsDisabled) {
        // to make sure the busnummer can be filled in if needed
        this.formGroup.controls.busNummer.enable();
      }
    }
  }

  private getHuisnummers(): void {
    const straat = this.formGroup.get('straat').value;
    const crabCodeStraat = this.formGroup.get('crabCodeStraat').value;
    const postCode = this.formGroup.get('postCode').value;
    const gemeente = this.formGroup.get('gemeente').value;

    if (straat && crabCodeStraat && postCode && gemeente) {
      this.isLoading$.next(true);

      // Then get all the busnummers for the combination of postcode, gemeente, straat, crabcode
      this.landenService.getHuisnummerSuggestions(postCode, gemeente, straat, crabCodeStraat)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.isLoading$.next(false))
        )
        .subscribe((huisnummers) => {
          const huisnummerList: string[] = [];

          // add default value
          huisnummerList.push('');

          // push the known huisnummers to the list
          huisnummers.forEach((huisnummer) => huisnummerList.push(huisnummer));

          this.huisNummers = huisnummerList;
        });
    }
  }

  private getBusnummers(): void {
    const huisnummer = this.formGroup.get('huisNummer').value;
    const straat = this.formGroup.get('straat').value;
    const crabCodeStraat = this.formGroup.get('crabCodeStraat').value;
    const postCode = this.formGroup.get('postCode').value;
    const gemeente = this.formGroup.get('gemeente').value;

    if (huisnummer && straat && crabCodeStraat && postCode && gemeente) {
      this.isLoading$.next(true);

      // Then get all the busnummers for the combination of postcode, gemeente, straat, crabCode, and huisnummer
      this.landenService.getBusnummerSuggestions(postCode, gemeente, straat, crabCodeStraat, huisnummer)
        .pipe(
          catchError(() => of([])),
          finalize(() => this.isLoading$.next(false))
        )
        .subscribe((busnummers) => {
          const filteredBusnummers = busnummers.filter((x): x is string => x !== null);
          this.addOrRemoveValidator(filteredBusnummers);

          const busnummerList: string[] = [];

          if (busnummers.length > 0) {
            // add default value
            busnummerList.push('');
          }

          // push the known busnummers to the list
          filteredBusnummers.forEach((busnummer) => busnummerList.push(busnummer));
          this.busNummers = busnummerList;
        });
    }
  }

  private addOrRemoveValidator(busnummers: string[]): void {
    if (busnummers.length > 0) {
      this.formGroup.controls.busNummer.enable();
      this.formGroup.controls.busNummer.setValidators([Validators.required]);
    } else {
      this.formGroup.controls.busNummer.disable();
      this.formGroup.controls.busNummer.clearValidators();
    }
    this.formGroup.controls.busNummer.updateValueAndValidity();
  }

  /**
   * Test the value.
   * Reset the formControl if the test fails to invalidate the form.
   * Display an autocomplete error.
   */
  private testStraat(value: string, resetDependents: boolean): void {
    if (resetDependents) {
      // Clear huisnummer & busnummer when straat has changed manually
      this.resetHuisnummer();
      this.resetBusnummer();
    }

    this.getStraatSuggestions(value, (v: { naam: string; crabCode: string }[]) => {
      const match = v?.find(suggestion => suggestion.naam.toUpperCase() === value.toUpperCase());
      const crabControl = this.formGroup.get('crabCodeStraat');

      if (match) {
        this.formGroup.get('straat').setValue(match.naam);
        if (crabControl) {
          crabControl.setValue(match.crabCode);
        }

        // Call to get huisnummers
        this.getHuisnummers();
        this.getBusnummers();
      }
      else {
        this.formGroup.get('straat').reset();
        this.straatError$.next($localize`De ingevoerde straat werd niet teruggevonden.`);
        if (crabControl) {
          crabControl.reset();
        }
      }
    });
  }

  private getStraatSuggestions(value: string, next: (v: { naam: string; crabCode: string }[]) => void): void {
    const postCode = this.formGroup.get('postCode').value;
    const gemeente = this.formGroup.get('gemeente').value;

    this.landenService.getStraatSuggestions(postCode, gemeente, value, 10)
      .pipe(take(1), catchError(() => of([])))
      .subscribe((v) => {
        next(v);
      });
  }

  private createStreetAutocomplete(): void {
    /**
     * Translatable strings, most of these are for visually impeared users only.
     * See https://github.com/alphagov/accessible-autocomplete#internationalization for default values.
     */
    const tStatusQueryTooShort = (minQueryLength: number) => $localize`Voer minstens ${minQueryLength} karakters in om te beginnen zoeken.`;
    const tNoResults = () => $localize`Geen resultaten gevonden`;
    const tStatusNoResult = () => $localize`Geen zoekresultaten`;
    const tStatusSelectedOption =
      (selectedOption: string, length: number, index: number) => $localize`${selectedOption} ${index + 1} van ${length} is geselecteerd.`;
    const tAssistiveHint = () =>
      $localize`Als de resultaten voor automatisch aanvullen beschikbaar zijn, gebruik dan de omhoog en
       omlaag pijltjestoetsen om ze te verkennen en enter om te selecteren. Touch-apparaatgebruikers verkennen door aanraking of met veegbewegingen`;
    const tStatusResults =
      (length: number, contentSelectedOption: string) => `<span>${length} ${(length === 1)
        ? $localize`resultaat` : $localize`resultaten`} ${(length === 1) ? $localize`is` : $localize`zijn`} beschikbaar. ${contentSelectedOption}</span>`;

    // 'AccessibleAutocomplete' doesn't get released from memory. It's a possible memoryleak for Angular.
    // In theory it should be released when the AddressComponent is destroyed, but in reality we see
    //  that components which use the AddressComponent remain in the memory. Possible fix would be
    // introducing a part of react so that the WillUnmount method in the AccessibleAutocomplete library
    // could be called automatically. Other fix would be to introduce another autocomplete.
    accessibleAutocomplete({
      element: this.straatAutocomplete.nativeElement,
      // ID should match the label[for]
      id: this.uniqueIdPRefix + 'straat',
      name: this.uniqueIdPRefix + 'straat',
      required: true,
      autoselect: false,
      defaultValue: this.formGroup.get('straat')?.value || '',
      tStatusQueryTooShort,
      tNoResults,
      tStatusNoResult,
      tStatusSelectedOption,
      tAssistiveHint,
      tStatusResults,
      onConfirm: (o: string) => {

        const value = this.autcompleteField.value;

        // reset the autocomplete error
        this.straatError$.next(null);

        // if there is no free-text value, reset the formControl to invalidate the form.
        if (!value) {
          this.resetStraat();
          this.straatError$.next($localize`Straat is een verplicht veld en werd niet ingevuld.`);
          return;
        }

        // retest the output or free text value
        // this allows us to capture the crabCode too,
        // but sends an extra request...
        this.testStraat(o || value, true);
      },
      source: (query: string, populateResults: (data: string[]) => void) =>
        this.getStraatSuggestions(query, (v) => populateResults(v.map(straat => straat.naam)))
    });

    this.autcompleteField = document.getElementById(this.uniqueIdPRefix + 'straat') as HTMLInputElement;
  }

  private setDisabledStraat(disabled: boolean): void {
    const element = document.getElementById(this.uniqueIdPRefix + 'straat');
    if (element) {
      if (disabled) {
        element.setAttribute('disabled', '');
      } else {
        element.removeAttribute('disabled');
      }
    }
  }

  private resetPostcode(): void {
    this.resetFormField('postCode');
  }

  private resetGemeente(): void {
    this.resetFormField('gemeente');
  }
  private resetStraat(): void {
    this.resetFormField('straat');
  }

  private resetHuisnummer(): void {
    this.resetFormField('huisNummer');
  }

  private resetBusnummer(): void {
    this.resetFormField('busNummer');
  }

  private resetFormField(formField: string): void {
    this.formGroup.get(formField).reset();
  }
}
