<header class="header">
    <div class="content-container">
        <a routerLink="/" title="Home" class="site-logo subsite" rel="home"
            i18n="@@site.naam">Mobiliteitsvergunningen</a>

        <a *ngIf="user" routerLink="/order" i18n-aria-label aria-label="winkelmandje" i18n-title title="winkelmandje"
            class="avgpv-order standalone-link">
            <span *ngIf="orderCount$ | async as count" class="avgpv-count" [@valueAnimation]="count">{{ count }}</span>
        </a>

        <div class="authentication accordion" #container>
            <a *ngIf="!isPartiallyAuthenticated" [routerLink]="['login']" [queryParams]="{return: url$ | async }"
                class="login-link" i18n>Aanmelden</a>
            <ng-container *ngIf="isPartiallyAuthenticated">
                <button [attr.aria-expanded]="!isProfileHidden" aria-controls="mijn_gent_content"
                    class="toggle accordion--button" (click)="isProfileHidden = !isProfileHidden">
                    <ng-container *ngIf="user?.persoon as persoon">
                        <span class="avatar" [innerText]="(persoon.voorNaam || persoon.email) | slice:0:1"></span>
                        <span *ngIf="persoon.naam && persoon.voorNaam">{{getFullname(persoon.voorNaam, persoon.naam) |
                            Truncate:0:35}}</span>
                        <span *ngIf="!(persoon.naam && persoon.voorNaam)">{{persoon.email}}</span>
                    </ng-container>
                    <ng-container *ngIf="user?.onderneming as onderneming">
                        <span class="avatar" [innerText]="(onderneming.naam || onderneming.email) | slice:0:1"></span>
                        <span *ngIf="onderneming.naam">{{onderneming.naam | Truncate:0:35}}
                        </span>
                        <span *ngIf="!onderneming.naam">{{onderneming.email}} </span>
                    </ng-container>
                </button>
                <div id="mijn_gent_content" class="accordion--content" [attr.aria-hidden]="isProfileHidden"
                    [attr.hidden]="isProfileHidden ? isProfileHidden : null">
                    <article class="content">
                        <h2 i18n="@@site.naam">Mobiliteit</h2>
                        <section class="profile">
                            <ng-container *ngIf="user?.persoon as persoon">
                                <span class="avatar"
                                    [innerText]="(persoon.voorNaam || persoon.email) | slice:0:1"></span>
                                <div class="profile-info">
                                    <span *ngIf="persoon.naam && persoon.voorNaam">{{persoon.voorNaam}} {{persoon.naam}}
                                    </span>
                                    <span *ngIf="!(persoon.naam && persoon.voorNaam)">{{persoon.email | BreakEmail}}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="user?.onderneming as onderneming">
                                <span class="avatar"
                                    [innerText]="(onderneming.naam || onderneming.email) | slice:0:1"></span>
                                <span *ngIf="onderneming.naam" [innerText]="onderneming.naam | Truncate:0:35"></span>
                                <span *ngIf="!onderneming.naam" [innerText]="onderneming.email | BreakEmail"></span>
                            </ng-container>
                        </section>
                        <section>
                            <ul class="links">
                                <li>
                                    <a routerLink="/portaal" i18n>Portaal</a>
                                </li>
                                <li *ngIf="user">
                                    <a routerLink="/order" i18n>Winkelmandje</a>
                                </li>
                            </ul>
                        </section>
                        <footer>
                            <button href='#' (click)="logout.emit()" class="button button-small button-secondary"
                                i18n>Afmelden</button>
                        </footer>
                    </article>
                </div>
            </ng-container>
        </div>

        <!-- <div class="language-switcher accordion">
            <button [attr.aria-expanded]="!isLanguageSwitchHidden" aria-controls="language_switcher_content"
                class="toggle accordion--button" (click)="isLanguageSwitchHidden = !isLanguageSwitchHidden">
                {{ currentLanguage | uppercase }}
            </button>
            <div id="language_switcher_content" class="accordion--content" [attr.aria-hidden]="isLanguageSwitchHidden"
                [attr.hidden]="isLanguageSwitchHidden ? isLanguageSwitchHidden : null">
                <ul class="content links">
                    <li>
                        <a *ngIf="!isCurrentLanguage('nl'); else textNL" href="/nl{{url$ | async}}" hreflang="nl"
                            title="Ga verder in het Nederlands" lang="en">NL</a>
                        <ng-template #textNL><strong>NL</strong></ng-template>
                    </li>
                    <li>
                        <a *ngIf="!isCurrentLanguage('en'); else textEN" href="/en{{url$ | async}}" hreflang="en"
                            title="Continue in English" lang="en">EN</a>
                        <ng-template #textEN><strong>EN</strong></ng-template>
                    </li>
                    <li>
                        <a *ngIf="!isCurrentLanguage('fr'); else textFR" href="/fr{{url$ | async}}" hreflang="fr"
                            title="Poursuivez en français" lang="fr">FR</a>
                        <ng-template #textFR><strong>FR</strong></ng-template>
                    </li>
                    <li>
                        <a *ngIf="!isCurrentLanguage('de'); else textDE" href="/de{{url$ | async}}" hreflang="de"
                            title="Weiter auf Deutsch" lang="de">DE</a>
                        <ng-template #textDE><strong>DE</strong></ng-template>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
    <div class="site-name">
        <span i18n="@@site.naam">Mobiliteit</span>
    </div>
    <avgpv-pub-breadcrumbs [childIsTabletOrLarger]="childIsTabletOrLarger"></avgpv-pub-breadcrumbs>
    <hr />

    <div class="content-container" *ngIf="isStaging">
        <div class="messages" role="alert" aria-atomic="true" i18n>
            <i class="icon-exclamation" aria-hidden="true"></i>
            <p>Dit is een TEST-omgeving. Ga naar <a
                    href="https://mobiliteitsvergunningen.stad.gent">https://mobiliteitsvergunningen.stad.gent</a> om
                je vergunning te beheren.
            </p>
        </div>
    </div>

    <avgpv-pub-crisis-communication></avgpv-pub-crisis-communication>

</header>