import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  /**
   * Check if the browser supports input[type=date].
   * Detecting attribute is not enough, so we check if the browser sanitises input.
   */
  public get hasDateInput(): boolean {
    const input = document.createElement('input');
    const notADateValue = 'not-a-date';
    input.setAttribute('type', 'date');
    input.setAttribute('value', notADateValue);
    return input.value !== notADateValue;
  }

}
