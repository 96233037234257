<nav class="breadcrumb section--default" [attr.aria-expanded]="!expanded" aria-labelledby="system-breadcrumb"
    *ngIf="(breadcrumbs$ | async) as crumbs">
    <div class="content-container">
        <h2 id="system-breadcrumb" class="visually-hidden">Breadcrumb</h2>
        <ol class="no-style">
            <ng-container *ngFor="let crumb of crumbs; let i = index">
                <li class="expandable" *ngIf="i < crumbs.length - 5 && expanded">
                    <a href="#" [attr.aria-expanded]="expanded" (click)="expanded = !expanded">...</a>
                </li>
                <li [style.display]="(i < crumbs.length - 2 && expanded) ? 'none' : ''">
                    <a *ngIf="crumb.isExternalLink && i != crumbs.length - 1" [href]="crumb.path">{{crumb.naam}}</a>
                    <a *ngIf="!crumb.isExternalLink && i != crumbs.length - 1"
                        [routerLink]="crumb.path">{{crumb.naam}}</a>
                    <span *ngIf="i == crumbs.length - 1">{{crumb.naam}}</span>
                </li>
            </ng-container>
        </ol>
    </div>
</nav>