<div class="form-item" [ngClass]="{'stacked': usedInFilter}" [formGroup]="formGroup">
  <label [attr.for]="id" [innerText]="fullLabel"></label>
  <div *ngIf="message" class="field-message" [id]="id + '-message'">
    <div [innerHTML]="message"></div>
    <div class="accolade "></div>
  </div>
  <div class="form-columns">
    <div class="form-item-column">
      <input *ngIf="dateService.hasDateInput" type="date"
             [id]="id"
             [formControlName]="myformControlName"
             [ngClass]="{'error': error}"
             [attr.aria-describedby]="describedBy"
             [dmv-required]="formGroup.controls[myformControlName]"
             [attr.aria-invalid]="error ? true : null"
             (input)="myInput.emit($event.target.value)" [min]="min" [max]="max" />
      <input *ngIf="!dateService.hasDateInput" type="date" useValueAsDate
             [id]="id"
             [formControlName]="myformControlName"
             [ngClass]="{'error': error}"
             [attr.aria-describedby]="error ? id + '-error' : null"
             [dmv-required]="formGroup.controls[myformControlName]"
             [attr.aria-invalid]="error ? true : null"
             (input)="myInput.emit($event.target.value)" [min]="min" [max]="max" />
    </div>
    <div class="form-item-column">
      <div *ngIf="error" class="field-message error" role="alert" [id]="id + '-error'">
        <div [innerHTML]="error"></div>
        <div class="accolade"></div>
      </div>
    </div>
  </div>
</div>
