<article class="detail-layout">
    <h1 i18n>We kunnen de pagina die je zoekt niet vinden</h1>
    <div class="highlight help-block">
        <div class="highlight__inner">
            <div>
                <h2 i18n>Hoe kunnen we u verder helpen?</h2>
                <div class="help-block-section">
                    <p i18n>Dat kan op verschillende manieren:</p>
                    <ul class="icon-list">
                        <li>
                            <i class="icon-home" aria-hidden="true"></i>
                            <span i18n>Terugkeren naar de</span>&nbsp;<a [routerLink]="['/']" i18n>homepagina</a>
                        </li>
                        <li>
                            <i class="icon-fancyback" aria-hidden="true"></i>
                            <span i18n>De terugknop van uw browser gebruiken</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="404-poetry">
        <h2>Speurtocht</h2>
        <p>
            we kamden de stad uit,<br>
            braken tegels los, keerden<br>
            kapelletjes, kerken, begraafplaatsen <br>
            ondersteboven, daalden in waterputten af, <br>
            stonden met huiszoekingsbevelen te zwaaien.<br><br>
            helaas, wat u zocht, heeft zich verstopt<br>
            bij het verdwenen paneel van het Lam Gods.</p>
        <p>
            <strong>David Troch, Stadsdichter Gent</strong>
        </p>
    </div>
</article>
