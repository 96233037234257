/* eslint-disable prefer-arrow/prefer-arrow-functions */

function jsonStringifyReplacer(key, value): any {
  try {
    if (typeof value === 'object') {
      const obj = {};

      Object.getOwnPropertyNames(value).forEach(propName => {
        obj[propName] = value[propName];
      });

      return obj;
    }

    return value;
  }
  catch (e) {
    return value;
  }

}

// allow to stringify, any exceptions are ignored
// on error, error is returned and also a simple stringify is tried on the object
export function safeJSONStringify(obj: any): string {
  try {
    if (obj) {
      const rv = JSON.stringify(obj, jsonStringifyReplacer, 4);
      return rv;
    }
  }
  catch (e) {
    return `{ text: "could not convert to json", error: ${safeJSONStringify(e)}, simpleJson: ${safeJSONStringifySimple(obj)} }`;
  }
  return '';
}

// is called simple because no use of a replacer
function safeJSONStringifySimple(obj: any): string {
  try {
    if (obj) {
      const rv = JSON.stringify(obj, null, 4);
      return rv;
    }
  }
  catch (e) {
    return `{ text: "could not (simple) convert to json", error: ${safeJSONStringify(e)} }`;
  }
  return '';
}

export function formatError(error: any): string {
  const rv = safeJSONStringify(error);
  return rv;
}

export function formatObject(obj: any): string {
  if (obj === null) {
    return 'null';
  }

  if (obj === undefined) {
    return 'null';
  }

  const rv = safeJSONStringify(obj);
  return rv;

}
