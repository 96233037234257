import { StatusMessageModel } from './../../errorhandling';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'avgpv-pub-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss' ]
})
export class StatusMessageComponent implements OnInit {

  @Input() statusMessage: StatusMessageModel = new StatusMessageModel();

  constructor() { }

  ngOnInit(): void {
  }

}
