import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateService } from 'src/app/services/date.service';

@Component({
    selector: 'avgpv-pub-input-date',
    templateUrl: './input-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputDateComponent {

    @Input() public formGroup: FormGroup;
    @Input() public myformControlName: string;
    @Input() public label: string;
    @Input() public error: string;
    @Input() public message: string;
    @Input() public min = '0001-01-01';
    @Input() public max = '9999-12-31';
    @Input() public usedInFilter = false;
    @Output() public myInput: EventEmitter<string> = new EventEmitter();

    constructor(public dateService: DateService) {
    }

    public get id(): string {
        return `${this.label}-input_date`;
    }

    public get describedBy(): string {
        if (this.error) {
            return `${this.id}-error`;
        }
        if (this.message) {
            return `${this.id}-message`;
        }
        return null;
    }

    public get fullLabel(): string {
        if (this.dateService.hasDateInput) {
            return this.label;
        }
        return `${this.label}  ${$localize`(dd/mm/jjjj)`}`;
    }
}
