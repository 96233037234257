import { Component, Input, AfterViewInit } from '@angular/core';
import Modal from '@digipolis-gent/modal';

@Component({
    selector: 'avgpv-pub-algemene-voorwaarden-modal',
    templateUrl: './algemene-voorwaarden-modal.component.html'
})
export class AlgemeneVoorwaardenModalComponent implements AfterViewInit {
    @Input() modalName: string;

    public ngAfterViewInit(): void {
        const modalElement = document.querySelector(`#${this.modalName}`);
        new Modal(modalElement, { changeHash: false });
    }
}
