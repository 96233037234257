<article class="detail-layout">
    <h1 i18n tabindex="-1">Vergunning niet mogelijk volgens de hoedanigheid van je klantenprofiel</h1>
    <p i18n>De vergunning die je wil aanmaken is niet mogelijk als
        <span *ngIf="!!user?.persoon">natuurlijk persoon</span>
        <span *ngIf="!!user?.onderneming">onderneming</span>.
    </p>
    <div class="highlight help-block">
        <div class="highlight__inner">
            <div>
                <h2 i18n>Wat kan je doen</h2>
                <div class="help-block-section">
                    <p i18n>Er zijn 3 mogelijke opties:</p>
                    <ol>
                        <li>
                            <span i18n>Meld je af en log aan met een klantenprofiel met een andere hoedanigheid</span>
                        </li>
                        <li>
                            <span i18n>Kies een ander product: </span>&nbsp;<a [routerLink]="['/vergunning', themaRouteKey]" i18n>overzichtspagina</a>
                        </li>
                        <li>
                            <a href="https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent" i18n>Contacteer het Mobiliteitsbedrijf</a>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</article>