import { Component } from '@angular/core';
import { AuthService } from '../../auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
    selector: 'avgpv-pub-auth',
    templateUrl: './auth.component.html'
})
export class AuthComponent {
    public isAuthenticated$;
    public isPartiallyAuthenticated$;
    public isLoggedin$;
    public params: Params;

    constructor(private authService: AuthService, private route: ActivatedRoute) {
        this.isAuthenticated$ = authService.isAuthenticated$;
        this.isPartiallyAuthenticated$ = authService.isPartiallyAuthenticated$;
        this.isLoggedin$ = authService.isLoggedin$;
        this.route.queryParams.pipe(take(1)).subscribe(params => this.params = params);
    }

    public loginACM(): void {
        this.authService.loginACM(this.params.return);
    }
}
