import { Injectable } from '@angular/core';

@Injectable() // we need to inject this without a constructor in main.ts
export class EnvironmentService {
    // proxy of env.js values

    public itb = {
        issuer: '',
        clientId: ''
    };
    public acmIdm = {
        issuer: '',
        clientId: '',
        clientSecret: '',
        enableTokenExchange : true,
        enableIdTokenAsAccessToken : false,
        avgpvClientId : ''

    };
    public api = {
        hostUri: '',
        baseUri: '',
        key: ''
    };
    public permanentRechtForm: '';
    public vergunningStopzettenForm: '';
    public appBaseUri: '';
    public production = false;
    public env: '';
    public googleTagManager = {
        enabled : false,
        id: ''
    };
}
