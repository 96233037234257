<div class="container" *ngIf="isDoneLoading$">
    <a [href]="location + '#main-content'"
       (click)="navigateToFragment('main-content')"
       class="skiplink" i18n>
        Overslaan en naar de inhoud gaan
    </a>
    <div *ngIf="!isProduction" id="env" class="{{env}}">{{env}}-omgeving</div>
    <avgpv-pub-header [user]="user$ | async" (logout)="logout()"></avgpv-pub-header>
    <main  id="main-content">
        <router-outlet></router-outlet>
    </main>
    <footer>
        <avgpv-pub-footer></avgpv-pub-footer>
    </footer>
</div>
<div aria-live="polite"
     aria-atomic="true"
     class="visually-hidden">
    <span [innerText]="a11yMessage | async"></span>
</div>
<!-- <div *ngIf="!(isDoneLoading$ | async)" class="spinner" [ngClass]="{'fade': true}">
    <div i18n>&hellip;loading</div>
</div> -->
