import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private environment: EnvironmentService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiHostUri = this.environment.api.hostUri;
    let requestToForward = request;

    // Only add api key header for requests to our own API.
    // Otherwise the OPTIONS request fails because the apiKey header is not allowed by ACM/IDM and District09 IDP.
    if (requestToForward.url.startsWith(apiHostUri)) {
      if (this.environment.api.key !== '') {
          requestToForward = request.clone({ setHeaders: { apiKey: this.environment.api.key }});
      }
    }

    return next.handle(requestToForward);
  }
}
