/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
import { UitzonderingsprocedureComponent } from './auth/components/uitzonderingsprocedure/uitzonderingsprocedure.component';
import { PortaalModule } from './portaal/portaal.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthComponent } from './auth/components/auth/auth.component';
import { AuthService, IDTokenInterceptor } from './auth/auth.service';
import { CallbackComponent } from './auth/components/callback/callback.component';
import { storageFactory } from './auth/storage.factory';
import { environmentServiceProvider } from './environment.service.provider';
import { LogoutComponent } from './auth/components/logout/logout.component';
import { I18nInterceptor } from './interceptors/i18n.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { API_BASE_URL, ProductApi, VergunningApi, UsersApi, OrderApi, FeaturesApi, BannerApi } from './shared/publicapi/d09.avgpv.public.client';
import { TerugbetalingApi } from './shared/publicapi/d09.avgpv.public.client';
import { ProfielPaginaModule } from './profiel-pagina/profiel-pagina.module';
import { LoginAcmIdmComponent } from './auth/components/loginacmidm/login-acm-idm/login-acm-idm.component';
import { DatePipe } from '@angular/common';

declare var window: any;

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        UitzonderingsprocedureComponent,
        CallbackComponent,
        LoginAcmIdmComponent,
        LogoutComponent,
        LoginAcmIdmComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        ProfielPaginaModule,
        PortaalModule,
        CoreModule.forRoot(),
        OAuthModule.forRoot({ resourceServer: { sendAccessToken: false } })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: IDTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: I18nInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        AuthService,
        environmentServiceProvider,
        { provide: OAuthStorage, useFactory: storageFactory },
        ProductApi,
        OrderApi,
        VergunningApi,
        TerugbetalingApi,
        UsersApi,
        FeaturesApi,
        BannerApi,
        { provide: API_BASE_URL, useFactory: () => window?.__env?.api?.hostUri },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
