class DevFeatures {

  private storage: Storage;

  constructor() {
    // future extensions can choose more complex logic for storage
    this.storage = window.localStorage;
  }

  public get triggerErrorOnCreateLoggedOnUser(): boolean {
    const value = this.getFeatureBool('dev-triggerErrorOnCreateLoggedOnUser', false);
    return value;
  }

  public get enableAuthLogging(): boolean {
    const value = this.getFeatureBool('dev-enableAuthLogging', false);
    return value;
  }

  private getFeature(featureName: string, defaultValue: string): string {
    const value = this.storage.getItem(featureName);
    if (value !== undefined) {
      return value;
    }
    else {
      return defaultValue;
    }
  }

  private getFeatureBool(featureName: string, defaultValue: boolean): boolean {
    const value = this.getFeature(featureName, defaultValue.toString());
    if (value !== undefined) {
      return value === 'true' ? true : false;
    }
    return defaultValue;
  }
}

export const devfeatures = new DevFeatures();
