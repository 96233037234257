<article class="overview-layout">
    <h1>Cookiebeleid</h1>
    <div class="paragraph paragraph--type--text paragraph--view-mode--default">

        <h2>Waarom gebruiken we cookies?</h2>

        <p>Stad Gent gebruikt cookies vooral om jou een optimale surfervaring te geven. Dankzij cookies krijgen we
            namelijk
            inzicht in het surfgedrag en dus de noden van onze gebruikers. Op basis hiervan verbeteren we onze
            website.<br><br>
            Het resultaat: jij krijgt relevantere content te zien én navigeert moeiteloos op een gebruiksvriendelijkere
            website.</p>

        <h2>Hoe kan je jouw cookievoorkeuren beheren?</h2>

        <h3>Via onze website</h3>

        <p>Wanneer je onze website voor de eerste keer bezoekt kies je welke cookies je wil aanvaarden. Je kan op elk
            moment
            je cookievoorkeuren eenvoudig wijzigen, door de cookie-instellingen aan te passen.</p>

        <h3>Via je browser</h3>

        <p>Je kan ook via je browser instellen of je een website toelaat cookies te plaatsen:</p>

        <ul>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=nl">Cookie-instellingen aanpassen in
                    Chrome</a>
            </li>
            <li><a href="https://support.microsoft.com/nl-nl/help/4468242/microsoft-edge-browsing-data-and-privacy">Cookie-instellingen
                    aanpassen in Edge</a></li>
            <li><a
                    href="https://support.mozilla.org/nl/kb/cookies-informatie-websites-computer-opgeslagen#w_cookie-instellingen">Cookie-instellingen
                    aanpassen in Firefox</a></li>
            <li><a href="https://help.apple.com/safari/mac/9.0/?lang=nl#/sfri11471">Cookie-instellingen aanpassen in
                    Safari</a></li>
        </ul>
        <p>Hou er wel rekening mee dat als je cookies op deze manier uitschakelt, de mogelijkheid bestaat dat onze
            website
            niet goed meer werkt.</p>

        <h2>Wat zijn cookies precies?</h2>

        <p>Een cookie is een klein tekstbestandje dat een website op de harde schijf van je computer zet op het moment
            dat
            je de site bezoekt.</p>

        <p>Cookies hebben in geen geval toegang tot je naam, adres of de persoonlijke documenten op je computer of
            mobiel
            toestel. Ze verzamelen enkel informatie over je websitegebruik.</p>

        <h2>Welke cookies gebruiken we en waarom?</h2>

        <p>Cookies kunnen door verschillende partijen worden geplaatst. First party cookies zijn cookies die de Stad
            Gent
            beheert. Third party cookies sturen data door naar externe partijen, zoals Facebook en Google. Zij gebruiken
            deze data om je content op jouw maat te laten zien, zoals relevante advertenties.</p>

        <p>In bepaalde gevallen sturen onze first party cookies gegevens door naar externe partijen. Bijvoorbeeld naar
            Google Analytics. Dankzij deze basic surfgegevens krijgen wij een beter beeld van wat onze bezoekers zoeken
            en
            hoe we ze beter kunnen helpen.</p>

        <p>De Stad Gent doet er alles aan om de plaatsing van third party cookies via onze platformen zo gecontroleerd
            mogelijk te laten verlopen.</p>

        <p>Je kan via je cookie-instellingen kiezen welke cookies de website plaatst en zo bepalen welke informatie je
            met
            de Stad Gent deelt.</p>

        <ol>
            <li>
                <p><strong>Noodzakelijke cookies</strong><br>
                    Zonder noodzakelijke cookies werkt onze website niet goed. Voor gebruik van deze cookies is geen
                    toestemming vereist. Je kan ze dus niet uitschakelen in je cookie-instellingen.<br>
                    We gebruiken deze cookies bijvoorbeeld om de website gelijkmatig te belasten, zodat die steeds
                    bereikbaar is. Of om je browserinstellingen uit te lezen, zodat we de website optimaal kunnen
                    weergeven
                    op je beeldscherm.</p>
            </li>
            <li>
                <p><strong>Statistische cookies</strong><br>
                    Met statistische cookies kunnen we bezoeken aan onze website meten. Zo zien we welke pagina’s onze
                    bezoekers het meest bekijken en welke websites naar ons verwijzen. Zo komen we te weten waar we onze
                    webteksten nog moeten verbeteren.</p>
            </li>
            <li>
                <p><strong>Voorkeur cookies</strong><br>
                    Voorkeur cookies maken je bezoek aan de website makkelijker. Deze cookies onthouden bijvoorbeeld je
                    inloggegevens en je voorkeursinstellingen, zodat je deze niet elke keer opnieuw moet invullen.</p>
            </li>
            <li>
                <p><strong>Marketing cookies</strong><br>
                    Marketing cookies helpen om content beter af te stemmen op jouw voorkeuren. Zo hopen we je inhoud
                    aan te
                    bieden die voor jou boeiend is en waar je zelf nog niet aan had gedacht. De Stad Gent gebruikt deze
                    cookies bijvoorbeeld om je op de hoogte te houden van beleidsbeslissingen, evenementen en nieuwe
                    dienstverlening.<br>
                    Dankzij marketing cookies kan je bovendien inhoud van Facebook, Twitter, YouTube en andere sociale
                    media
                    bekijken op onze website.</p>
            </li>
        </ol>
        <p>Let op: ook zonder cookies zie je advertenties, maar dan minder afgestemd op jouw voorkeuren en interesses.
        </p>

        <h2>Gedetailleerde lijst van cookies op de website van de Stad Gent</h2>

        <div #cookieLijst></div>

        <h2>Wie is verantwoordelijk voor dit cookiebeleid?</h2>

        <p>Stad Gent<br>
            Botermarkt 1<br>
            9000 Gent</p>

        <h2>Kan het cookiebeleid van Stad Gent veranderen?</h2>

        <p>Ja, wij kunnen dit cookiebeleid op elk moment aanpassen. Als dat gebeurt, vragen we je opnieuw om je
            cookievoorkeuren in te stellen.</p>

        <h2>Hoe gaat Stad Gent om met mijn persoonlijke gegevens?</h2>

        <p>Sommige cookies verzamelen persoonsgegevens. Lees meer over hoe de Stad Gent omgaat met de verwerking van
            jouw
            persoonsgegevens in onze&nbsp;<a
                href="https://stad.gent/nl/over-gent-en-het-stadsbestuur/juridische-info/met-respect-voor-je-privacy">privacyverklaring</a>.
        </p>

        <h2>Heb je vragen of klachten?</h2>

        <ul>
            <li>Inhoudelijke vragen over onze cookies: Gentinfo via het&nbsp;<a
                    href="https://stad.gent/contact">contactformulier</a>,&nbsp;<a
                    href="mailto:gentinfo@stad.gent">gentinfo&#64;stad.gent</a>&nbsp;of 09 210 10 10.</li>
            <li>Vragen over jouw privacy bij het gebruik van cookies: Data Protection Officer van de Stad Gent
                via&nbsp;<a href="mailto:privacy@stad.gent">privacy&#64;stad.gent</a>.</li>
        </ul>
    </div>
</article>