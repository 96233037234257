import { Component, OnDestroy, OnInit } from '@angular/core';
import { BannerAndFeatureService } from '../feature.service';
import { forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { switchMap, catchError, tap, map } from 'rxjs/operators';

@Component({
    selector: 'avgpv-pub-crisis-communication',
    templateUrl: './crisis-communication.component.html'
})

export class CrisisCommunicationComponent implements OnInit, OnDestroy {
    public bannerMessage: string;
    private sub: Subscription;
    private defaultBannerText = $localize`De website is momenteel in onderhoud. We streven ernaar om binnen een uurtje terug online te zijn.`;

    constructor(private featureService: BannerAndFeatureService) { }

    public ngOnInit(): void {
        // run check every minute
        this.sub = timer(0, 60000)
            .pipe(
                switchMap(_ => this.getBannerMessage())
            ).subscribe(bannerMessage => {
                this.bannerMessage = bannerMessage;
            });
    }

    public getBannerMessage(): Observable<string> {
        return this.featureService.getBannerMessage().pipe(catchError(_ => of(this.defaultBannerText)));
    }

    public ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
