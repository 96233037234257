<!-- if both are true this is going to look weird, but just don't do that I guess. -->
<div class="region region-highlighted" *ngIf="bannerMessage">
    <div class="block block-dg-crisis block-dg-crisis-communication">
        <div class="dg-crisis-block dg-crisis-block--type-front">
            <section class="crisis-communication__wrapper">
                <div class="crisis-communication" role="alert">
                    <i aria-hidden="true" class="icon-exclamation"></i>

                    <div class="crisis-communication__content">
                        <h2 i18n>Opgelet</h2>
                        <div><p>{{ bannerMessage }}</p></div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>