import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FileUploaderOptions, FileItem, FileUploader, FileLikeObject, ParsedResponseHeaders } from 'ng2-file-upload';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';

@Component({
	selector: 'avgpv-pub-input-file',
	templateUrl: './input-file.component.html'
})
export class InputFileComponent implements OnInit, OnDestroy {
	@Input() public label: string;
	@Input() public id: string;
	@Input() public message: string;
	@Input() public limitations: string;

	@Input() public hasFilesUploaded: boolean;

	@Input() private uploaderOptions: FileUploaderOptions;
	@Input() private validationErrors: { [name: string]: string };
	@Input() private formData: UntypedFormGroup;
	@Output() private done: EventEmitter<{ item: FileItem; response: string }>;

	@ViewChild('fileUpload', { static: true }) private fileUploadElement: ElementRef;

	public error$: Subject<string> = new BehaviorSubject(null);
	public describedBy$: Subject<string> = new BehaviorSubject(null);
	public uploader: FileUploader;
	public file$: Subject<string> = new Subject();
	private errorSubscription: Subscription;
	private unknownError = $localize`Er is een onverwachte fout opgetreden.`;

	constructor() {
		this.done = new EventEmitter<{ item: FileItem; response: string }>();
	}

	public get accept(): string {
		return this.uploaderOptions?.allowedMimeType.toString();
	}

	@Input() public set error(value) {
		this.error$.next(value);
	}

	public ngOnInit(): void {
		this.uploader = new FileUploader(this.uploaderOptions);
		this.uploader.onBeforeUploadItem = (fileItem: FileItem) => {
			fileItem.withCredentials = false;
		};
		this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
			this.error$.next(null);
			this.file$.next(fileItem.file.name);
			fileItem.upload();
		};
		this.uploader.onWhenAddingFileFailed = (_fileItem: FileLikeObject, filter: { name: string }, _options: any) => {
			this.error$.next(this.validationErrors[filter.name] || this.unknownError);
		};
		this.uploader.onSuccessItem = (item: FileItem, response: string, _status: number, _headers: ParsedResponseHeaders) => {
			this.fileUploadElement.nativeElement.value = null;
			this.file$.next(null);
			this.done.emit({ item, response });
		};
		this.uploader.onErrorItem = (_fileItem: FileItem, _response: string, _status: number, _headers: ParsedResponseHeaders) => {
			this.error$.next(this.unknownError);
		};
		this.uploader.onBuildItemForm = (fileItem: FileItem, form: FormData) => {
			const values = this.formData.getRawValue();
			Object.keys(values).forEach(key => {
				form.append(key, values[key]);
			});
			form.append('filename', fileItem.file.name);
			return { fileItem, form };
		};
		this.errorSubscription = this.error$.subscribe((error: string) => {
			if (!error) {
				this.describedBy$.next(this.message ? `${this.id}-message` : null);
			}

			this.describedBy$.next(`${this.id}-error`);
		});
	}

	public ngOnDestroy(): void {
		if (this.errorSubscription) {
			this.errorSubscription.unsubscribe();
		}
	}
}
