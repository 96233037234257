import { EnvironmentService } from './../../../environment.service';
import { User } from 'src/app/models/user';
import { Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrderService } from '../../../order/order.service';
import { animate, group, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'avgpv-pub-header',
    templateUrl: './header.component.html',
    animations: [
        trigger('valueAnimation', [
            transition(':increment', group([
                style({ color: 'white', backgroundColor: '#007db3' }),
                animate('1s ease-out', style('*'))
            ]))
        ])
    ]
})

export class HeaderComponent implements OnInit {

    @ViewChild('container') container: ElementRef;

    @Input() public user: User;
    @Input() public plannedMaintenance: boolean;
    @Input() public technicalIssues: boolean;
    @Output() public logout = new EventEmitter();

    public childIsTabletOrLarger: boolean;

    public isLanguageSwitchHidden = true;
    public url$: Observable<string>;
    public isProfileHidden = true;
    public orderCount$;
    public isAuthenticated: boolean;
    public isPartiallyAuthenticated: boolean;

    constructor(
        private breakpointObserver: BreakpointObserver,
        @Inject(LOCALE_ID) private locale: string,
        private authService: AuthService,
        private environment: EnvironmentService,
        router: Router,
        orderService: OrderService,
    ) {
        this.url$ = router.events.pipe(filter(e => e instanceof NavigationEnd), map((e: NavigationEnd) => e.urlAfterRedirects));
        this.orderCount$ = orderService.orderCount$;
        this.authService.isAuthenticated$.subscribe(authenticated => this.isAuthenticated = authenticated);
        this.authService.isPartiallyAuthenticated$
            .subscribe(partiallyAuthenticated => this.isPartiallyAuthenticated = partiallyAuthenticated);
        document.addEventListener('click', this.offClickHandler.bind(this));
    }

    public get currentLanguage(): string {
        return this.locale.substr(0, 2);
    }

    public get env(): string {
        return this.environment.env;
    }

    public get isStaging(): boolean {
        return this.env === 'st';
    }

    public offClickHandler(event: any): void {
        if (!this.container.nativeElement.contains(event.target)) {
            this.isProfileHidden = true;
        }
    }

    public ngOnInit(): void {
        this.childIsTabletOrLarger = this.breakpointObserver.isMatched('(min-width: 768px)');

        this.breakpointObserver.observe('(min-width: 768px)').subscribe(result => {
            this.childIsTabletOrLarger = result.matches;
        });
    }

    public isCurrentLanguage(lang: string): boolean {
        return this.currentLanguage.toUpperCase() === lang.toUpperCase();
    }

    public getFullname(naam: string, voornaam: string): string {
        const volledigeNaam = `${naam} ${voornaam}`;
        return volledigeNaam;
    }
}
