export { AlgemeneVoorwaardenComponent } from './algemene-voorwaarden/algemene-voorwaarden.component';
export { CookiebeleidComponent } from './cookiebeleid/cookiebeleid.component';
export { FouteProductHoedanigheidComponent } from './foute-product-hoedanigheid/foute-product-hoedanigheid.component';
export { HoedanigheidComponent } from './hoedanigheid/hoedanigheid.component';
export { AddressComponent } from './address/address.component';
export { InputDateComponent } from './input-date/input-date.component';
export { InputFileComponent } from './input-file/input-file.component';
export { ConfirmComponent } from './confirm/confirm.component';
export { StatusMessageComponent } from './status-message/status-message.component';
export { AantallenControleValidationMessageComponent } from './aantallen-controle-validation-messages/aantallen-controle-validation-messages.component';
export { AlgemeneVoorwaardenModalComponent } from './algemene-voorwaarden-modal/algemene-voorwaarden-modal.component';
