import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { CodeNaam } from '../models/code-naam';

@Injectable({
  providedIn: 'root'
})
export class LandenService {

  private landen: CodeNaam[];

  constructor(private httpClient: HttpClient, private environment: EnvironmentService) { }

  public getLanden(): Observable<CodeNaam[]> {
    if (this.landen) {
      return of(this.landen);
    }
    return this.httpClient.get<CodeNaam[]>(`${this.environment.api.baseUri}/landen`)
      .pipe(
        catchError(() => of([{ code: 'BE', naam: 'België' }])),
        tap(l => this.landen = l),
        shareReplay()
      );
  }

  public getStraatSuggestions(postcode: string,
                              gemeente: string,
                              straat: string, aantal: number): Observable<{ naam: string; crabCode: string }[]> {
    return this.httpClient.get<{ naam: string; crabCode: string }[]>(`${this.environment.api.baseUri}/adressen/straten/suggest`,
      {
        params:
        {
          postcode,
          gemeente,
          straat,
          aantal: aantal.toString()
        }
      });
  }

  public getHuisnummerSuggestions(postcode, gemeente, straat, crabCodeStraat): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.environment.api.baseUri}/adressen/huisnummers/suggest`,
      {
        params:
        {
          postcode,
          gemeente,
          straat,
          crabCodeStraat
        }
      });
  }

  public getBusnummerSuggestions(postcode, gemeente, straat, crabCodeStraat, huisnummer): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.environment.api.baseUri}/adressen/busnummers/suggest`,
      {
        params:
        {
          postcode,
          gemeente,
          straat,
          crabCodeStraat,
          huisnummer
        }
      });
  }
}
