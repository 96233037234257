/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class I18nInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, @Inject(LOCALE_ID) private locale: string) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req.clone({ setHeaders: { 'Accept-Language': this.locale } }));
  }

}
