import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Renderer2
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from '@angular/forms';

/**
 * The accessor for writing a value and listening to changes on a date input element.
 * As a fallback for older browsers, input in the format of 'dd/mm/yyyy' is parsed to Date.
 *
 *  ### Example
 *  `<input type="date" name="myBirthday" ngModel useValueAsDate>`
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[useValueAsDate]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateValueAccessorDirective),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DateValueAccessorDirective),
        multi: true
    }]
})
export class DateValueAccessorDirective implements ControlValueAccessor, Validator {
    constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

    // both empty functions are placeholders that need to be defined as empty funcs so they can receive a valid implementation later on.
    // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
    @HostListener('input', ['$event.target.value']) public onChange = (_value: string) => { };
    // eslint-disable-next-line no-empty,@typescript-eslint/no-empty-function
    @HostListener('blur', []) public onTouched = () => { };

    public writeValue(value: Date | string): void {
        if (!value) {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);
            return;
        }
        // IE get's a string of yyyy-mm-dd as initial value (before onchange)
        if (typeof value === 'string') {
            this.renderer.setProperty(
                this.elementRef.nativeElement,
                'value',
                value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1'));
            return;
        }
        /**
         * Fix for 'object is not extensible' bug at getSetOffset.
         */
        const output = new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), 0, 0, 0, 0));
        const date = output.getUTCDate();
        const month = output.getUTCMonth() + 1;
        const year = output.getUTCFullYear();

        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'value',
            (date < 10 ? '0' + date : date) + '/' +
            (month < 10 ? '0' + month : month) + '/' +
            year);
    }

    public registerOnChange(fn: (value: any) => void): void {
        // Invalid value turns "null", with quotes, to trigger pattern validation and not required validation.
        this.onChange = (value: string) => {
            fn(/(\d{2})\/(\d{2})\/(\d{4})/.exec(value) ?
                new Date(value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1T00:00:00.000Z')) : 'null');
        };
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    // function is required by the interface but does nothing in our case here.
    // eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function
    public registerOnValidatorChange(_fn: () => void): void {
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        if (!c.value || (c.value instanceof Date && !isNaN(c.value.getTime()))) {
            return null;
        }
        const date = new Date(c.value);
        return !isNaN(date.getTime()) ? null : { date: true };
    }
}
