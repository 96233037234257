import { CommonModule } from '@angular/common';
import { CrisisCommunicationComponent } from './crisis-communication/crisis-communication.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [CrisisCommunicationComponent],
    exports: [CrisisCommunicationComponent],
    imports: [CommonModule]
})
export class FeatureModule { }
