import { StatusMessageModel } from './../../../shared/errorhandling/status-message-model';
import { User } from './../../../models/user';
import { AuthService } from 'src/app/auth/auth.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CodeNaam } from 'src/app/models/code-naam';
import { Onderneming } from 'src/app/models/onderneming';
import { LandenService } from 'src/app/services/landen.service';
import { avgpvHandleError } from 'src/app/shared/errorhandling';

@Component({
  selector: 'avgpv-pub-onderneming-form',
  templateUrl: './onderneming-form.component.html'
})
export class OndernemingFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public onderneming: Onderneming;
  @Input() public strongAuth: boolean;
  @Input() public disabledFields: string[] = [];
  @Output() public submitForm = new EventEmitter<Onderneming>();
  @Output() public scrollToHeader = new Subject<void>();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public form: UntypedFormGroup;
  public submitted = false;
  public landen$: Observable<CodeNaam[]>;
  public showPrivacy = false;
  public statusMessage = new StatusMessageModel();
  public syncMagda$: Subject<void> = new Subject<void>();

  private destroyed = new Subject<void>();
  private readonly defaultLandIso2Code = 'BE';

  constructor(landenService: LandenService, private authService: AuthService) {
    this.landen$ = landenService.getLanden();
  }

  public get contactpersoon(): UntypedFormGroup {
    return (this.form.get('contactPersonen') as UntypedFormArray).at(0) as UntypedFormGroup;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.unsubscribe();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      landIso2Code: new UntypedFormControl(this.defaultLandIso2Code, !this.strongAuth ? [Validators.required] : null),
      ondernemingsNummer: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      naam: new UntypedFormControl(null, [Validators.required]),
      telefoon: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\+?\d{0,20}$/)]),
      adresEntiteit: new UntypedFormGroup({
        straat: new UntypedFormControl(null, [Validators.required]),
        huisNummer: new UntypedFormControl(null, [Validators.required]),
        busNummer: new UntypedFormControl(),
        postCode: new UntypedFormControl(null, [Validators.required]),
        gemeente: new UntypedFormControl(null, [Validators.required]),
        landIso2Code: new UntypedFormControl('BE', [Validators.required]),
        crabCodeStraat: new UntypedFormControl()
      }),
      contactPersonen: new UntypedFormArray([new UntypedFormGroup({
        voornaam: new UntypedFormControl(null, [Validators.required]),
        naam: new UntypedFormControl(null, [Validators.required])
      })]),
    });

    this.form.patchValue(this.onderneming);
    this.setDefaultLandCodeForAdres();

    if (this.strongAuth) {
      this.disabledFields.push('adresEntiteit.straat');
      this.disabledFields.push('adresEntiteit.huisNummer');
      this.disabledFields.push('adresEntiteit.busNummer');
      this.disabledFields.push('adresEntiteit.postCode');
      this.disabledFields.push('adresEntiteit.gemeente');
      this.disabledFields.push('adresEntiteit.landIso2Code');
    }

    this.disabledFields.forEach(f => this.form.get(f)?.disable());

    const ondernemingsNummerControl = this.form.get('ondernemingsNummer');

    // check which validator to be set
    if (!this.onderneming.adresEntiteit || this.onderneming.adresEntiteit.landIso2Code == null) {
      this.updateValidationCheckForLand(ondernemingsNummerControl, this.onderneming.adresEntiteit.landIso2Code);
    }

    this.updateValidationCheckForLand(ondernemingsNummerControl, this.defaultLandIso2Code);

    this.form.get('landIso2Code').valueChanges.pipe(takeUntil(this.destroyed)).subscribe(v => {
      this.updateValidationCheckForLand(ondernemingsNummerControl, v);

      ondernemingsNummerControl.updateValueAndValidity();

      const adresEntiteitFormGroup = this.form.get('adresEntiteit') as UntypedFormGroup;
      adresEntiteitFormGroup.reset({ landIso2Code: v });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.onderneming) {
      this.form.patchValue(this.onderneming);
      this.setDefaultLandCodeForAdres();
    }
  }

  public isInvalid(control: string, group: UntypedFormGroup = null): boolean {
    const c = !group ? this.form.get(control) : group?.get(control);

    if (!c) {
      return true;
    }

    return c.errors && (c.touched || this.submitted);
  }

  public submit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.submitForm.emit({ ...this.onderneming, ...this.form.getRawValue() });
  }

  public focusFragment(fragment: string): void {
    this.showPrivacy = true;
    setTimeout(() => {
      (document.getElementById(fragment) as HTMLElement).focus();
    });
  }

  public fetchAdresData(): void {
    this.loading$.next(true);

    this.authService.syncMagdaLoggedInOnderneming()
      .pipe(
        avgpvHandleError({ statusMessageModel: this.statusMessage, context: $localize`:backendapi context:Ophalen van de adresgegevens` }),
        finalize(() => {
          if (this.statusMessage.hasMessage()) {
            const message = $localize`:profiel synchronisatieBericht:Haal uw adresgegevens opnieuw op of vul ze manueel aan.`
              + '<br/><br/>' + this.statusMessage.message;

            this.statusMessage.setMessage(message);

            this.scrollToHeader.next();
          }

          this.authService
            .reloadLoggedInOnderneming()
            .then((user: User) => {
              if (!user.onderneming.magdaLastSyncSuccess) {
                this.enableFields();
              }
              this.syncMagda$.next();
              this.loading$.next(false);
            });
        }))
      .subscribe();
  }

  private setDefaultLandCodeForAdres(): void {
    if (!this.onderneming.adresEntiteit || this.onderneming.adresEntiteit.landIso2Code == null) {
      // reset the landcode to the default
      // the patch removes the default value 'BE', since address is null
      const adresEntiteitFormGroup = this.form.get('adresEntiteit') as UntypedFormGroup;
      adresEntiteitFormGroup.get('landIso2Code').setValue('BE');

      // also set the land code on the form itself, to make sure that has a value
      this.form.get('landIso2Code')?.setValue('BE');
    }
  }

  private enableFields(): void {
    const disabledFields: string[] = [
      'straat',
      'huisNummer',
      'busNummer',
      'postCode',
      'gemeente',
      'landIso2Code'
    ];

    const adresEntiteitFormGroup = this.form.get('adresEntiteit') as UntypedFormGroup;
    disabledFields.forEach(f => adresEntiteitFormGroup.get(f)?.enable());
  }

  private updateValidationCheckForLand(ondernemingsNummerControl: AbstractControl, landIso2Code: string): void {
    ondernemingsNummerControl.setValidators([
      Validators.required,
      landIso2Code === 'BE' ? Validators.maxLength(10) : Validators.maxLength(12)
    ]);
  }
}
