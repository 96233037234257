<div [id]="modalName" class="modal modal--fixed-height" role="dialog" aria-modal="true" aria-labelledby="modal-title" tabindex="-1">
    <div class="modal-inner">
        <div class="modal-header">
            <button type="button" class="button close icon-cross modal-close" [attr.data-target]="modalName">
                <span i18n>Sluiten</span>
            </button>
        </div>
        <div class="modal-content" tabindex="0">
            <avgpv-pub-algemene-voorwaarden></avgpv-pub-algemene-voorwaarden>
        </div>
        <div class="modal-actions">
            <a href="algemene-voorwaarden">Open dit als webpagina</a>
        </div>
    </div>

    <div class="modal-overlay modal-close" [attr.data-target]="modalName" tabindex="-1"></div>
</div>