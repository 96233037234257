import { StatusMessageModel } from './../../../shared/errorhandling/status-message-model';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/auth/auth.service';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { CodeNaam } from 'src/app/models/code-naam';
import { Persoon } from 'src/app/models/persoon';
import { LandenService } from 'src/app/services/landen.service';
import { RijksregisternummerValidator } from 'src/app/utils/rijksregisternummer.validator';
import { avgpvHandleError } from 'src/app/shared/errorhandling';

@Component({
  selector: 'avgpv-pub-persoon-form',
  templateUrl: './persoon-form.component.html'
})
export class PersoonFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() public persoon: Persoon;
  @Input() public strongAuth: boolean;
  @Input() public disabledFields: string[] = [];
  @Output() public submitForm = new EventEmitter<Persoon>();
  @Output() public scrollToHeader = new Subject<void>();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public form: UntypedFormGroup;
  public submitted = false;
  public landen$: Observable<CodeNaam[]>;
  public showPrivacy = false;
  public statusMessage = new StatusMessageModel();
  public syncMagda$: Subject<void> = new Subject<void>();

  private destroyed = new Subject<void>();

  constructor(landenService: LandenService, private authService: AuthService) {
    this.landen$ = landenService.getLanden();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setRijksregisterNummerValidation(this.form.get('landIso2Code').value);
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.unsubscribe();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      voorNaam: new UntypedFormControl(null, [Validators.required]),
      naam: new UntypedFormControl(null, [Validators.required]),
      landIso2Code: new UntypedFormControl('BE', !this.strongAuth ? [Validators.required] : null),
      rijksRegisterNummer: new UntypedFormControl(null, [Validators.required, RijksregisternummerValidator.isValidFormat]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      telefoon: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^\+?\d{0,20}$/)]),
      hoofdVerblijfplaats: new UntypedFormGroup({
        straat: new UntypedFormControl(null, [Validators.required]),
        huisNummer: new UntypedFormControl(null, [Validators.required]),
        busNummer: new UntypedFormControl(),
        postCode: new UntypedFormControl(null, [Validators.required]),
        gemeente: new UntypedFormControl(null, [Validators.required]),
        landIso2Code: new UntypedFormControl('BE', [Validators.required]),
        crabCodeStraat: new UntypedFormControl()
      }),
    });

    this.form.patchValue(this.persoon);
    this.setDefaultLandCodeForAdres();

    if (this.strongAuth) {
      this.disabledFields.push('hoofdVerblijfplaats.straat');
      this.disabledFields.push('hoofdVerblijfplaats.huisNummer');
      this.disabledFields.push('hoofdVerblijfplaats.busNummer');
      this.disabledFields.push('hoofdVerblijfplaats.postCode');
      this.disabledFields.push('hoofdVerblijfplaats.gemeente');
      this.disabledFields.push('hoofdVerblijfplaats.landIso2Code');
    }
    this.disabledFields.forEach(f => this.form.get(f)?.disable());
    const hoofdVerblijfplaatsFormGroup = this.form.get('hoofdVerblijfplaats') as UntypedFormGroup;

    this.form.get('landIso2Code').valueChanges.pipe(takeUntil(this.destroyed)).subscribe(v => {
      this.setRijksregisterNummerValidation(v);
      hoofdVerblijfplaatsFormGroup.reset({ landIso2Code: v });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.persoon) {
      this.form.patchValue(this.persoon);

      this.setDefaultLandCodeForAdres();
    }
  }

  public isInvalid(control: string, group: string = null): boolean {
    const c = !group ? this.form.get(control) : this.form.get(group)?.get(control);

    if (!c) {
      return true;
    }

    return c.errors && (c.touched || this.submitted);
  }

  public submit(): void {
    this.statusMessage = new StatusMessageModel();
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.submitForm.emit({ ...this.persoon, ...this.form.getRawValue() });
  }

  public focusFragment(fragment: string): void {
    this.showPrivacy = true;
    setTimeout(() => {
      (document.getElementById(fragment) as HTMLElement).focus();
    });
  }

  public fetchAdresData(): void {
    this.loading$.next(true);
    this.statusMessage = new StatusMessageModel();

    this.authService.syncMagdaLoggedInPersoon()
      .pipe(
        avgpvHandleError({ statusMessageModel: this.statusMessage, context: $localize`:backendapi context:Ophalen van de adresgegevens` }),
        finalize(() => {
          if (this.statusMessage.hasMessage()) {
            const message = $localize`:profiel synchronisatieBericht:Haal uw adresgegevens opnieuw op of vul ze manueel aan.`
              + '<br/><br/>' + this.statusMessage.message;

            this.statusMessage.setMessage(message);

            this.scrollToHeader.next();
          }

          this.authService
            .reloadLoggedInPersoon()
            .then((user: User) => {
              if (!user.persoon.magdaLastSyncSuccess) {
                this.enableFields();
              }
              this.loading$.next(false);
              this.syncMagda$.next();
            });
        }))
      .subscribe();
  }

  private setDefaultLandCodeForAdres(): void {
    if (!this.persoon.hoofdVerblijfplaats || this.persoon.hoofdVerblijfplaats.landIso2Code == null) {
      // reset the landcode to the default
      // the patch removes the default value 'BE', since address is null
      const hoofdVerblijfplaatsFormGroup = this.form.get('hoofdVerblijfplaats') as UntypedFormGroup;
      hoofdVerblijfplaatsFormGroup.get('landIso2Code').setValue('BE');

      // also set the land code on the form itself, to make sure that has a value
      this.form.get('landIso2Code')?.setValue('BE');
    }
  }

  private enableFields(): void {
    const disabledFields: string[] = [
      'straat',
      'huisNummer',
      'busNummer',
      'postCode',
      'gemeente',
      'landIso2Code'
    ];

    const hoofdVerblijfplaatsFormGroup = this.form.get('hoofdVerblijfplaats') as UntypedFormGroup;
    disabledFields.forEach(f => hoofdVerblijfplaatsFormGroup.get(f)?.enable());
  }

  private setRijksregisterNummerValidation(landIso2Code: string): void {
    if (landIso2Code && this.form) {
      const rijksRegisterNummerControl = this.form.get('rijksRegisterNummer');
      rijksRegisterNummerControl.setValidators([
        Validators.required,
        landIso2Code === 'BE' ? RijksregisternummerValidator.isValidFormat : Validators.maxLength(20)
      ]);
      rijksRegisterNummerControl.updateValueAndValidity();
    }
  }
}

